import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Stack, Typography, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import { fData } from '../../../../utils/formatNumber';
// _mock
import { countries } from '../../../../_mock';
import useLocales from '../../../../hooks/useLocales';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';
import LinkAccount from 'src/components/LinkAccount';
import CompanyReviewPageLink from 'src/components/buttons/CompanyReviewPageContainer';
import NotificationSettings from './NotificationSettings';
import Iconify from 'src/components/Iconify';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Card } from 'src/components/ui/card';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { user } = useAuth();

  const UpdateUserSchema = z.object({
    displayName: z.string().min(1, { message: 'Name is required' }),
    email: z.string().email({ message: 'email is required' }),
    photoURL: z.string().nullable(),
  });

  const defaultValues = {
    displayName: user?.displayName || '',
    email: user?.email || '',
    photoURL: user?.photoURL || '',
    phoneNumber: user?.phoneNumber || '',
    country: user?.country || '',
    address: user?.address || '',
    state: user?.state || '',
    city: user?.city || '',
    zipCode: user?.zipCode || '',
    about: user?.about || '',
    isPublic: user?.isPublic || '',
  };

  console.log({ defaultValues });

  const form = useForm<z.infer<typeof UpdateUserSchema>>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    reset,
    formState: { isSubmitting },
    setError,
    setValue,
  } = form;

  const onSubmit = async (data: z.infer<typeof UpdateUserSchema>) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: any[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid lg:grid-cols-3 gap-4">
          <div className="md:col-span-3 lg:col-span-1">
            <Card className="p-6 shadow-none justify-center text-center py-10">
              <RHFUploadAvatar
                name="photoURL"
                accept={{ 'image/*': [] }}
                maxSize={3145728}
                onDrop={handleDrop}
                disabled
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    {/* {t('account.profile.image.description')} {fData(3145728)} */}
                  </Typography>
                }
              />

              {/* <RHFSwitch
              name="isPublic"
              labelPlacement="start"
              label={t('account.profile.image.public') as string}
              sx={{ mt: 5 }}
            /> */}
              <Stack sx={{ mt: 4, mb: 2 }}>
                <LinkAccount />
              </Stack>
              <CompanyReviewPageLink />
            </Card>
          </div>

          <div className="md:col-span-3 lg:col-span-2 space-y-4">
            <Card className="p-6 shadow-none">
              <div className="grid grid-cols-2 w-full items-center gap-4">
                <div>
                  <FormField
                    control={form.control}
                    name="displayName"
                    render={({ field }) => (
                      <FormItem className="space-y-0">
                        <FormLabel className="text-xs text-slate-500">
                          {t('general.name')}
                        </FormLabel>
                        <FormControl>
                          <Input
                            className="mt-0"
                            disabled
                            placeholder={t('general.name') as string}
                            {...field}
                          />
                        </FormControl>
                        {/* <FormDescription>This is your public display name.</FormDescription> */}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="space-y-0">
                        <FormLabel className="text-xs text-slate-500">
                          {t('general.form.email')}
                        </FormLabel>
                        <FormControl>
                          <Input
                            className="mt-0"
                            disabled
                            placeholder={t('general.form.email') as string}
                            {...field}
                          />
                        </FormControl>
                        {/* <FormDescription>This is your public display name.</FormDescription> */}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              {/* <RHFTextField name="phoneNumber" label={t('general.form.phone')} />
              <RHFTextField name="address" label={t('general.form.address')} /> */}

              {/* <RHFSelect
                name="country"
                label={t('general.form.country')}
                placeholder={t('general.form.country') as string}
              >
                <option value="" />
                {countries.map((option) => (
                  <option key={option.code} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </RHFSelect> */}

              {/* <RHFTextField name="state" label={t('general.form.state')} />

              <RHFTextField name="city" label={t('general.form.city')} />
              <RHFTextField name="zipCode" label={t('general.form.zip')} /> */}

              {/* <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField
                name="about"
                multiline
                rows={4}
                label={t('general.form.about')}
              />

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {t('general.form.saveChanges')}
              </LoadingButton>
            </Stack> */}
            </Card>

            <Card className="p-6 shadow-none">
              <Stack width="100%">
                <NotificationSettings />
              </Stack>
            </Card>
          </div>
        </div>
      </form>
    </Form>
  );
}
