import { useState } from 'react';
import { Intl } from 'src/enum';
import { useSelector } from 'src/redux/store';

export const initialQuestions = [
  { id: '1', content: 'Who are you (short intro)' },
  { id: '2', content: 'How has my service helped you?' },
  { id: '3', content: 'What is the best experience you had working with me?' },
];

export default function usePageSettings() {
  const id = useSelector((state) => state.publicConfig?.id);
  const titleInit = useSelector((state) => state.publicConfig?.title);
  const descriptionInit = useSelector((state) => state.publicConfig?.description);
  const logoInit = useSelector((state) => state.publicConfig?.logo?.original);
  const questionsInit = useSelector((state) => state.publicConfig?.questions);
  const configStatus = useSelector((state) => state.publicConfig?.status);
  const colorInit = useSelector((state) => state.publicConfig?.color);
  const onlyVideoInit = useSelector((state) => state.publicConfig?.onlyVideo);
  const videoIntroInit = useSelector((state) => state.publicConfig?.videoIntro);
  const reviewerPageLanguageInit = useSelector((state) => state.publicConfig?.reviewerPageLanguage);
  // default to user's language
  const intl = useSelector((state) => state.user.intl);

  const [avatar, setavatar] = useState<string | undefined>(logoInit);
  const [title, setTitle] = useState<string>(titleInit || '');
  const [description, setDescription] = useState<string>(descriptionInit || '');
  const [questions, setQuestions] = useState<any>(questionsInit || initialQuestions);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [color, setColor] = useState<string | undefined>(colorInit || undefined);
  const [onlyVideo, setOnlyVideo] = useState<boolean>(onlyVideoInit || false);
  const [videoIntroPreview, setVideoIntroPreview] = useState<string | undefined>(undefined);
  const [reviewerPageLanguage, setReviewerPageLanguage] = useState<Intl | undefined>(
    reviewerPageLanguageInit || intl
  );
  return {
    id,
    // init
    titleInit,
    descriptionInit,
    logoInit,
    questionsInit,
    configStatus,
    colorInit,
    onlyVideoInit,
    videoIntroInit,
    // reviewerPageLanguageInit,
    // state
    avatar,
    title,
    description,
    questions,
    showModal,
    color,
    onlyVideo,
    videoIntroPreview,
    reviewerPageLanguage,
    // set state
    setavatar,
    setTitle,
    setDescription,
    setQuestions,
    setShowModal,
    setColor,
    setOnlyVideo,
    setVideoIntroPreview,
    setReviewerPageLanguage,
  };
}
