import React, { useEffect, useRef, useState } from 'react';
import {
  ExpandableChat,
  ExpandableChatBody,
  ExpandableChatFooter,
  ExpandableChatHeader,
} from '../ui/chat/expandable-chat';
import { Button } from '../ui/button';
import { ChatMessageList } from '../ui/chat/chat-message-list';
import { ChatBubble, ChatBubbleAvatar, ChatBubbleMessage } from '../ui/chat/chat-bubble';
import { ChatInput } from '../ui/chat/chat-input';
import { Image, Send, X } from 'lucide-react';
import { useSupportChat } from './SupportChatContext';
import SimplySupportIcon from 'src/assets/SupportLogo';
import { useSelector } from 'src/redux/store';
import useMessages, { Message } from 'src/hooks/useMessages';
import { updateParticipantLastSeenMessage } from './api';
import { createConversation, sendMessage } from 'src/sections/@dashboard/chat/ChatWindow';
import { Dialog, DialogContent } from '../ui/dialog';
import SupportChatMessages from './SupportChatMessages';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Input } from '../ui/input';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { IMAGE_METADATA_TYPE } from 'src/enum';
import { upload } from 'src/hooks/useUpload';

// const getCountUnseenMessages = (
//   messages: Message[],
//   userId: string | null,
//   lastSeenMessageTime: Date | null
// ): number => {
//   // assume you then have seen all messages
//   if (!lastSeenMessageTime) {
//     return 0; // messages.length;
//   }
//   if (!userId) return 0;

//   const unseenMessages = messages.filter(
//     (message) => message.createTime > lastSeenMessageTime && message.sender.uid !== userId
//   );
//   return unseenMessages.length;
// };

const workspaceId = 'simplyLabs';

type WorkspaceIdType = typeof workspaceId;

export default function SupportChat() {
  const { isOpen, toggleOpen, messages, conversationId, latestNonUserMessageTime } =
    useSupportChat();
  const userId = useSelector((state) => state.user.id);
  // const isAdmin = useSelector((state) => state.user.email?.includes('@simply'));
  const lastSeenMessageByUser = useSelector(
    (state) =>
      // @ts-ignore
      state.conversations?.map[conversationId]?.participants[userId]?.lastSeenMessageTime
  );

  // const countUnseenMessages = getCountUnseenMessages(messages, userId, lastSeenMessageByUser);

  useEffect(() => {
    console.log('SupportChat', { isOpen, messages, conversationId, userId });
    if (userId && conversationId && isOpen && messages.length > 0) {
      console.log('SupportChat', { latestNonUserMessageTime });
      if (!latestNonUserMessageTime) return;
      if (latestNonUserMessageTime <= lastSeenMessageByUser) return;
      // console.log('USER HAS UNSEEN MESSAGES', 'UPDATING IN 0.5s');
      // adding short delay to not interupt animation
      setTimeout(() => {
        updateParticipantLastSeenMessage({
          workspaceId,
          userId,
          conversationId,
          lastSeenMessageCreateTime: latestNonUserMessageTime,
        });
      }, 500);
      // update conversation participant with latest seen message
      // updateConversationParticipant({
      // Reset unread count
    }
  }, [userId && conversationId && isOpen && messages.length]);

  return (
    <>
      <ExpandableChat isOpen={isOpen} toggleChat={toggleOpen} size="md" position="bottom-right">
        <ExpandableChatHeader className="flex flex-row gap-3 justify-between">
          <div className="flex items-center gap-3">
            <div>
              <SimplySupportIcon />
            </div>
            <h1 className="text-xl font-semibold">SimplySupport</h1>
          </div>
          {/* <div className="pr-5">
            <Button size="sm" variant="secondary">
              {t('support.chat.header.faq')}
            </Button>
          </div> */}
          {/* <div className="flex flex-col gap-2 items-start pt-5"> */}
          {/* <Button variant="secondary">New Chat</Button> */}
          <Button
            size="icon"
            variant="ghost"
            className="absolute top-0 right-0"
            onClick={toggleOpen}
          >
            <X />
          </Button>
          {/* </div> */}
        </ExpandableChatHeader>
        <SupportChatMessages messages={messages} />
        <ExpandableChatFooter>
          <SendMessage workspaceId={workspaceId} conversationId={conversationId} />
        </ExpandableChatFooter>
      </ExpandableChat>
    </>
  );
}

function SendMessage({
  workspaceId,
  conversationId,
}: {
  workspaceId: WorkspaceIdType;
  conversationId: string | null;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);

  const user = useSelector((state) => ({
    uid: state.user.id as string,
    displayName: state.user.displayName ?? null,
    photoURL: state.user.photoURL ?? null,
  }));

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [message]);

  const handleSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    try {
      e.preventDefault();
      if (!message?.trim() || !workspaceId) return;
      if (!conversationId) {
        // if no conversation is selected, create a new conversation
        await createConversation({
          workspaceId,
          user,
          message: {
            message,
            sender: {
              displayName: user.displayName,
              photoURL: user.photoURL,
              uid: user.uid,
            },
          },
          type: 'EXTERNAL',
        });
        setMessage('');
        return;
      }
      await sendMessage({
        workspaceId,
        conversationId,
        user,
        message: {
          message,
          sender: {
            displayName: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid,
          },
        },
        type: 'EXTERNAL',
      });

      setMessage('');
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0];
    if (image) {
      try {
        if (!workspaceId || !conversationId) return;
        setUploading(true);
        // Handle file processing here

        const messageRef = doc(
          collection(DB, 'workspaceChats', workspaceId, 'conversations', conversationId, 'messages')
        );

        const response = await upload(
          image,
          `workspaceChats/${workspaceId}/conversations/${conversationId}/`,
          {
            userId: workspaceId,
            conversationId,
            messageId: messageRef.id,
            type: IMAGE_METADATA_TYPE.CHAT_IMAGE,
          }
        );
        // @ts-ignore
        const imageUrl = response?.url;

        if (!imageUrl) {
          throw new Error(`Error uploading image ${JSON.stringify(response)}`);
        }
        setDoc(messageRef, {
          createTime: new Date(),
          updateTime: new Date(),
          message: '',
          image: {
            original: imageUrl ?? null,
          },
          sender: {
            displayName: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <form className="flex relative gap-2 items-end" onSubmit={handleSubmit}>
      <ChatInput
        ref={ref}
        name="message"
        className="min-h-12 bg-background shadow-none pr-20"
        value={message}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
          }
        }}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={t('support.chat.message.placeholder') as string}
        // className="resize-none overflow-hidden"
        rows={1}
      />
      <Button
        className="absolute top-1/2 right-11 transform size-8 -translate-y-1/2"
        variant="outline"
        size="icon"
        loading={uploading}
        onClick={() => inputRef.current?.click()}
      >
        <Input
          id="picture"
          type="file"
          className="hidden"
          ref={inputRef}
          onChange={handleFileChange}
        />
        <Image />
      </Button>
      <Button className="absolute top-1/2 right-2 transform size-8 -translate-y-1/2" size="icon">
        <Send className="size-4" />
      </Button>
    </form>
  );
}
