import { noCase } from 'change-case';
import { useState, Dispatch } from 'react';
// @mui
import {
  Box,
  List,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useNotifications from '../../../hooks/useNotifications';
import { useSelector } from '../../../redux/store';
import { Notification, NotificationType } from '../../../redux/slices/notificationSlice';
import useLocales from '../../../hooks/useLocales';
import i18n from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Collection } from '../../../enum';
import { doc, setDoc, writeBatch } from 'firebase/firestore';
import { getCollection } from '../../../api';
import { DB } from '../../../contexts/FirebaseContext';
import useAuth from '../../../hooks/useAuth';
import { AutomationType } from '../../../redux/slices/automationsSlice';
import { Stack } from '@mui/material';
import { getInitials } from 'src/utils/names';
import { Ban, Bell, Check, CheckCheck, Clock, Send, Star, UserPlus } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { Badge } from 'src/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from 'src/components/ui/dropdown-menu';
import { AvatarFallback, AvatarImage, Avatar } from 'src/components/ui/avatar';
import { ScrollArea } from 'src/components/ui/scroll-area';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  useNotifications();
  const { translate } = useLocales();
  const { user } = useAuth();
  const notifications = useSelector((state) => state.notifications.list);

  // const [notifications, setNotifications] = useState(_notifications);

  const totalUnRead = notifications.filter((n) => !n.seen).length;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    if (!user?.id) return alert(translate('general.error.default'));
    const batch = writeBatch(DB);
    notifications.forEach((n) => {
      batch.update(doc(DB, getCollection[Collection.USERS_NOTIFICATIONS](user?.id), n.id), {
        seen: true,
      });
    });
    await batch.commit();
    handleClose();
    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     seen: false,
    //   }))
    // );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="relative min-w-10 rounded-full"
          variant="ghost"
          size="icon" /* onClick={handleOpen} */
        >
          {/* <Badge badgeContent={totalUnRead} color="error"> */}
          {totalUnRead > 0 && (
            <Badge
              style={{ top: -4, right: -2 }}
              className="absolute rounded-full px-1 text-xs justify-center min-w-[22px]"
              variant="destructive"
            >
              {totalUnRead}
            </Badge>
          )}
          <Bell style={{ width: 20, height: 20 }} />
          {/* </Badge> */}
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-96">
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <p className="text-sm text-black">{translate('notifications.title')}</p>
            <p className='text-xs text-gray-500'>
              {translate('notifications.description', { totalUnRead })}
            </p>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <Button variant="outline" size="icon" onClick={handleMarkAllAsRead}>
                <CheckCheck />
              </Button>
            </Tooltip>
          )}
        </Box>

        <DropdownMenuSeparator />

        <ScrollArea className="h-96 rounded-md">
          {/* <Scrollbar sx={{ height: { xs: 340, sm: 500 } }}> */}
          {notifications.map((notification) => (
            <>
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleClose={handleClose}
              />
              <DropdownMenuSeparator />
            </>
          ))}
        </ScrollArea>

        {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))} */}
        {/* </List> */}
        {/* </Scrollbar> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            {translate('notifications.viewAll')}
          </Button>
        </Box> */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

// ----------------------------------------------------------------------

// type NotificationItemProps = {
//   id: string;
//   title: string;
//   description: string;
//   avatar: string | null;
//   type: string;
//   createTime: Date;
//   seen: boolean;
// };

function NotificationItem({
  notification,
  handleClose,
}: {
  notification: Notification;
  handleClose: () => void;
}) {
  const { user } = useAuth();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { avatar, avatarUrl, title, link } = renderContent(notification);

  const handleNotificationClick = async () => {
    if (user?.id) {
      await setDoc(
        doc(DB, getCollection[Collection.USERS_NOTIFICATIONS](user?.id), notification.id),
        { seen: true },
        { merge: true }
      );
      if (link) {
        navigate(link);
      }
      handleClose();
    }
  };
  const isSeen = notification.seen;
  return (
    <DropdownMenuItem className={isSeen ? 'opacity-40' : ''} onClick={handleNotificationClick}>
      <Avatar className="h-9 w-9 rounded-full border-2 border-background z-1">
        <AvatarImage src={avatarUrl} alt="@shadcn" />
        <AvatarFallback className="bg-slate-300">{avatar}</AvatarFallback>
      </Avatar>
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }} src={avatarUrl}>
          {avatar}
        </Avatar>
      </ListItemAvatar> */}
      <div className="flex flex-col space-y-0.5">
        <p>{title}</p>
        <p
          className="flex text-xs text-gray-500 gap-1"
          // variant="caption"
          // sx={{
          //   mt: 0.5,
          //   display: 'flex',
          //   alignItems: 'center',
          //   color: 'text.disabled',
          // }}
        >
          <Clock size={14} />

          {
            // @ts-ignore
            translate('notifications.date', { date: notification.createTime?.toDate() })
          }
        </p>
      </div>
    </DropdownMenuItem>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: Notification) {
  if (notification.type === NotificationType.NEW_REVIEW) {
    return {
      title: (
        <p className="text-sm">
          {notification.title}
          <span>{i18n.t('notifications.newReview')}</span>
          <span className="font-bold">{` ${notification.metadata?.reviewer?.name}`}</span>
        </p>
      ),
      avatar: <Star size={16} />,
      link: `/dashboard/reviews?id=${notification.metadata?.id}`,
    };
  }
  if (notification.type === NotificationType.ACCEPTED_TEAM_INVITE) {
    return {
      title: (
        <p className="text-sm">
          {notification.title}
          <span>{i18n.t('notifications.team.accept')}</span>
          <span className="font-bold">{` ${notification.metadata?.displayName}`}</span>
        </p>
      ),
      avatar: <Check size={16} />,
      link: null,
    };
  }
  if (notification.type === NotificationType.VOIDED_TEAM_INVITE) {
    return {
      title: (
        <p className="text-sm">
          {notification.title}
          <span>{i18n.t('notifications.team.decline')}</span>
          <span className="font-bold">{` ${notification.metadata?.displayName}`}</span>
        </p>
      ),
      avatar: <Ban size={16} />,
      link: null,
    };
  }
  if (notification.type === NotificationType.REMOVE_TEAM_MEMBER) {
    return {
      title: (
        <p className="text-sm">
          {notification.title}
          <span>{i18n.t('notifications.team.removed')}</span>
          <span className="font-bold">{` ${notification.metadata?.companyName}`}</span>
        </p>
      ),
      avatar: <Ban size={16} />,
      link: null,
    };
  }
  if (notification.type === NotificationType.REVIEW_REMINDER_INVITE) {
    return {
      title: (
        <p className="text-sm">
          {notification.title}
          <span>
            {i18n.t('notifications.invite.syncReady', { count: notification.metadata?.count })}
          </span>
          {/* <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {` ${notification.metadata?.count}`}
          </Typography> */}
        </p>
      ),
      avatar: <Send />,
      link: `/dashboard/invites?tab=${AutomationType.REVIEW_REMINDER}`,
    };
  }
  if (notification.type === NotificationType.NEW_LEAD_SUBMISSION) {
    return {
      title: (
        <p className="text-sm">
          <span>{i18n.t('notifications.leads.new')}</span>
          <span className="font-bold">{notification.metadata.leadName}</span>
        </p>
      ),
      avatar: <UserPlus size={16} />,
      link: `/dashboard/leads/list`,
    };
  }
  if (notification.type === NotificationType.NEW_CHAT_MESSAGE) {
    return {
      title: (
        <p className="text-sm">
          <span>{i18n.t('notifications.chat.new_message')}</span>
          <span className="font-bold">{` ${notification.metadata?.displayName}`}</span>
        </p>
      ),
      avatar: notification.metadata?.displayName
        ? getInitials(notification.metadata?.displayName)
        : null,
      avatarUrl: notification.metadata?.photoURL,
      link: `/dashboard/chat/conversations?conversation=${notification.metadata?.conversationId}`,
    };
  }

  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  return {
    // avatar: notification?.avatar ? (
    //   <img alt={notification.title} src={notification.avatar} />
    // ) : null,
    title: 'unknown',
    avatar: notification?.metadata?.reviewer?.img,
    link: null,
  };
}
