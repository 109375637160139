import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import usePresence from '../hooks/usePresence';
import usePublic from '../hooks/usePublic';
import InitialSettings from '../sections/@dashboard/user/initial/InitialSettings';
import { useSelector } from '../redux/store';
import { RequestState } from '../enum';
import { selectIsUserReady } from '../redux/slices/userSlice';
import { PATH_AUTH } from '../routes/paths';
import useAutomations from '../hooks/useAutomations';
import useInviteReminders from 'src/hooks/useInviteReminders';
import useLeadForms from '../hooks/useLeadForm';
import useFormSubmissions from '../hooks/useLeadFormSubmissions';
import { hasAllPlatformsLoaded } from 'src/redux/selectors/platform';
import InitialPickWorkspace from 'src/sections/@dashboard/user/initial/InitialPickWorkspace';
// import useRequestNotificationToken from 'src/notifications/useRequestNotificationToken';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  // useRequestNotificationToken();
  usePresence();
  usePublic();
  useAutomations();
  useInviteReminders();
  useLeadForms();
  useFormSubmissions();
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const isUserReady = useSelector(selectIsUserReady);
  const isConfigReady = useSelector((state) => state.publicConfig.status === RequestState.RESOLVED);
  const isWorkspaceSettingUp = useSelector((state) => Boolean(state.user.settingUpWorkspace));
  const isAllWorkspacesLoaded = useSelector(hasAllPlatformsLoaded);

  // console.log({
  //   isAuthenticated,
  //   isInitialized,
  //   isUserReady,
  //   isConfigReady,
  //   isWorkspaceSettingUp,
  //   isAllWorkspacesLoaded,
  //   pathname,
  //   requestedLocation,
  // });

  // Wait for initializations
  if (!isInitialized) {
    return <LoadingScreen initialSetup={isWorkspaceSettingUp} />;
  }
  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_AUTH.login} />;
  }

  // if data is not loaded yet show loading screen
  if (!isAllWorkspacesLoaded || !isUserReady || isWorkspaceSettingUp) {
    return <LoadingScreen initialSetup={isWorkspaceSettingUp} />;
  }

  // console.log('READY TO GO', isUserReady, isWorkspaceSettingUp);

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <>
      {children}
      {isConfigReady && <InitialSettings />}
      {isConfigReady && <InitialPickWorkspace />}
    </>
  );
}
