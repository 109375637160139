import { useEffect, useState } from 'react';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
// import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import {
  setWorkspaceReviews,
  setWorkspaceReviewsState,
  WorkspaceReview,
} from '../redux/slices/workspaceReviewsSlice';

export default function useWorkspaceReviews() {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) =>
      state.workspaceReview.state !== RequestState.RESOLVED &&
      state.workspaceReview.state !== RequestState.REJECTED
  );
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_REVIEWS]()),
        where(`members.${userId}.uid`, '==', userId)
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.group('useWorkspaceReviews');
          const workspaceReviews = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as WorkspaceReview[];
          async function setWorkspaceReviewData() {
            dispatch(setWorkspaceReviews({ workspaces: workspaceReviews }));
            console.groupEnd();
          }
          setWorkspaceReviewData();
        },
        (error) => {
          dispatch(setWorkspaceReviewsState(RequestState.REJECTED));

          console.error('useWorkspaceReviews', error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingWorkspaceReviews: isLoading };
}
