import React from 'react';
import { Box, Dialog, Slide, Grid, Stack, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'src/redux/store';
import { ModalType, setModal } from 'src/redux/slices/modal';
import { TransitionProps } from '@mui/material/transitions';
import IntegrationsWizard from './IntegrationsWizard';
import Iconify from 'src/components/Iconify';
import { useTheme } from '@mui/material/styles';

export default function WidgetModalContainer() {
  const theme = useTheme();
  const isModalOpen = useSelector((state) => state.modal.type === ModalType.WIDGET);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setModal(null));
  };
  return (
    <Dialog fullScreen open={isModalOpen} onClose={handleClose} TransitionComponent={Transition}>
      <Box>
        <IconButton
          sx={{ position: 'absolute', top: 20, right: 20, zIndex: 100 }}
          onClick={handleClose}
        >
          <Iconify
            icon="ep:close-bold"
            color={theme.palette.grey[400]}
            sx={{ width: 48, height: 48 }}
          />
        </IconButton>
        <Stack direction="column" sx={{ height: '100vh', justifyContent: 'center' }}>
          <Grid container sx={{ height: '100%' }}>
            {/* <Grid item md={1} xs={0} /> */}
            <Grid item xs={12} md={12}>
              <IntegrationsWizard handleClose={handleClose} />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Dialog>
  );
}

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) => <Slide direction="up" ref={ref} {...props} />);
