'use client';

import { ChevronsUpDown, Lock, TriangleAlert, Users } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from 'src/components/ui/sidebar';
import { useWorkspaceSettings } from '../workspaceSettings/context';
import { useDispatch, useSelector } from 'src/redux/store';
import usePublicConfigs from './usePublicConfigs';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { toast } from 'sonner';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import {
  selectCurrentWorkspaceId,
  selectHasPersonalWorkspace,
  selectUserId,
  setCurrentWorkspace,
} from 'src/redux/slices/userSlice';

export function TeamSwitcher() {
  const dispatch = useDispatch();
  const { openDialog } = useWorkspaceSettings();
  const { isMobile, state } = useSidebar();
  const userId = useSelector(selectUserId);
  const currentWorkspaceId = useSelector(selectCurrentWorkspaceId);
  const { workspaces } = usePublicConfigs();
  const hasPersonalWorkspace = useSelector(selectHasPersonalWorkspace);
  const switchWorkspace = async (workspaceId: string) => {
    try {
      if (!userId) throw new Error('User not found');
      dispatch(setCurrentWorkspace(workspaceId));
      const userRef = doc(collection(DB, 'users'), userId);
      await setDoc(userRef, { currentWorkspace: workspaceId }, { merge: true });
      toast.success('Workspace switched successfully');
    } catch (error) {
      console.error(error);
      toast.error('Failed to switch workspace');
    }
  };

  const isCollapsed = state === 'collapsed';

  const currentWorkspace = workspaces.find(
    (workspace) => workspace.workspaceId === currentWorkspaceId
  );

  return (
    <SidebarMenu data-joyride-id="new-sidebar-workspaces">
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground overflow-hidden">
                <Avatar className="size-8 rounded-none">
                  <AvatarImage
                    src={currentWorkspace?.workspaceLogo}
                    alt={currentWorkspace?.workspaceTitle}
                  />
                  <AvatarFallback className="rounded-none bg-transparent">
                    {currentWorkspace?.workspaceTitle?.[0] || <TriangleAlert size={16} />}
                  </AvatarFallback>
                </Avatar>
              </div>
              {!isCollapsed && (
                <>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">
                      {currentWorkspace?.workspaceTitle || 'No workspace yet'}
                    </span>
                    {/* <span className="truncate text-xs">{activeTeam.plan}</span> */}
                  </div>
                  <ChevronsUpDown className="ml-auto" />
                </>
              )}
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? 'bottom' : 'right'}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              {currentWorkspace?.workspaceTitle}
            </DropdownMenuLabel>

            <DropdownMenuItem
              key="team"
              onClick={openDialog}
              className="gap-2 p-2"
              disabled={!hasPersonalWorkspace}
            >
              <div className="flex size-6 items-center justify-center rounded-sm border">
                <Users className="size-4 shrink-0" />
              </div>
              {!hasPersonalWorkspace && <Lock />}
              Manage team
              {/* <DropdownMenuShortcut>⌘{1}</DropdownMenuShortcut> */}
            </DropdownMenuItem>

            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Workspaces
            </DropdownMenuLabel>
            {workspaces.map((workspace, index) => (
              <DropdownMenuItem
                key={workspace.workspaceId}
                className={'gap-2 p-2'}
                onClick={() => switchWorkspace(workspace.workspaceId)}
              >
                <div className="flex size-6 items-center justify-center rounded-sm border overflow-hidden">
                  {/* <team.logo className="size-4 shrink-0" /> */}
                  <Avatar className="size-6 rounded-none">
                    <AvatarImage src={workspace?.workspaceLogo} alt={workspace?.workspaceTitle} />
                    <AvatarFallback className="rounded-none">
                      {workspace.workspaceTitle?.[0]}
                    </AvatarFallback>
                  </Avatar>
                </div>
                {workspace?.workspaceTitle}
                {/* <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut> */}
              </DropdownMenuItem>
            ))}
            {/* {!hasPersonalWorkspace && (
              <DropdownMenuItem key="personal-workspace" className={'gap-2 p-2'} onClick={() => {}}>
                <div className="flex size-6 items-center justify-center rounded-sm border overflow-hidden">
            
                  <Avatar className="size-6 rounded-none items-center justify-center">
                    <Plus size={16} />
                  </Avatar>
                </div>
                Create personal workspace
            
              </DropdownMenuItem>
            )} */}
            {/* <DropdownMenuSeparator /> */}
            {/* <DropdowWnMenuItem className="gap-2 p-2">
              <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                <Plus className="size-4" />
              </div>
              <div className="font-medium text-muted-foreground">Add team</div>
            </DropdownMenuItem> */}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
