import * as React from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useSelector } from '../../../../redux/store';
import { useForm } from 'react-hook-form';
import { ROOTS } from '../../../../enum';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { Functions } from '../../../../contexts/FirebaseContext';
import { Platforms } from '../../../../redux/slices/platform';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from 'src/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { PrefixedInput } from 'src/components/PrefixInput';
import { toast } from 'sonner';
import { Dialog, DialogContent, DialogHeader } from 'src/components/ui/dialog';

const rootUrl = process.env.NODE_ENV === 'development' ? ROOTS.DEV : ROOTS.PROD;

type InitialSettingsProps = { slug: string; companyName: string };

export const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

function getPrefixLink(platform: Platforms) {
  if (platform === Platforms.SIMPLY_REVIEW) {
    return `${rootUrl}/r/`;
  }
  if (platform === Platforms.SIMPLY_LEADS) {
    return `https://app.simplyleads.io/l/`;
  }
  if (platform === Platforms.SIMPLY_LINKS) {
    return `https://simplylink.io/`;
  }
  // fallback simplyreview
  return `${rootUrl}/r/`;
}

export default function InitialSettings() {
  const { t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();
  const config = useSelector((state) => state.publicConfig);
  const platform = useSelector((state) => state.user.platform);

  const open = useSelector(
    (state) =>
      state.publicConfig.id &&
      (!state.publicConfig.companyName || !state.publicConfig.slug) &&
      (state.user.platform === Platforms.SIMPLY_REVIEW ||
        state.user.platform === Platforms.SIMPLY_FLOW ||
        state.user.platform === Platforms.SIMPLY_LEADS ||
        state.user.platform === Platforms.SIMPLY_LINKS)
    // state.user.platform === Platforms.SIMPLY_REVIEW
  );

  const userId = useSelector((state) => state.user.id);
  const id = useSelector((state) => state.publicConfig?.id);

  const companyName = useSelector((state) => state.publicConfig.companyName);
  const slug = useSelector((state) => state.publicConfig.slug);

  const handleConfirmModal = () => {};
  const UpdateUserSchema = z.object({
    slug: z
      .string()
      .regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, { message: t('validation.slug.formatError') as string })
      .min(1, { message: t('validation.slug.required') as string }),
    companyName: z.string().min(1, { message: t('validation.companyName') as string }),
  });
  const defaultValues = { companyName, slug };

  const form = useForm<InitialSettingsProps>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues,
  });
  const {
    // watch,
    // setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = form;
  // const values = watch();
  const onSubmitSlug = async (data: any) => {
    try {
      if (!userId) throw new Error(`userId invalid`);

      const functionRef = httpsCallable(Functions, 'settings');
      const response: any = await functionRef({
        request: 'RESERVE_SLUG',
        publicConfigId: id,
        slug: data.slug?.trim(),
        companyName: data.companyName,
      });
      // const configId = response?.data?.id;

      // @ts-ignore
      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        toast.error(t('validation.slug.format'));
        return;
      }

      toast.success(t('form.save.success'));
      // onClose();
      // setStep(Steps.INDUSTRY);
      // setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error(t('general.error.default'));
    }
  };
  const onSubmit = async (data: InitialSettingsProps) => {
    try {
      await onSubmitSlug(data);
    } catch (error) {
      console.error(error);
    }
  };

  const baseUrl = getPrefixLink(platform);
  return (
    <Dialog
      open={Boolean(open)}
      // TransitionComponent={Transition}
      // keepMounted
      // onClose={handleConfirmModal}
      aria-describedby="alert-dialog-slide-description"
    >
      {/* <DialogTitle> */}
      {/* </DialogTitle> */}

      <DialogContent>
        <DialogHeader>
          <p className="text-xl font-bold">{t('form.initialSettings.header')}</p>
        </DialogHeader>
        <div className="grid gap-6 pt-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className="flex flex-col gap-6">
                <FormField
                  control={form.control}
                  name="companyName"
                  render={({ field }) => (
                    <FormItem className="flex flex-col w-full">
                      <FormLabel>{t('form.companyName.header')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('form.companyName.placeholder') as string}
                          {...field}
                        />
                      </FormControl>
                      {/* <FormDescription>This is your public display name.</FormDescription> */}
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="slug"
                  render={({ field }) => (
                    <FormItem className="flex flex-col w-full">
                      <FormLabel>{t('form.link.header')}</FormLabel>
                      <FormLabel className="text-xs text-muted-foreground">
                        {t('form.companyslug.placeholder', { baseUrl })}
                      </FormLabel>
                      <FormControl>
                        <PrefixedInput prefix={baseUrl} placeholder="" {...field} />
                      </FormControl>
                      {/* <FormDescription>This is your public display name.</FormDescription> */}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button className="w-full" type="submit" loading={isSubmitting}>
                {t('form.saveBtn')}
              </Button>
            </form>
          </Form>
        </div>
      </DialogContent>
      {/* <Button onClick={handleClose}>Disagree</Button> */}
    </Dialog>
  );
}
