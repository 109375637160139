import { ReactElement, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, Stack, Switch } from '@mui/material';
// components
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import { Subscription, PriceId } from '../../enum';
import Subscribe from '../../stripe/components/Subscribe';
import ManageSubscription from '../../stripe/components/ManageSubscription';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import { selectIsSuperOwner } from 'src/redux/slices/userSlice';
import { hasFlowPremiumSelector } from 'src/redux/slices/workspaceFlowSlice';
import {
  StripeSubscriptionStatus,
  selectFlowSubscriptions,
  selectHasStripeLoaded,
  selectLatestBundleSubscription,
  selectLatestFlowSubscription,
} from 'src/redux/slices/stripeSlice';
import SimplyFlowLogo from 'src/assets/Logo/SimplyFlowLogo';
import { FLOW } from 'src/theme/palette';
import { selectWorkspaceFlowHasWorskpaces } from 'src/redux/selectors/workspaces';
import { Button } from 'src/components/ui/button';
import Logo from 'src/components/SRLogo';
import { Card } from 'src/components/ui/card';
import SetupFlow from 'src/modals/flow/setupFlow';

// ----------------------------------------------------------------------

// const RootStyle = styled(Card)(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   height: '100%',
//   display: 'flex',
//   position: 'relative',
//   alignItems: 'center',
//   flexDirection: 'column',
//   padding: theme.spacing(3),
//   [theme.breakpoints.up(414)]: {
//     padding: theme.spacing(5),
//   },
// }));

const lists = [
  { text: 'pricing.premiumflow.item1', isAvailable: true },
  { text: 'pricing.premiumflow.item2', isAvailable: true },
  { text: 'pricing.premiumflow.item3', isAvailable: true },
  { text: 'pricing.premiumflow.item4', isAvailable: true },
  { text: 'pricing.premiumflow.item5', isAvailable: true },
  { text: 'pricing.premiumflow.item6', isAvailable: true },
];

// ----------------------------------------------------------------------

type Props = {
  index: number;
  logo?: React.ReactElement;
};

export default function PricingPlanCardPremium({ index, logo }: Props) {
  const { user } = useAuth();
  const [priceId, setPriceId] = useState(PriceId.SIMPLY_FLOW_MONTHLY);
  const { t } = useTranslation();
  const isSuperOwner = useSelector(selectIsSuperOwner);
  const stripeHasLoaded = useSelector(selectHasStripeLoaded);
  const hasSubscription = useSelector(selectFlowSubscriptions);
  const hasFlowPremium = useSelector(hasFlowPremiumSelector);
  const bundleSubscription = useSelector(selectLatestBundleSubscription);
  const flowSub = useSelector(selectLatestFlowSubscription);
  const hasFlow = useSelector(selectWorkspaceFlowHasWorskpaces);

  const latestSubscriptionIsCancelled = flowSub?.status === StripeSubscriptionStatus.Canceled;

  const renderSubscription = () => {
    if (!stripeHasLoaded) {
      return <Button loading size="lg" className="w-full" />;
    }
    if (!isSuperOwner) {
      return (
        <Button disabled size="lg" className="w-full">
          Only the owner can upgrade billing
        </Button>
      );
    }
    if (
      bundleSubscription?.status &&
      bundleSubscription?.status !== StripeSubscriptionStatus.Canceled
    ) {
      return (
        <Button disabled size="lg" className="w-full">
          You are Simply Bundle so this is included
        </Button>
      );
    }
    if (!hasFlow) {
      return <SetupFlow />;
    }
    if (hasFlowPremium && hasSubscription?.length < 1) {
      return (
        <Button disabled size="lg" className="w-full">
          Seems like you got this one for free, lucky you
        </Button>
      );
    }
    if ((!hasFlowPremium && hasSubscription?.length < 1) || latestSubscriptionIsCancelled) {
      return <Subscribe priceId={priceId} />;
    }

    return <ManageSubscription />;
  };

  return (
    <Card className="flex flex-col min-w-96 relative items-center p-10 shadow-none">
      {/* {priceId === PriceId.YEARLY && (
        <Label
          color="info"
          sx={{
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          {t('general.popular')}
        </Label>
      )} */}
      {logo && (
        <div className="absolute opacity-20 top-4 left-4 flex justify-center items-center">
          <Logo />
        </div>
      )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {t('pricing.premiumflow.subHeader')}
      </Typography>
      <Stack sx={{ visibility: 'hidden' }} direction="row" spacing={1} alignItems="center">
        <Typography>{t('general.yearly')}</Typography>
        <Switch
          value={priceId === PriceId.SIMPLY_FLOW_MONTHLY}
          onChange={() => {}}
          inputProps={{ 'aria-label': 'ant design' }}
        />
        <Typography>{t('general.monthly')}</Typography>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        {index === 1 || index === 2 ? (
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            €
          </Typography>
        ) : (
          ''
        )}
        <Typography variant="h2" sx={{ mx: 1 }}>
          {9}
        </Typography>
        {index === 1 || index === 2 ? (
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary',
            }}
          >
            /{t('general.date.month.short')}
          </Typography>
        ) : (
          ''
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {priceId !== PriceId.SIMPLY_FLOW_MONTHLY ? (
          `(${t('pricing.premiumflow.priceSubText')})`
        ) : (
          <span style={{ visibility: 'hidden' }}>_</span>
        )}
      </Typography>

      {/* <Box sx={{ width: 80, height: 80, mt: 3 }}>
        <PlanPremiumIcon />
      </Box> */}

      <Stack component="ul" spacing={1.5} sx={{ my: 2, mb: 3, width: 1 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ typography: 'body2', color: item.isAvailable ? 'text.primary' : 'text.disabled' }}
          >
            {item.isAvailable ? (
              <Box>
                <Iconify icon={'eva:checkmark-fill'} sx={{ width: 20, height: 20 }} />
              </Box>
            ) : (
              <Iconify icon={'bi:x'} sx={{ width: 20, height: 20 }} />
            )}
            <p className="text-sm">{t(item.text)}</p>
          </Stack>
        ))}
      </Stack>
      <Box flexGrow={1} />
      {renderSubscription()}
    </Card>
  );
}
