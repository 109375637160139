import { Badge } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'src/redux/store';
import { selectInviteRemindersCount } from 'src/redux/slices/inviteReminders';

export default function InvitePendingBadge() {
  const inviteReminderCount = useSelector(selectInviteRemindersCount);
  return (
    <Box sx={{ position: 'absolute', right: 18, top: 4 }}>
      <Badge color="error" badgeContent={inviteReminderCount} />
    </Box>
  );
}
