// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { useEffect } from 'react';
import { Segment } from './utils/segment';
import Joyrider from './components/joyride';
import useUser from './hooks/useUser';
import useUserOnboarding from './hooks/useUserOnboarding';
import useReviewIntegrations from './hooks/useReviewIntegrations';
import useReviewStats from './hooks/useReviewStats';
// import usePublic from './hooks/usePublic';
import './App.css';
import WidgetModalContainer from './pages/Integrations/Modal';
import ModalContainer from './modals';
// import InitialSettings from './sections/@dashboard/user/initial/InitialSettings';
import useWorkspaceReviews from './hooks/useWorkspaceReviews';
import useTeamInvites from './hooks/useTeamInvites';
import useWorkspaceFlow from './hooks/useWorkspaceFlow';
import useFlowIntegrations from './hooks/useFlowIntegrations';
import useApiIntegrations from './hooks/useApiIntegrations';
import useWorkspaceLeads from './hooks/useWorkspaceLeads';
import SubscriptionModal from './modals/SubscriptionModal';
import useWorkspaceLinks from './hooks/useWorkspaceLinks';
import useZenfitLeadApiIntegrations from './hooks/useZenfitLeadApiIntegrations';
import useGoogleCalendarIntegrations from './hooks/useGoogleCalendarIntegration';
import useWorkspaceBooking from './hooks/useWorkspaceBooking';
import ModalBookingsInitialContainer from './modals/bookings/ModalBookingInitialContainer';
import useStripe from './hooks/useStripe';
import InAppMessages from './components/InAppMessages';
import { Toaster } from 'src/components/ui/sonner';
import { SupportChatProvider } from './components/chat/SupportChatContext';
import SupportChat from './components/chat/SupportChat';
import { TooltipProvider } from './components/ui/tooltip';
// ----------------------------------------------------------------------

export default function App() {
  const { isLoadingUser } = useUser();
  const { isLoadingWorkspaceReviews } = useWorkspaceReviews();
  useWorkspaceFlow();
  useWorkspaceLeads();
  useWorkspaceLinks();
  useWorkspaceBooking();
  const { isLoading } = useUserOnboarding();
  useApiIntegrations();
  useZenfitLeadApiIntegrations();
  useGoogleCalendarIntegrations();
  useReviewIntegrations();
  useFlowIntegrations();
  useTeamInvites();
  useReviewStats();
  useStripe();
  useEffect(() => {
    Segment.init();
    return () => {};
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <SupportChatProvider>
                  <TooltipProvider>
                    <Joyrider />
                    <ProgressBarStyle />
                    <ChartStyle />
                    {/* <Settings /> */}
                    <ScrollToTop />
                    {!isLoading && !isLoadingUser && !isLoadingWorkspaceReviews && (
                      <>
                        <WidgetModalContainer />
                        <ModalContainer />
                      </>
                    )}
                    <SupportChat />
                    <InAppMessages />
                    <Router />
                    <Toaster />
                  </TooltipProvider>
                </SupportChatProvider>
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
