import { Grid, Card, useTheme } from '@mui/material';
import Label from 'src/components/Label';
import { RequestState, IMAGE_METADATA_TYPE } from 'src/enum';
import ReviewInfo from 'src/pages/client/ReviewIntro';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { upload } from 'src/hooks/useUpload';
import AlertDialogSlide from './ConfirmDialog';
import { useTranslation } from 'react-i18next';
import usePageSettings from './usePageSettings';
import Box from '@mui/material/Box';
import { hex2rgb } from 'src/utils/colors';
import OnboardingSlug from './OnboardingSlug';

function getBackgroundColor(color?: string) {
  if (color) {
    const [r, g, b] = hex2rgb(color);
    return `rgba(${r},${g},${b}, 0.1)`;
  }
  return null;
}

// const defaultDescription = `Thanks for sharing your experience with us 🏆
// We would love a video review. Keep the review short, precise and honest.

// Thanks a million 🤩`;

interface Props {
  userId: string;
}

export default function PageSettings({ userId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation();
  const pageSettings = usePageSettings();

  // const defaultTitle = `Share your experience with us`;
  const backgroundColor = getBackgroundColor(pageSettings?.color);

  const onSubmitSlug = async (data: any) => {
    try {
      if (!userId) throw new Error(`userId invalid`);

      const updateMessage = t(`reviewPageSettings.update.message.general`);
      const updateMessageVideo = t('reviewPageSettings.update.message.video');
      enqueueSnackbar(
        data.videoIntro !== pageSettings.videoIntroInit ? updateMessageVideo : updateMessage,
        {
          variant: 'info',
        }
      );
      const configId = pageSettings.id;
      let logoUrl;
      if (!configId) {
        throw new Error('No id returned');
      }
      if (data.logo !== pageSettings.logoInit) {
        const response = await upload(
          data.logo as Blob,
          `workspaceReviews/${userId}/config/${configId}/`,
          {
            userId,
            configId,
            type: IMAGE_METADATA_TYPE.REVIEW_SETTINGS,
          }
        );

        // @ts-ignore
        if (response?.url) {
          // @ts-ignore
          logoUrl = response.url;
        }
      }

      const functionRef = httpsCallable(Functions, 'settings');
      const response: any = await functionRef({
        request: 'REVIEW_PAGE_SETTINGS',
        id: pageSettings.id,
        title: pageSettings.title,
        description: pageSettings.description,
        questions: pageSettings.questions,
        color: pageSettings.color,
        onlyVideo: pageSettings.onlyVideo,
        reviewerPageLanguage: pageSettings.reviewerPageLanguage,
        companyLogo: logoUrl,
      });
      if (data.videoIntro !== pageSettings.videoIntroInit && data.videoIntro) {
        await upload(data.videoIntro as Blob, `workspaceReviews/${userId}/config/${configId}/`, {
          userId,
          configId,
        });
      }
      enqueueSnackbar(t('reviewPageSettings.update.message.success'));
      pageSettings.setVideoIntroPreview(undefined);
      // onClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  return (
    <Grid container spacing={2} sx={{ pl: 4, height: '100%', mt: 0 }}>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ overflowY: 'scroll', height: '100%', maxHeight: '100vh', pb: 4, pr: 4 }}
      >
        {pageSettings.configStatus === RequestState.RESOLVED && (
          <OnboardingSlug
            configId={pageSettings.id}
            onSubmitSlug={onSubmitSlug}
            setavatar={pageSettings.setavatar}
            setVideoIntro={pageSettings.setVideoIntroPreview}
            initialQuestions={pageSettings.questions}
            onQuestionChange={(questions: any) => pageSettings.setQuestions(questions)}
            onTitleChange={pageSettings.setTitle}
            onDescriptionChange={pageSettings.setDescription}
            color={pageSettings.color}
            onColorChange={pageSettings.setColor}
            onReviewerPageLanguageChange={pageSettings.setReviewerPageLanguage}
            onlyVideo={pageSettings.onlyVideo}
            onOnlyVideoChange={pageSettings.setOnlyVideo}
            logoInit={pageSettings.logoInit}
            videoIntroInit={pageSettings.videoIntroInit}
            defaultValues={{
              title: pageSettings.titleInit || t('review.form.titlePlaceholder'),
              description: pageSettings.descriptionInit || t('review.form.descriptionPlaceholder'),
              logo: pageSettings.logoInit,
              videoIntro: pageSettings.videoIntroInit,
              reviewerPageLanguage: pageSettings.reviewerPageLanguage,
            }}
          />
        )}
      </Grid>

      <Grid
        xs={12}
        md={8}
        sx={{
          // position: 'relative',
          // display: 'flex',
          // overflowY: 'scroll',
          // justifyContent: 'center',
          // maxHeight: '100vh',
          // height: '100%',
          alignItems: 'center',
          // pr: 2,
          // pt: 2,
          // pb: 2,
          pt: 0,
          paddingTop: 0,
          background: backgroundColor || theme.palette.background.neutral,
        }}
      >
        <Box
          sx={{
            maxHeight: '100vh',
            overflowY: 'scroll',
            p: 6,
            position: 'relative',
          }}
        >
          <Label
            variant={'ghost'}
            color={'success'}
            sx={{ position: 'absolute', zIndex: 100, left: 96, top: 36 }}
          >
            {t('reviewPageSettings.preview.badge')}
          </Label>
          <Card
            sx={{
              mr: 6,
              ml: 6,
              mt: 0,
              p: 3,
              height: '100%' /* height: '100%', overflowY: 'scroll' */,
            }}
          >
            {/* {ReviewType.INTRO === reviewType && ( */}
            <ReviewInfo
              avatar={pageSettings.avatar}
              title={pageSettings.title}
              color={pageSettings.color}
              description={pageSettings.description}
              questions={pageSettings.questions}
              onlyVideo={pageSettings.onlyVideo}
              reviewerPageLanguage={pageSettings.reviewerPageLanguage}
              // reviewType={reviewType}
              videoIntroPreview={pageSettings.videoIntroPreview}
              videoIntro={pageSettings.videoIntroInit}
              setReviewType={() => pageSettings.setShowModal(true)}
            />
          </Card>
        </Box>
      </Grid>
      <AlertDialogSlide
        title={t('reviewPageSettings.preview.alert')}
        open={pageSettings.showModal}
        handleClose={() => pageSettings.setShowModal(false)}
      />
    </Grid>
  );
}
