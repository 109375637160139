import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Avatar, AvatarFallback } from 'src/components/ui/avatar';
import { useTranslation } from 'react-i18next';
import { UserPlus } from 'lucide-react';

type AddTeamProps = {
  onClick: () => void;
};

export default function AddTeam({ onClick }: AddTeamProps) {
  const { t } = useTranslation();
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Avatar
          aria-label="invite-team"
          className="h-9 w-9 rounded-full border-2 border-background z-1"
          onClick={onClick}
        >
          <AvatarFallback className="bg-slate-300">
            <UserPlus size={16} />
          </AvatarFallback>
        </Avatar>
      </TooltipTrigger>
      <TooltipContent>
        <p>{t('team.invite.add.tooltip')}</p>
      </TooltipContent>
    </Tooltip>
  );
}
