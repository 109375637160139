import React from 'react';
import { IconButton } from '@mui/material';
import Iconify from './Iconify';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

export default function InfoTooltip({ title, size }: { title: string; size?: 'small' }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton size={size}>
          <Iconify icon="bx:info-circle" />
        </IconButton>
      </TooltipTrigger>
      <TooltipContent className="max-w-80 whitespace-pre-line">{title}</TooltipContent>
    </Tooltip>
  );
}
