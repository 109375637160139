// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  DialogActions,
  ToggleButton,
  Switch,
  Stack,
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';
import { useSelector } from '../../../redux/store';
import { useTranslation } from 'react-i18next';
import MenuPopover from '../../../components/MenuPopover';
import { PremiumFeatureLabel } from '../../../components/PremiumFeature';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasSmsLimitExceeded } from '../../../redux/slices/workspaceReviewsSlice';
import { Transition } from 'src/sections/@dashboard/user/initial/InitialSettings';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { PhoneCountryField, PhoneField, countries, phoneCustom } from 'src/components/PhoneField';
import { addDoc, collection } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { Collection, Intl } from 'src/enum';
import { LeadType } from 'src/redux/slices/leadFormsSubmissionSlice';
import { selectLeadFormLanguage } from 'src/redux/slices/leadFormsSlice';
import Android12Switch from 'src/components/customSwitch';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  numSelected: number;
  filterName: string;
  onFilterName: (value: string) => void;
  onDeleteUsers: VoidFunction;
  additionalParameters?: string;
  disabled?: boolean;
};

interface Lead {
  name: string;
  email: string;
  countryCode: string;
  phone: string;
}

function getCountryCodeFromLang(leadFormLanguage: Intl) {
  const country = countries.find((c) => c.languageCode === leadFormLanguage);
  return country?.phone || '+47';
}

export default function BookingsToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDeleteUsers,
  additionalParameters,
  disabled,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLight = theme.palette.mode === 'light';
  const [modal, setmodal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const leadFormLanguage = useSelector(selectLeadFormLanguage);
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const InviteMemberSchema = z.object({
    name: z.string().min(1, { message: t('invites.validation.name') as string }),
    email: z
      .string()
      .email({ message: t('invites.validation.email') as string })
      .min(1, { message: t('invites.validation.email') as string }),
    phone: z.string().optional(),
  });

  const defaultValues = {
    name: '',
    email: '',
    countryCode: getCountryCodeFromLang(leadFormLanguage),
    phone: '',
  };

  const methods = useForm<Lead>({
    resolver: zodResolver(InviteMemberSchema),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  const onSubmit = async (data: Lead) => {
    try {
      // // await new Promise((resolve) => setTimeout(resolve, 500));
      // const inviteType = resolveInviteType(type);
      if (!workspaceId) {
        return alert(t('general.error.default'));
      }
      const { name, email, phone, countryCode } = data;

      await addDoc(collection(DB, getCollection[Collection.LEADS_FORMS_SUBMISSIONS](workspaceId)), {
        name,
        email,
        phone: `${countryCode}${phone}`.split(' ').join(''),
        createTime: new Date(),
        type: LeadType.MANUAL,
      });
      enqueueSnackbar(t('leads.added'), { variant: 'success' });
      reset();

      setmodal(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: isLight ? 'primary.main' : 'text.primary',
            bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} {t('general.selected')}
          </Typography>
        ) : (
          <InputStyle
            size="small"
            stretchStart={240}
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder={`${t('general.search')}...`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}

        <Stack direction="row" alignItems="center">
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton onClick={onDeleteUsers}>
                <Iconify icon={'eva:trash-2-outline'} />
              </IconButton>
            </Tooltip>
          ) : (
            <Button variant="contained" disabled={disabled} onClick={() => setmodal(true)}>
              {t('leads.add.new')}
            </Button>
          )}
        </Stack>
      </RootStyle>
      <Dialog
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={() => setmodal(false)}
        aria-describedby="handle-leads-new"
        maxWidth="sm"
      >
        <DialogTitle>{t('leads.form.title')}</DialogTitle>
        <DialogContent>
          <FormProvider
            style={{ height: 'calc(100% - 64px)' }}
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid
              container
              spacing={2}
              sx={{ height: '100%' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sx={{ height: '100%' }}>
                <Box sx={{ p: 0, pt: 4, position: 'relative', height: '100%' }}>
                  <Box sx={{ py: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <RHFTextField
                          // {...register(`invites.${i}.email`)}
                          name={'name'}
                          label={t('leads.form.fields.name')}
                        />
                        {/* <Stack flexDirection="row" justifyContent="space-between"></Stack> */}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <RHFTextField
                          // {...register(`invites.${i}.email`)}
                          name={'email'}
                          label={t('leads.form.fields.email')}
                        />
                        {/* <Stack flexDirection="row" justifyContent="space-between"></Stack> */}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <PhoneField
                          // size="small"
                          // {...register(`invites.${i}.phone`)}
                          name={`phone`}
                          label={t('leads.form.fields.phone')}
                          InputProps={{
                            inputComponent: phoneCustom as any,
                            startAdornment: (
                              <InputAdornment sx={{ width: 200 }} position="start">
                                <PhoneCountryField
                                  id="outlined-select-currency"
                                  name={'countryCode'}
                                >
                                  {countries.map((option) => (
                                    <option key={option.phone} value={option.phone}>
                                      {option.flag} {option.phone}
                                    </option>
                                  ))}
                                </PhoneCountryField>
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors?.phone}
                          helperText={errors?.phone?.message || ''}
                        />
                        {/* <Stack flexDirection="row" justifyContent="space-between"></Stack> */}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            disabled={isSubmitting}
            // type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            {t('leads.form.add')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
