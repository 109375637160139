import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, DialogActions } from '@mui/material';
import { useSelector } from '../redux/store';
import { RemoveMemberContext } from '../redux/slices/modal';
import { httpsCallable } from 'firebase/functions';
import { Functions } from '../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { selectCurrentWorkspaceId, selectPlatform } from '../redux/slices/userSlice';

export default function RemoveMember({ handleClose }: { handleClose: () => void }) {
  const [isRemoving, setIsRemoving] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const platform = useSelector(selectPlatform);
  const { inviteId, userId } = useSelector((state) => state.modal.context as RemoveMemberContext);
  const displayName = useSelector(
    (state) =>
      state.workspaceTeamInvites.workspaceTeamInviteMap[inviteId]?.displayName ||
      state.workspaceReview.workspaceMap?.[workspaceId as string]?.members[userId]?.displayName ||
      state.workspaceFlow.workspaceMap?.[workspaceId as string]?.members[userId]?.displayName ||
      state.workspaceLeads.workspaceMap?.[workspaceId as string]?.members[userId]?.displayName
  );
  const handleOk = async () => {
    try {
      if (!workspaceId) return alert(t('general.error.default'));
      setIsRemoving(true);
      const functionRef = httpsCallable(Functions, 'removeTeamMember');
      const response: any = await functionRef({
        inviteId,
        userId,
        workspaceId,
        platform,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(t('team.remove.member.success'), { variant: 'success' });
        handleClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsRemoving(false);
    }
  };
  return (
    <>
      <DialogTitle id="alert-dialog-title">🚨{t('team.remove.member.title')}🚨</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('team.remove.member.body', { displayName })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('general.no')}</Button>
        <LoadingButton onClick={handleOk} loading={isRemoving} variant="contained" color="error">
          {t('general.yes')}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
