'use client';

import * as React from 'react';

import { NavMain } from 'src/components/sidebar/nav-main';
import { NavInfo } from 'src/components/sidebar/nav-info';
import { NavUser } from 'src/components/sidebar/nav-user';
import { TeamSwitcher } from 'src/components/sidebar/team-switcher';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from 'src/components/ui/sidebar';
import InvitePendingBadge from '../badges/InvitePendingBadge';
import MissingAutomationSetup from '../badges/MissingAutomation';
import { PATH_DASHBOARD } from 'src/routes/paths';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import { BOOKINGS, CHAT, FLOW, LEADS, LINKS, REVIEW } from 'src/theme/palette';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import SimplyFlowIcon from 'src/assets/Logo/SimplyFlowIcon';
import { useSelector } from 'src/redux/store';
import { selectPlatform } from 'src/redux/slices/userSlice';
import { Platforms } from 'src/redux/slices/platform';
import SimplyLinksIcon from 'src/assets/Logo/SimplyLinksIcon';
import SimplyBookingIcon from 'src/assets/Logo/SimplyBookingIcon';
import SimplyChatIcon from 'src/assets/Logo/SimplyChatIcon';
import { ExternalLink } from 'lucide-react';

// This is sample data.
export const data = (platform: Platforms, slug?: string) => ({
  navMain: [
    {
      title: 'SimplyReview',
      url: PATH_DASHBOARD.general.app,
      icon: <SimplyReviewIcon width={8} color={REVIEW.lighter} />, // SquareTerminal,
      isActive: platform === Platforms.SIMPLY_REVIEW,
      items: [
        {
          title: 'nav.dashboard',
          url: PATH_DASHBOARD.general.app,
        },
        {
          // icon: Send,
          title: 'nav.invites',
          url: PATH_DASHBOARD.general.invites,
          badge: <InvitePendingBadge />,
        },
        {
          title: 'nav.reviews',
          url: PATH_DASHBOARD.general.reviews,
        },
        {
          title: 'nav.integrations',
          url: PATH_DASHBOARD.general.integrations,
        },
        {
          title: 'nav.automations',
          url: PATH_DASHBOARD.general.reviewAutomations,
          badge: <MissingAutomationSetup />,
        },
        {
          title: 'nav.reviewPage',
          url: `/r/${slug}`,
          external: true,
        },
        {
          title: 'nav.companyPage',
          url: `/c/${slug}`,
          badge: <ExternalLink />,
          external: true,
        },
      ],
    },
    {
      title: 'SimplyFlow',
      url: '#',
      icon: (
        <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
          <SimplyFlowIcon width={10} color={FLOW.lighter} />
        </div>
      ),
      isActive: platform === Platforms.SIMPLY_FLOW,
      items: [
        {
          title: 'nav.dashboard',
          url: PATH_DASHBOARD.general.appFlow,
        },
        {
          title: 'nav.videoFlow',
          url: PATH_DASHBOARD.general.videoFlow,
        },
      ],
    },
    {
      title: 'SimplyLeads',
      url: '#',
      icon: (
        <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
          <SimplyLeadsIcon width={14} color={LEADS.light} />
        </div>
      ),
      isActive: platform === Platforms.SIMPLY_LEADS,
      items: [
        {
          title: 'nav.dashboard',
          url: PATH_DASHBOARD.general.appLeads,
        },
        {
          title: 'nav.leadsForm',
          url: PATH_DASHBOARD.general.leadsForm,
        },
        {
          title: 'nav.leadSubmissions',
          url: PATH_DASHBOARD.general.leadSubmissions,
        },
        {
          title: 'nav.leadCustomisePage',
          url: PATH_DASHBOARD.general.leadCustomisePage,
        },
        {
          title: 'nav.leadEmbedOnWebsite',
          url: PATH_DASHBOARD.general.leadEmbedOnWebsite,
        },
        {
          title: 'nav.leadsPage',
          url: `/l/${slug}`,
          badge: <ExternalLink />,
          external: true,
        },
      ],
    },
    {
      title: 'SimplyLinks',
      url: '#',
      icon: (
        <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
          <SimplyLinksIcon width={14} color={LINKS.light} />
        </div>
      ),
      isActive: platform === Platforms.SIMPLY_LINKS,
      items: [
        {
          title: 'nav.linksCustomisePage',
          url: PATH_DASHBOARD.general.linksCustomisePage,
        },
        {
          title: 'nav.linkPage',
          url: `/i/${slug}`,
          badge: <ExternalLink />,
          external: true,
        },
      ],
    },
    {
      title: 'SimplyBooking',
      url: '#',
      icon: (
        <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
          <SimplyBookingIcon width={14} color={BOOKINGS.light} />
        </div>
      ),
      isAdminOnly: true,
      isActive: platform === Platforms.SIMPLY_BOOKING,
      items: [
        { title: 'nav.dashboard', url: PATH_DASHBOARD.general.appBooking },

        {
          title: 'nav.bookingPages',
          url: PATH_DASHBOARD.general.bookingSchedules,
        },
        {
          title: 'nav.bookingList',
          url: PATH_DASHBOARD.general.bookingList,
        },
      ],
    },
    {
      title: 'SimplyChat',
      url: '#',
      icon: (
        <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
          <SimplyChatIcon width={14} color={CHAT.light} />
        </div>
      ),
      isAdminOnly: true,
      isActive: platform === Platforms.SIMPLY_CHAT,
      items: [{ title: 'nav.dashboard', url: PATH_DASHBOARD.general.chatConversations }],
    },
  ],
});

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const slug = useSelector((state) => state.publicConfig?.slug);
  const currentPlatform = useSelector(selectPlatform);
  const nav = data(currentPlatform, slug);

  return (
    <Sidebar className="z-20" collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={nav.navMain} />
      </SidebarContent>
      <SidebarFooter>
        <NavInfo />
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
