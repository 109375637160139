import React from 'react';
import { Avatar, Box, useTheme, Typography, Stack, Grid } from '@mui/material';
import Iconify from 'src/components/Iconify';
import BrandButton from 'src/components/buttons/BrandButton';
import { Intl, ReviewType } from '../../enum';
import VideoPlayer, { VideoPlayerPreview } from 'src/components/Review/VideoPlayer';
import { useTranslation } from 'react-i18next';
import CoverVideoOptimzing from 'src/components/Review/CoverVideoOptimzing';
import Label from 'src/components/Label';
import { initialQuestions } from '../simplyreview/reviewpage/usePageSettings';

export default function ReviewInfo({
  avatar = undefined,
  title,
  description,
  color,
  questions,
  setReviewType,
  onlyVideo,
  videoIntro,
  videoIntroPreview,
  videoProcessed,
  reviewerPageLanguage = Intl.EN,
}: {
  avatar?: string;
  title: string;
  description: string;
  color?: string;
  questions: any[];
  setReviewType: any;
  videoIntro?: string;
  videoIntroPreview?: string;
  videoProcessed?: boolean;
  onlyVideo: boolean;
  reviewerPageLanguage?: Intl;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <Typography variant="h4" align="center">
              {title || t('review.form.titlePlaceholder', { lng: reviewerPageLanguage })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            {videoIntroPreview || videoIntro ? (
              <div style={{ maxWidth: 400, margin: '0 auto' }}>
                {videoIntroPreview ? (
                  <VideoPlayerPreview video={videoIntroPreview} />
                ) : videoProcessed === false ? (
                  <CoverVideoOptimzing
                    text={t('videoPlaceholder.optimizing', { lng: reviewerPageLanguage })}
                  />
                ) : (
                  <VideoPlayer videoPlaybackId={videoIntro} />
                )}
              </div>
            ) : (
              <Avatar
                alt={'logo'}
                src={avatar}
                sx={{
                  // width: '100%',
                  // height: '100%',
                  width: 128,
                  height: 128,
                  margin: '0 auto',
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            {/* <Stack sx={{ height: '100%', justifyContent: 'center' }}> */}
            <Typography
              variant="body2"
              /* color={theme.palette.grey[500]} */ align="center"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {description ||
                t('review.form.descriptionPlaceholder', { lng: reviewerPageLanguage })}
            </Typography>
            {/* </Stack> */}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ py: 4 }}>
        <Stack mb={2}>
          <Typography variant="overline" color={theme.palette.grey[500]} textAlign="center">
            {t('review.form.questionsLabel', { lng: reviewerPageLanguage })}
          </Typography>
        </Stack>
        <Box sx={{ px: 3 }}>
          {(questions || initialQuestions)?.map((q, index) => (
            <Stack key={q.id}>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.grey[200]}`,
                  p: 1,
                  px: 2,
                  pl: 4,
                  borderRadius: 1,
                  position: 'relative',
                }}
              >
                <Stack
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: -16,
                    top: 0,
                    bottom: 0,
                    margin: 'auto',
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: '50%',
                    lineHeight: 0,
                    p: 0.5,
                    backgroundColor: theme.palette.common.white,
                    height: 28,
                    width: 28,
                    textAlign: 'center',
                  }}
                >
                  <Box sx={{ mt: 0.15, ml: -0.1 }}>{index + 1}</Box>
                </Stack>
                <Typography variant="body2">{q.content}</Typography>
              </Box>
              <Box sx={{ mb: 1 }} />
            </Stack>
          ))}
        </Box>
      </Box>

      <Stack>
        <BrandButton
          variant="contained"
          size="large"
          customColor={color}
          startIcon={<Iconify icon="carbon:video" />}
          onClick={() => setReviewType(ReviewType.VIDEO)}
        >
          {t('review.form.videoReviewBtn', { lng: reviewerPageLanguage })}
          <Box style={{ position: 'absolute', top: -12, fontSize: 12 }}>
            <Label
              sx={{
                backgroundColor: theme.palette.primary.lighter,
                color: theme.palette.primary.main,
                fontSize: 10,
                // fontWeight: 'light',
                textTransform: 'uppercase',
                cursor: 'pointer',
              }}
            >
              {t('review.form.videoReviewBtnLabel', { lng: reviewerPageLanguage })}
            </Label>
          </Box>
        </BrandButton>
        <Stack textAlign="center" mt={1}>
          <Typography fontSize={12} fontWeight={'bold'} color={theme.palette.grey[500]}>
            {t('review.form.videoReviewSubtitle1', { lng: reviewerPageLanguage })}
          </Typography>
          <Typography fontSize={12} color={theme.palette.grey[500]}>
            {t('review.form.videoReviewSubtitle2', { lng: reviewerPageLanguage })}
          </Typography>
        </Stack>
        {!onlyVideo && (
          <>
            <Box sx={{ mt: 2 }} />
            <BrandButton
              variant="text"
              size="large"
              customColor={color}
              startIcon={<Iconify icon="carbon:pen" />}
              onClick={() => setReviewType(ReviewType.TEXT)}
            >
              {t('review.form.textReviewBtn', { lng: reviewerPageLanguage })}
            </BrandButton>
          </>
        )}
      </Stack>
    </>
  );
}
