import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { InviteStatus, Subscription, RequestState } from '../../enum';
import keyBy from 'lodash.keyby';
import { Timestamp } from 'firebase/firestore';
import { selectCurrentWorkspaceId } from './userSlice';

export interface Member {
  uid: string;
  email: string;
  displayName: string;
  status: InviteStatus;
}

export interface Settings {
  autoApproveReviews?: boolean;
}

export interface WorkspaceReview {
  uid: string;
  id: string;
  companyName?: string;
  members: {
    [key: string]: Member;
  };
  subscription: Subscription;
  trialStart: Timestamp;
  smsLimit: number;
  settings?: Settings;
}

// Define a type for the slice state
interface WorkpsaceReviewState {
  state: RequestState;
  workspaceIds: string[];
  workspaceMap: { [key: string]: WorkspaceReview };
}

// Define the initial state using that type
const initialState: WorkpsaceReviewState = {
  state: RequestState.IDLE,
  workspaceIds: [],
  workspaceMap: {},
};

export const workspaceReviewsSlice = createSlice({
  name: 'workspaceReviews',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setWorkspaceReviews: (
      state,
      action: PayloadAction<{ workspaces: WorkspaceReview[]; current?: string | null }>
    ) => {
      const workspaceMap = keyBy(action.payload.workspaces, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        workspaceIds: action.payload.workspaces.map((workspace) => workspace.id),
        workspaceMap,
        state: RequestState.RESOLVED,
      };
    },
    setWorkspaceReviewsState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      state: action.payload,
    }),
  },
});

export const { setWorkspaceReviews, setWorkspaceReviewsState } = workspaceReviewsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentWorkspaceMembers = (state: RootState) => {
  const currentReviewWorkspace = selectCurrentWorkspaceId(state);
  if (!currentReviewWorkspace) return [];
  const currentWorkspace = state.workspaceReview.workspaceMap[currentReviewWorkspace];
  return (
    currentWorkspace?.members
      ? Object.keys(currentWorkspace?.members).map((key) => currentWorkspace?.members[key])
      : []
  ) as Member[];
};

export const selectWorkspaces = (state: RootState) =>
  Object.keys(state.workspaceReview.workspaceMap).map(
    (key) => state.workspaceReview.workspaceMap[key]
  );
/**
 * Everyone has premium for now but only on trial
 * @param state
 * @returns
 */
export const hasPremiumSelector = (state: RootState) => true;

export const hasSmsLimitExceeded = (state: RootState) =>
  // @ts-ignore
  state.workspaceReview?.currentWorkspace?.smsLimit <= 0;
// state.workspaceReview.currentWorkspace?.subscription === Subscription.PREMIUM;

export const isLoadingWorkspaceReview = (state: RootState) =>
  state.workspaceReview.state === RequestState.IDLE ||
  state.workspaceReview.state === RequestState.PENDING;

export default workspaceReviewsSlice.reducer;
