import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where, doc } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { Review } from '../redux/slices/reviewSlice';
import { SubTitle } from '../components/subtitles';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export interface SubTitleDoc {
  id: string;
  createTime: Date;
  timeframes: SubTitle[];
}

// if passed here it will load X users reviews
export default function useReviewTranscript(reviewId: string | null) {
  const [loading, setLoading] = useState<RequestState>(RequestState.IDLE);
  const [transcript, setTranscript] = useState<SubTitleDoc | null>(null);
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId && reviewId) {
      setLoading(RequestState.PENDING);
      const q = doc(
        DB,
        getCollection[Collection.USERS_REVIEW_TRANSCRIPT](workspaceId, reviewId),
        'transcript'
      );
      unsubscribe = onSnapshot(q, (doc) => {
        const transcript = {
          id: doc.id,
          ...doc.data(),
        } as SubTitleDoc;
        setTranscript(transcript);
        setLoading(RequestState.RESOLVED);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [reviewId, workspaceId]);
  return {
    isLoading: loading === RequestState.IDLE || loading === RequestState.PENDING,
    subtitles: transcript?.timeframes || [],
    transcriptId: transcript?.id,
  };
}
