import React from 'react';
import { Platforms } from 'src/redux/slices/platform';
import { useSelector } from 'src/redux/store';
import SimplyFlowLogo from './SimplyFlowLogo';
import SimplyReviewLogo from './SimplyReviewLogo';
import SimplyLeadsLogo from './SimplyLeadsLogo';
import { selectPlatform } from 'src/redux/slices/userSlice';
import SimplyLinksLogo from './SimplyLinksLogo';
import SimplyBookingLogo from './SimplyBookingLogo';

export default function Simply({ width = 84, color = '#333' }) {
  const platform = useSelector(selectPlatform);
  if (platform === Platforms.SIMPLY_FLOW) {
    return <SimplyFlowLogo width={width} color={color} />;
  }
  if (platform === Platforms.SIMPLY_REVIEW) {
    return <SimplyReviewLogo width={width} color={color} />;
  }
  if (platform === Platforms.SIMPLY_LEADS) {
    return <SimplyLeadsLogo width={width} color={color} />;
  }
  if (platform === Platforms.SIMPLY_LINKS) {
    return <SimplyLinksLogo width={width} color={color} />;
  }
  if (platform === Platforms.SIMPLY_BOOKING) {
    return <SimplyBookingLogo width={width} color={color} />;
  }
  return <SimplyReviewLogo width={width} color={color} />;
}
