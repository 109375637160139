import { useState, useRef, ChangeEvent } from 'react';
// @mui
// import { MobileDateRangePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
} from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
// import { DisplayTime, useDatePicker } from '../../sections/@dashboard/kanban/KanbanTaskAdd';
import IconButtonAnimate from '../../../components/animate/IconButtonAnimate';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import CopyInviteLinkSmall from '../../../components/CopyInviteLinkSmall';
import useInvite from '../../../hooks/useInvite';
// import { Invite } from 'src/redux/slices/invites';
import { sentenceCase } from 'change-case';
import useInviteLink from '../../../hooks/useInviteLink';
import { useTranslation } from 'react-i18next';
import UpgradeUser from '../UserAdminActions/UpgradeUser';
import { UserDocWithId } from '../../../redux/slices/usersSlice';
import DowngradeUser from '../UserAdminActions/DowngradeUser';
import LoginAsUser from '../UserAdminActions/LoginInAsUser';
import Content from './Content';

//

// ----------------------------------------------------------------------

// const PRIORITIZES = ['low', 'medium', 'hight'];

// ----------------------------------------------------------------------

type Props = {
  userId: string | null;
  user?: UserDocWithId;
  onClose: VoidFunction;
};

export default function UserDrawer({ userId, user, onClose }: Props) {
  const isDesktop = useResponsive('up', 'sm');
  const { t } = useTranslation();
  // const invite = useInvite(inviteId);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [taskCompleted, setTaskCompleted] = useState(true);

  const handleToggleCompleted = () => {
    setTaskCompleted((prev) => !prev);
  };

  const renderContent = (userId: string, user: UserDocWithId) => (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        {!isDesktop && (
          <Tooltip title="Back">
              <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
                <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
        )}

        <Button
          size="small"
          variant="outlined"
          disabled
          color={taskCompleted ? 'primary' : 'inherit'}
          startIcon={
            taskCompleted && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />
          }
          onClick={handleToggleCompleted}
        >
          {sentenceCase('status')}
        </Button>

        <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
          {/* <Tooltip title="Like this">
              <IconButtonAnimate size="small">
                <Iconify icon={'ic:round-thumb-up'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

            <>
              <Tooltip title="Attachment">
                <IconButtonAnimate size="small" onClick={handleAttach}>
                  <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
              <input ref={fileInputRef} type="file" style={{ display: 'none' }} />
            </> */}

          <Tooltip title="Slet invitation">
            <IconButtonAnimate onClick={() => {}} size="small">
              <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>

          {/* <Tooltip title="More actions">
              <IconButtonAnimate size="small">
                <Iconify icon={'eva:more-horizontal-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip> */}
        </Stack>
      </Stack>
      <Content userId={userId} />
    </>
  );

  return (
    <Drawer
        open={Boolean(userId)}
        onClose={onClose}
        anchor="right"
        PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}
      >
        {Boolean(userId) && renderContent(userId as string, user as UserDocWithId)}
      </Drawer>
  );
}
