import {
  Box,
  Card,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  PaperProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { Transition } from 'src/sections/@dashboard/user/initial/InitialSettings';
import { Button } from '@mui/material';
import { UnverifiedReview } from './useUnverifiedReviews';
import { Stack } from '@mui/material';
import { Review } from 'src/redux/slices/reviewSlice';
import DisplayVideo from '../simplyreview/reviews/DisplayVideo';
import { Collection, ReviewType } from 'src/enum';
import HoverCard from 'src/components/simply/Cards/HoverCard';
import { ReviewerDetails } from 'src/sections/@dashboard/user/profile/ProfilePostCard';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { DB } from 'src/contexts/FirebaseContext';
import { collection, doc, getDoc, getDocs, limit, query, setDoc, where } from 'firebase/firestore';
import { getCollection } from 'src/api';
import { PublicConfig } from 'src/redux/slices/publicConfig';
import Draggable from 'react-draggable';
import useRecentDistanceReviews from './useRecentDistanceReviews';
import { format } from 'date-fns';
import { groupBy } from 'lodash';
import { UserDocWithId } from 'src/redux/slices/usersSlice';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export default function UnverifiedReviewsList({
  reviews,
  users,
}: {
  reviews: UnverifiedReview[];
  users: UserDocWithId[];
}) {
  const [modal, setModal] = useState(false);
  const handleCloseModal = () => setModal(false);

  const groupedByWorkspaceId = groupBy(reviews, 'workspaceId');

  // write a function that replaces the workspaceId in groupedByWorkspaceId with users name and keep the orignal grouped object structure
  const groupedByUser = Object.keys(groupedByWorkspaceId).reduce((acc, key) => {
    const user = users.find((u) => u.id === key);
    if (user) {
      return { ...acc, [user.displayName]: groupedByWorkspaceId[key] };
    }
    return acc;
  }, {});

  console.log({ groupedByUser, users });

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          setModal(true);
        }}
        color="warning"
        // endIcon={<Iconify icon="tabler:external-link" />}
      >
        {reviews.length} unverfied review(s)
      </Button>
      <Dialog
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleCloseModal}
        aria-describedby="handle-reviews-list"
        maxWidth="lg"
      >
        <HandleReviews reviewsGroupedByUser={groupedByUser} handleClose={handleCloseModal} />
      </Dialog>
    </>
  );
}

export function HandleReviews({
  reviewsGroupedByUser,
  handleClose,
}: {
  reviewsGroupedByUser: { [user: string]: UnverifiedReview[] };
  handleClose: () => void;
}) {
  const theme = useTheme();
  const [modal, setModal] = useState<UnverifiedReview | null>(null);
  const handleCloseModal = () => setModal(null);
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {'Unverified reviews (click review to compare with other incoming)'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }} />
        <Stack sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            {Object.entries(reviewsGroupedByUser).map(([user, reviews]) => (
              <>
                <Grid xs={12}>
                  <Stack sx={{ p: 2, backgroundColor: theme.palette.primary.lighter }}>
                    <Typography variant="h4">{user}</Typography>
                  </Stack>
                </Grid>
                {reviews.map((r) => (
                  <Grid xs={3}>
                    {r.type === ReviewType.VIDEO ? (
                      <Card sx={{ p: 1 }} onClick={() => setModal(r)}>
                        <Box>{format(r.createTime.toDate(), 'dd-MM-yyyy')}</Box>
                        <Box sx={{ overflow: 'hidden', objectFit: 'cover' }}>
                          <DisplayVideo
                            rating={r?.rating}
                            videoProcessed={r?.videoProcessed}
                            videoPlaybackId={r?.videoPlaybackId}
                            thumbFrame={r?.thumbFrame}
                            reviewerName={r?.reviewer?.name}
                          />
                        </Box>
                        <ReviewActions
                          workspaceId={r.workspaceId}
                          reviewId={r.id}
                          inviteId={r?.inviteId}
                        />
                      </Card>
                    ) : (
                      <HoverCard sx={{ p: 2, pb: 1, height: '100%' }} onClick={() => setModal(r)}>
                        <Stack justifyContent="space-between">
                          <Stack>
                            {r.createTime && (
                              <Typography variant="subtitle2">
                                {format(r.createTime.toDate(), 'dd-MM-yyyy')}
                              </Typography>
                            )}
                            {r.title && <Typography variant="h5">{r.title}</Typography>}
                            <Box sx={{ overflowY: 'scroll', maxHeight: 250 }}>
                              {r.description && <Typography>{r.description}</Typography>}
                            </Box>
                          </Stack>
                          <ReviewerDetails
                            name={r?.reviewer?.name as string}
                            rating={r.rating}
                            source={r.source}
                          />
                        </Stack>
                        <ReviewActions
                          workspaceId={r.workspaceId}
                          reviewId={r.id}
                          inviteId={r?.inviteId}
                        />
                      </HoverCard>
                    )}
                  </Grid>
                ))}
              </>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
      <Dialog
        open={Boolean(modal)}
        TransitionComponent={Transition}
        keepMounted
        PaperComponent={PaperComponent}
        fullWidth
        onClose={handleCloseModal}
        aria-describedby="compare-reviews-list"
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Showing reviews within +4 and -4 days of the review (Except the review you clicked)
        </DialogTitle>
        <DialogContent>
          {modal && (
            <CompareRecentReviews
              workspaceId={modal.workspaceId}
              createTime={modal.createTime.toDate() as Date}
              reviewId={modal.id}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function getReviewState(verified: boolean | null): { text: string; color: string } {
  if (verified === null) return { text: 'Pending', color: '255, 191, 0' };
  if (verified) return { text: 'Verified', color: '0,128,0' };
  return { text: 'Rejected', color: '255,128,0' };
}

function CompareRecentReviews({
  workspaceId,
  createTime,
  reviewId,
}: {
  workspaceId: string;
  createTime: Date;
  reviewId: string;
}) {
  const theme = useTheme();
  const compareReviews = useRecentDistanceReviews({ workspaceId, createTime });
  const currentReview = compareReviews.list.find((r) => r.id === reviewId);
  if (!currentReview) return null;
  console.log({ compareReviews });

  return (
    <Stack sx={{ width: '100%' }}>
      <Grid container spacing={0}>
        {compareReviews.list?.length === 1 && (
          <Typography variant="body1">
            Doesn't seem like there is duplicates of this review within the last 4 days, you should
            be able to just verify it
          </Typography>
        )}
        {compareReviews.list
          .filter((r) => r.id !== reviewId)
          .map((r) => {
            const matches = match(currentReview.reviewer.name as string, r.reviewer.name as string);
            const parts = parse(r.reviewer.name as string, matches);
            console.log({ parts });

            return (
              <Grid xs={6}>
                {r.type === ReviewType.VIDEO ? (
                  <Card sx={{ p: 1 }}>
                    <ReviewStateBadge verified={r.verified} />
                    <Box>{format(r.createTime, 'dd-MM-yyyy')}</Box>
                    <Box sx={{ overflow: 'hidden', objectFit: 'cover' }}>
                      <DisplayVideo
                        rating={r?.rating}
                        videoProcessed={r?.videoProcessed}
                        videoPlaybackId={r?.videoPlaybackId}
                        thumbFrame={r?.thumbFrame}
                        reviewerName={r?.reviewer?.name}
                      />
                    </Box>
                  </Card>
                ) : (
                  <HoverCard sx={{ p: 2, pb: 1, height: '100%' }}>
                    <ReviewStateBadge verified={r.verified} />
                    <Stack justifyContent="space-between">
                      <Stack>
                        {r.createTime && (
                          <Typography variant="subtitle2">
                            {format(r.createTime, 'dd-MM-yyyy')}
                          </Typography>
                        )}
                        {r.title && <Typography variant="h5">{r.title}</Typography>}
                        <Box sx={{ overflowY: 'scroll', maxHeight: 250 }}>
                          {r.description && <Typography>{r.description}</Typography>}
                        </Box>
                      </Stack>
                      <Tooltip
                        title={
                          'If the name is highlighted it matches the name of the review you clicked'
                        }
                      >
                        <Stack>
                          <ReviewerDetails
                            name={parts.map((part, index) => (
                              <Typography
                                key={index}
                                component="span"
                                variant="subtitle2"
                                style={{
                                  backgroundColor: part.highlight
                                    ? theme.palette.warning.main
                                    : 'textPrimary',
                                }}
                              >
                                {part.text}
                              </Typography>
                            ))}
                            rating={r.rating}
                            source={r.source}
                          />
                        </Stack>
                      </Tooltip>
                    </Stack>
                  </HoverCard>
                )}
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
}

function ReviewStateBadge({ verified }: { verified: boolean | null }) {
  const state = getReviewState(verified);
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        right: 0,
        top: 0,
        borderBottomLeftRadius: 8,
        width: 120,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: `rgba(${state.color},0.8)`,
        fontSize: 20,
        color: 'white',
        fontWeight: 'bold',
      }}
    >
      {state.text}
    </Box>
  );
}

function ReviewActions({
  workspaceId,
  reviewId,
  inviteId,
}: {
  workspaceId: string;
  reviewId: string;
  inviteId: string | null;
}) {
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <CardActions sx={{ justifyContent: 'space-between' }}>
      <LoadingButton
        onClick={async () => {
          try {
            if (!workspaceId) return alert('Something is not working!');
            setloading(true);

            const productRef = doc(
              DB,
              getCollection[Collection.USERS_REVIEWS](workspaceId),
              reviewId
            );

            await setDoc(productRef, { verified: false }, { merge: true });

            enqueueSnackbar('Review rejected', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });

            console.error(error);
          } finally {
            setloading(false);
          }
        }}
        variant="contained"
        color="error"
        size="small"
      >
        {'Afvis'}
      </LoadingButton>
      <Button
        onClick={async () => {
          try {
            const q = query(
              collection(DB, getCollection[Collection.PUBLIC]()),
              where('userId', '==', workspaceId),
              limit(1)
            );
            const docsSnap = await getDocs(q);
            if (docsSnap.empty) return alert('Something is not working!');
            const config = docsSnap.docs[0]?.data() as PublicConfig;
            if (inviteId) {
              return window.open(
                `https://app.simplyreview.com/r/${config.slug}/${inviteId}?r=${reviewId}`,
                '_blank'
              );
            }
            return window.open(
              `https://app.simplyreview.com/r/${config.slug}?r=${reviewId}`,
              '_blank'
            );
          } catch (error) {
            console.error(error);
            alert(`Something is not working! - ${JSON.stringify(error)}`);
          }
        }}
        variant="contained"
        size="small"
      >
        {'Godkend review'}
      </Button>
    </CardActions>
  );
}
