import React from 'react';
import CustomiseLightAndDark from './CustomiseLightAndDark';
import { MiniReviewCount } from '@simplylabs_/shared';
import { Integration } from 'src/redux/slices/integrationSlice';
import { doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { useSnackbar } from 'notistack';
import { WidgetTypeConfig } from 'src/types';
import { Collection, WidgetType } from 'src/enum';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import CustomiseControls from './CustomiseControls';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function CustomiseControlsContainer({
  currentWidget,
}: {
  currentWidget: Integration;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const updateWidgetConfig = async (config: Partial<WidgetTypeConfig<WidgetType>>) => {
    try {
      if (!workspaceId) {
        throw new Error('Workspace ID not found');
      }
      await setDoc(
        doc(DB, getCollection[Collection.USERS_INTEGRATIONS](workspaceId), currentWidget.id),
        { config },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };

  if (currentWidget?.type === WidgetType.REVIEW_CARDS) {
    const reviewCardConfig = currentWidget.config as WidgetTypeConfig<WidgetType.REVIEW_CARDS>;
    // @ts-ignore
    const hasControls = Boolean(reviewCardConfig?.hasControls);
    return (
      <CustomiseControls
        hasControls={hasControls}
        toggleControls={() =>
          updateWidgetConfig({
            hasControls: !hasControls,
          } as WidgetTypeConfig<WidgetType.REVIEW_CARDS>)
        }
      />
    );
  }
  if (currentWidget?.type === WidgetType.VIDEO_CARDS) {
    const reviewCardConfig = currentWidget.config as WidgetTypeConfig<WidgetType.VIDEO_CARDS>;
    // @ts-ignore
    const hasControls = Boolean(reviewCardConfig?.hasControls);
    return (
      <CustomiseControls
        hasControls={hasControls}
        toggleControls={() =>
          updateWidgetConfig({
            hasControls: !hasControls,
          } as WidgetTypeConfig<WidgetType.REVIEW_CARDS>)
        }
      />
    );
  }
  if (currentWidget?.type === WidgetType.TEXT_VIDEO_CARDS) {
    const reviewCardConfig = currentWidget.config as WidgetTypeConfig<WidgetType.TEXT_VIDEO_CARDS>;
    // @ts-ignore
    const hasControls = Boolean(reviewCardConfig?.hasControls);
    return (
      <CustomiseControls
        hasControls={hasControls}
        toggleControls={() =>
          updateWidgetConfig({
            hasControls: !hasControls,
          } as WidgetTypeConfig<WidgetType.REVIEW_CARDS>)
        }
      />
    );
  }
  return null;
}
