import { useSelector } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import {
  selectHasCurrentPlatformLoaded,
  selectIsUserWorkspaceTeamMemberByPlatform,
} from 'src/redux/selectors/workspaces';
import { selectPlatformDisplayName } from 'src/redux/selectors/platform';
import { Lock } from 'lucide-react';
import { selectPublicConfigHasLoaded } from 'src/redux/slices/publicConfig';
import { RequestState } from 'src/enum';

export default function ModalAccessContainer() {
  const hasLoadedConfig = useSelector(selectPublicConfigHasLoaded);
  const hasUserLoaded = useSelector((state) => state.user.state === RequestState.RESOLVED);
  const hasPlatformLoaded = useSelector(selectHasCurrentPlatformLoaded);
  
  if (!hasLoadedConfig || !hasUserLoaded || !hasPlatformLoaded) {
    return null;
  }
  return <ModalPlatformAccess />;
}

function ModalPlatformAccess() {
  const { t } = useTranslation();
  const isTeamMember = useSelector(selectIsUserWorkspaceTeamMemberByPlatform);
  const platform = useSelector(selectPlatformDisplayName);
  const isOwner = useSelector((state) => state.user.id === state.publicConfig?.userId);
  const workspaceName = useSelector((state) => state.publicConfig.companyName);

  if (isOwner || isTeamMember) {
    return null;
  }

  return (
    <div className="fixed flex flex-col p-6 pt-12 justify-center items-center h-[calc(100%)] w-[calc(100%)] mt-0 pl-[--sidebar-width] z-10 backdrop-blur-lg gap-4">
      <div>
        {/* <Logo /> */}
        <Lock size={24} />
      </div>

      <div>
        <h2 className="text-2xl font-bold">{t('access.team.notmember.title', { platform })}</h2>
      </div>
      <div>
        <p>{t('access.team.notmember.description', { workspace: workspaceName })}</p>
      </div>
    </div>
  );
}
