import { cn } from 'src/utils/utils';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useAuth from 'src/hooks/useAuth';
import { toast } from 'sonner';
import SignInGoogle from '../SignInGoogle';
import { Link } from 'react-router-dom';
import { PATH_AUTH } from 'src/routes/paths';
import { Terms } from 'src/config';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';

const formSchema = z.object({
  firstName: z.string().min(2, { message: 'register.validation.firstnameReq' }),
  lastName: z.string().min(2, { message: 'register.validation.lastnameReq' }),
  email: z.string().email(),
  password: z
    .string()
    .min(8, {
      message: 'register.validation.passwordReq',
    })
    .max(50),
});

export function SignupForm({ className, ...props }: React.ComponentPropsWithoutRef<'form'>) {
  const { t } = useTranslation();
  const { register } = useAuth();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
  });

  const {
    reset,
    formState: { isSubmitting },
    setError,
  } = form;

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    console.log(values);
    try {
      const error = await register({
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
      });
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('flex flex-col gap-6', className)}
        {...props}
      >
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-bold">{t('register.headline')}</h1>
          <p className="text-balance text-sm text-muted-foreground">{t('register.subheadline')}</p>
        </div>
        <div className="grid gap-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('general.form.firstname')}</FormLabel>
                    <FormControl>
                      <Input placeholder="Jane" {...field} />
                    </FormControl>
                    {/* <FormDescription>This is your public display name.</FormDescription> */}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('general.form.lastname')}</FormLabel>
                    <FormControl>
                      <Input placeholder="Doe" {...field} />
                    </FormControl>
                    {/* <FormDescription>This is your public display name.</FormDescription> */}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('general.form.email')}</FormLabel>
                  <FormControl>
                    <Input placeholder="m@example.com" {...field} />
                  </FormControl>
                  {/* <FormDescription>This is your public display name.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center">
                    <FormLabel>{t('general.form.password')}</FormLabel>
                  </div>
                  <FormControl>
                    <Input type="password" placeholder="*******" {...field} />
                  </FormControl>
                  {/* <FormDescription>This is your public display name.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button type="submit" className="w-full" loading={isSubmitting} disabled={isSubmitting}>
            {t('register.button')}
          </Button>
          <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
            <span className="relative z-10 bg-background px-2 text-muted-foreground">
              Or continue with
            </span>
          </div>
          <SignInGoogle />
        </div>
        <div className="text-center text-sm">
          {t('register.gotAnAccount')}{' '}
          <Link to={PATH_AUTH.login} className="underline underline-offset-4">
            {t('login.button')}
          </Link>
        </div>
        <div className="text-center text-sm">
          {t('register.terms')}&nbsp;
          <a
            className="underline underline-offset-4"
            href={Terms.TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          {t('general.and')}{' '}
          <a
            className="underline underline-offset-4"
            rel="noreferrer"
            href={Terms.PRIVACY_POLICY}
            target="_blank"
          >
            Privacy Policy
          </a>
          .
        </div>
      </form>
    </Form>
  );
}
