import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore';
import { Collection, InviteStatus, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { setInviteLoadingState } from '../redux/slices/invites';
import { FirestoreTimestamp, convertFStoDate } from '../utils/formatTime';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export interface InviteResendReminder {
  id: string;
  countryCode: string;
  createTime: Date;

  email: string;

  emailReminder: boolean;
  linkId: string;

  name: string;

  phone: string | null;

  shortLink: string;
  smsReminder: boolean;
  status: InviteStatus;
}

// if passed here it will load X users reviews
export default function useInviteResendReminders(inviteId: string | null) {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentWorkspaceId);
  const [requestState, setRequestState] = useState(RequestState.IDLE);
  const [reminders, setReminders] = useState<InviteResendReminder[]>([]);
  useEffect(() => {
    let unsubscribe: () => void;

    if (userId && inviteId) {
      setRequestState(RequestState.PENDING);
      // dispatch(setInviteLoadingState(RequestState.PENDING));
      const q = query(
        collection(DB, getCollection[Collection.REVIEW_INVITES_RESEND_REMINDERS](userId, inviteId)),
        where('status', '==', InviteStatus.PENDING),
        orderBy('createTime', 'desc'),
        limit(1)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const activity = querySnapshot.docs.map((doc) => {
            const data = doc.data() as Partial<InviteResendReminder>;
            return {
              ...data,
              id: doc.id,
              // @ts-ignore
              createTime: convertFStoDate(data.createTime),
            } as InviteResendReminder;
          });

          setReminders(activity);
          setRequestState(RequestState.RESOLVED);
        },
        (error) => {
          setRequestState(RequestState.REJECTED);
          console.error(error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId, inviteId]);

  return { requestState, reminders };
}
