import { IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { ModalType, setModal } from '../../../redux/slices/modal';
import { useSelector } from '../../../redux/store';
import { Member } from '../../../redux/slices/workspaceReviewsSlice';
import { selectCurrentWorkspaceId, selectIsSuperOwner } from '../../../redux/slices/userSlice';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { TeamInvite } from '../../../redux/slices/teamInviteSlice';
import { selectHasCurrentPlatformPremium } from '../../../redux/selectors/workspaces';
import { useWorkspaceSettings } from 'src/components/workspaceSettings/context';
import { TeamAvatarGroup } from 'src/components/avatar/TeamGroup';
import { TeamAvatarGroupPending } from 'src/components/avatar/TeamGroupPending';
import AddTeam from 'src/components/avatar/AddTeam';

export default function Team({
  pendingInvites,
  members,
}: {
  pendingInvites: TeamInvite[];
  members: Member[];
}) {
  const { isOpen, openDialog } = useWorkspaceSettings();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const hasPremium = useSelector(selectHasCurrentPlatformPremium);
  const isSuperOwner = useSelector(selectIsSuperOwner);
  const userId = useSelector((state) => state.user.id);
  const currentWorkspaceId = useSelector(selectCurrentWorkspaceId);
  const handleInviteVoid = (inviteId: string) => {
    if (isSuperOwner) {
      return dispatch(setModal({ type: ModalType.REMOVE_MEMBER, context: { inviteId } }));
    }
    return enqueueSnackbar(t('team.invites.guard.owner'), { variant: 'warning' });
  };

  const openTeamSettings = () => {
    if (!currentWorkspaceId) return;
    openDialog();
  };

  
  return (
    <div className="flex -space-x-2">
      <TeamAvatarGroupPending users={pendingInvites} onClick={openTeamSettings} max={5} />
      <TeamAvatarGroup users={members} onClick={openTeamSettings} max={5} />
      <AddTeam onClick={openTeamSettings} />
    </div>
  );
}

const AddIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  border: `1px solid transparent`,
  backgroundColor: theme.palette.primary.lighter,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));
