'use client';

import * as React from 'react';
import {
  AudioWaveform,
  BookOpen,
  Bot,
  Cog,
  Command,
  Frame,
  GalleryVerticalEnd,
  Map,
  PieChart,
  ReceiptText,
  Settings2,
  SquareTerminal,
  Users,
} from 'lucide-react';

import { NavMain } from 'src/components/workspaceSettings/sidebar/nav-main';
import { NavProjects } from 'src/components/workspaceSettings/sidebar/nav-projects';
import { NavUser } from 'src/components/workspaceSettings/sidebar/nav-user';
import { TeamSwitcher } from 'src/components/workspaceSettings/sidebar/team-switcher';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from 'src/components/ui/sidebar';

// This is sample data.
const data = {
  projects: [
    // {
    //   name: 'Billing',
    //   url: '#',
    //   icon: ReceiptText,
    // },
    {
      name: 'Team members',
      url: '#',
      icon: Users,
    },
    // {
    //   name: 'Settings',
    //   url: '#',
    //   icon: Cog,
    // },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar
      style={{ borderTopLeftRadius: 8 }}
      className="h-full w-60 rounded-tl-lg"
      collapsible="icon"
      {...props}
    >
      <SidebarHeader>
        <TeamSwitcher />
      </SidebarHeader>
      <SidebarContent>
        {/* <NavMain items={data.navMain} /> */}
        <NavProjects projects={data.projects} />
      </SidebarContent>
      {/* <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter> */}
      {/* <SidebarRail /> */}
    </Sidebar>
  );
}
