// @mui
import { Grid, Typography, Box, Stack, Tabs, Tab, Fade, Card, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '../../../redux/store';
import { getWidgetCode } from '../../../utils/getWidgetCode';
import { useTranslation } from 'react-i18next';
import textVideoWidgetImage from '../../../assets/widget_onboarding/text_video_review.png';
import videoWidgetImage from '../../../assets/widget_onboarding/video_review.png';
import WidgetActivation from './components/WidgetActivation';
import SendInstructionCard from './components/SendInstructionCard';
import VideoInstructionCard from './components/VideoInstructionCard';
import WidgetCodeBlock from './components/WidgetCodeBlock';
import WidgetPreviewBlock, { ImagePlaceholder } from './components/WidgetPreviewBlock';
import { HEADER } from '../../../config';
import { Collection, ReviewType, WidgetType } from '../../../enum';
import { selectCurrentPlatformIntegrationsMap } from '../../../redux/selectors/integrations';
// import TextCardCarousel from '@simplytech/simply.widgets.review.text-cards-carousel';
import FloatingVideo from '@simplytech/simply.widgets.video.floating';
import { Position } from '@simplytech/simply.widgets.video.floating';
import { WidgetTypeConfig } from '../../../types';
import LeadFormPreview from 'src/pages/simplyleads/leadEmbedOnWebsite/Preview';
import { useState } from 'react';
import {
  MiniReviewCount,
  TextCardsCarouselWidget,
  TextVideoCardsCarouselWidget,
  VideoCardsCarouselWidget,
} from '@simplylabs_/shared';
import { useSnackbar } from 'notistack';
import CustomiseDarkLightContainer from '../CustomiseDarkLightContainer';
import CustomiseControlsContainer from '../CustomiseControlsContainer';
import InfoTooltip from 'src/components/InfoTooltip';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export function getPreviewWidget(type: WidgetType) {
  switch (type) {
    case WidgetType.TEXT_VIDEO_CARDS:
      return '';

    case WidgetType.VIDEO_CARDS:
      return '';

    case WidgetType.MICRO_STARS:
      return ''; // microWidgetImage;

    case WidgetType.REVIEW_CARDS:
      return ''; // textWidgetImage;

    default:
      return '';
  }
}
export function getPreviewWidgetComponent({
  type,
  config,
}: {
  type: WidgetType;
  config?: WidgetTypeConfig<WidgetType>;
}) {
  switch (type) {
    case WidgetType.TEXT_VIDEO_CARDS:
      return (
        <div
          style={{
            position: 'absolute',
            width: 1060,
            transform: 'scale(0.5)',
            left: -265,
            height: '90%',
            paddingLeft: 40,
          }}
        >
          <Box
            sx={{
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 1px rgba(0, 0, 0, 0.05)',
              borderRadius: 1,
              p: 4,
              ml: '2%',
              width: '90%',
              // @ts-ignore
              backgroundColor: config?.headerTextColor === '#ffffff' ? 'black' : 'white',
            }}
          >
            <TextVideoCardsCarouselWidget
              token={'test-token'}
              // @ts-ignore
              reviews={dummyTextVideoReviews}
              reviewCount={100}
              rating={4.7}
              slug={'simply-review'}
              // @ts-ignore
              hasControls={config?.hasControls}
              // @ts-ignore
              headerTextColor={config?.headerTextColor}
              // @ts-ignore
              alignment={config?.headerAlignment}
              // @ts-ignore
              controlsBackgroundColor={config?.textCardBackgroundColor}
            />
          </Box>
        </div>
      );

    case WidgetType.VIDEO_CARDS:
      return (
        <div
          style={{
            position: 'absolute',
            width: 1060,
            transform: 'scale(0.5)',
            left: -265,
            height: '90%',
            paddingLeft: 40,
          }}
        >
          <Box
            sx={{
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 1px rgba(0, 0, 0, 0.05)',
              borderRadius: 1,
              p: 4,
              ml: '2%',
              width: '90%',
              // @ts-ignore
              backgroundColor: config?.headerTextColor === '#ffffff' ? 'black' : 'white',
            }}
          >
            <VideoCardsCarouselWidget
              token={'test-token'}
              // @ts-ignore
              reviews={dummyVideoReviews}
              reviewCount={100}
              rating={4.7}
              slug={'simply-review'}
              config={config as WidgetTypeConfig<WidgetType.VIDEO_CARDS>}
            />
          </Box>
        </div>
      );

    case WidgetType.MICRO_STARS:
      return (
        <div
          style={{
            position: 'absolute',
            width: 1060,
            transform: 'scale(0.7)',
            left: -265,
            height: '220%',
            paddingLeft: 40,
          }}
        >
          <Box
            sx={{
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 1px rgba(0, 0, 0, 0.05)',
              borderRadius: 1,
              p: 4,
              ml: '18%',
              width: '60%',
              // @ts-ignore
              backgroundColor: config?.textColor === '#ffffff' ? 'black' : 'white',
            }}
          >
            <MiniReviewCount
              // TODO: add micro stars config
              // @ts-ignore
              textColor={config?.textColor || '#000000'}
              reviewCount={100}
              rating={4.5}
              slug="simply-review"
            />
          </Box>
        </div>
      );

    case WidgetType.REVIEW_CARDS:
      return (
        <div
          style={{
            position: 'absolute',
            width: 1060,
            transform: 'scale(0.5)',
            left: -265,
            height: '130%',
          }}
        >
          <Box
            sx={{
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 1px rgba(0, 0, 0, 0.05)',
              borderRadius: 1,
              p: 4,
              // ml: '18%',
              // width: '60%',
              // @ts-ignore
              backgroundColor: config?.headerTextColor === '#ffffff' ? 'black' : 'white',
            }}
          >
            <TextCardsCarouselWidget
              config={{
                // @ts-ignore
                headerTextColor: config?.headerTextColor,
                // @ts-ignore
                hasControls: config?.hasControls,
              }}
              reviewCount={100}
              rating={4.5}
              slug="simply-review"
              reviews={dummyReviews}
            />
          </Box>
        </div>
      );
    case WidgetType.FLOATING_INTRO_VIDEO:
      return (
        <div
          style={{
            position: 'absolute',
            // transform: 'scale(0.5)',
            right: 76,
            bottom: 10,
          }}
        >
          <FloatingVideo
            videoPlaybackId={
              (config as WidgetTypeConfig<WidgetType.FLOATING_INTRO_VIDEO>)?.videoPlaybackId ||
              'y2UyjQh6jJg4ypP65ZzxkYg659f2gGiSgPzjO8smHXU'
            }
          />
        </div>
      );
    default:
      return '';
  }
}

export default function Step2({ currentWidgetId, widgetType, isSetup }: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[currentWidgetId];
  const isActive = currentWidget?.state === 'ACTIVE';
  const [currentTab, setCurrentTab] = useState(0);
  const code = getWidgetCode(widgetType, {
    workspaceId,
    widgetId: currentWidgetId,
  });

  const topOffset = HEADER.DASHBOARD_DESKTOP_HEIGHT + 20;
  const image = getPreviewWidget(widgetType);
  const widgetComponent = getPreviewWidgetComponent({
    type: widgetType,
    config: currentWidget?.config,
  });

  const hasIncludeCustomisation = [
    WidgetType.REVIEW_CARDS,
    WidgetType.VIDEO_CARDS,
    WidgetType.TEXT_VIDEO_CARDS,
  ].includes(widgetType);
  const isDarkMode =
    // @ts-ignore
    currentWidget?.config?.headerTextColor === '#ffffff' ||
    // @ts-ignore
    currentWidget?.config?.textColor === '#ffffff';

  console.log(currentWidget?.config, isDarkMode);

  const isVideoReady = currentWidget?.videoProcessed === true;
  const videoConfig = currentWidget?.config as WidgetTypeConfig<WidgetType.FLOATING_INTRO_VIDEO>;

  return (
    <>
      <Grid item xs={12} sm={12} md={7}>
        {/* <Box sx={{ py: 2, pr: 4 }}>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h5">{t('widgets.setup.headline')}</Typography>
          </Box>
        </Box> */}
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          <Tab
            disableRipple
            key={0}
            label={t('widgets.setup.tab.code')}
            // icon={tab.icon}
            value={0}
          />
          {widgetType !== WidgetType.LEAD_FORM && (
            <Tab
              disableRipple
              key={1}
              label={t('widgets.setup.tab.customise')}
              // icon={tab.icon}
              value={1}
            />
          )}
        </Tabs>
        <Fade key="slide-widget-code" in={currentTab === 0}>
          <Stack display={currentTab === 0 ? 'flex' : 'none'} mt={3} spacing={0}>
            <Box sx={{ py: 2, pr: 4 }}>
              <WidgetCodeBlock code={code as string} />
            </Box>
            <Box sx={{ py: 2, pr: 4 }}>
              <VideoInstructionCard tutorialVideo="https://storage.googleapis.com/simply-review-b6fb2.appspot.com/tutorials/setup-video-widget-short.mov" />
            </Box>
            <Box sx={{ pb: 4, pr: 4 }}>
              <SendInstructionCard widgetId={currentWidgetId} />
            </Box>
            {widgetType !== WidgetType.LEAD_FORM && (
              <Box sx={{ py: 2, pr: 4 }}>
                <WidgetActivation
                  isActive={isActive}
                  url={`${currentWidget?.metadata?.hostname}${currentWidget?.metadata?.pathname}`}
                />
              </Box>
            )}
          </Stack>
        </Fade>
        <Fade key="slide-widget-customise" in={currentTab === 1}>
          <Stack display={currentTab === 1 ? 'flex' : 'none'} mt={3} spacing={0}>
            {/* <Box sx={{ py: 2, pr: 4 }}> */}
            <Typography variant="h5">{t('widgets.setup.customise.headline')}</Typography>
            <CustomiseDarkLightContainer currentWidget={currentWidget} />
            {hasIncludeCustomisation && (
              <Stack pt={8}>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
                  pb={2}
                >
                  <Typography variant="h6">{t('widgets.setup.customise.include')}</Typography>
                  <Typography variant="body2">
                    {t('widgets.setup.customise.toggle')}
                    <InfoTooltip size="small" title={t('widgets.setup.customise.toggleTooltip')} />
                  </Typography>
                </Stack>
                <Stack pt={2}>
                  <CustomiseControlsContainer currentWidget={currentWidget} />
                </Stack>
              </Stack>
            )}
            {/* </Box> */}
          </Stack>
        </Fade>
      </Grid>

      {widgetType === WidgetType.LEAD_FORM ? (
        <Grid item xs={12} sm={12} md={5} sx={{ position: 'relative' }}>
          <Stack sx={{ pt: 4 }} alignItems="center">
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                // textAlign: 'center',
                // backgroundColor: theme.palette.grey[900],
                paddingTop: `${topOffset}px`,
                px: 8,
                height: '100vh',
              }}
            >
              <WidgetPreviewBlock widgetType={WidgetType.LEAD_FORM}>
                <Stack sx={{ position: 'absolute', top: 90, left: 10 }}>
                  <Stack sx={{ transform: 'scale(0.40)' }}>
                    <LeadFormPreview />
                  </Stack>
                </Stack>
              </WidgetPreviewBlock>
            </Box>
          </Stack>
        </Grid>
      ) : widgetType === WidgetType.FLOATING_INTRO_VIDEO ? (
        <Grid item xs={12} sm={12} md={5} sx={{ position: 'relative' }}>
          <Stack sx={{ pt: 4 }} alignItems="center">
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                marginTop: isSetup ? -12 : 0,
                // textAlign: 'center',
                // backgroundColor: theme.palette.grey[900],
                paddingTop: `${topOffset}px`,
                px: 8,
                height: '100vh',
              }}
            >
              <WidgetPreviewBlock>
                <div
                  style={{
                    position: 'absolute',
                    right: videoConfig?.position !== Position.bottomLeft ? 76 : 'inherit',
                    bottom: 10,
                  }}
                >
                  {videoConfig && isVideoReady && (
                    <FloatingVideo
                      videoPlaybackId={videoConfig?.videoPlaybackId}
                      position={videoConfig?.position}
                      aspectRatio={videoConfig?.aspectRatio}
                      gifFrame={videoConfig?.gifFrame}
                      height={150}
                      thumbFrame={videoConfig?.thumbFrame}
                    />
                  )}
                </div>
              </WidgetPreviewBlock>
            </Box>
          </Stack>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={5} sx={{ position: 'relative' }}>
          <Stack sx={{ pt: 4 }} alignItems="center">
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                // textAlign: 'center',
                // backgroundColor: theme.palette.grey[900],
                paddingTop: `${topOffset}px`,
                px: 8,
                height: '100vh',
              }}
            >
              <WidgetPreviewBlock isDarkMode={isDarkMode}>
                {image && <ImagePlaceholder widgetImage={image} offsetImage={topOffset} />}
                {widgetComponent && widgetComponent}
              </WidgetPreviewBlock>
            </Box>
          </Stack>
        </Grid>
      )}
    </>
  );
}

export const dummyReviews = [
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
];
export const dummyVideoReviews = [
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    videoPlaybackId: 'sRkw202xA4wLgpX6BDymJcAQ5kPq3BoRA501HjFfRKjLE',
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'John Doe',
    },
    videoPlaybackId: '1LBJJsDWSjr7eT1ZGF4isb00U5SvIhQ00Ed402vORoU7n8',
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    videoPlaybackId: '01QWV3KJMQmVCU02vIi01o8FjwSNvCuB6by2BMp02AVrZJI',
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    videoPlaybackId: 'yYn01ObPqZdxGXK55e01wIL878H01iG00a3zRgV900NtzFCs',
  },
];
export const dummyTextVideoReviews = [
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    type: ReviewType.VIDEO,
    videoPlaybackId: 'sRkw202xA4wLgpX6BDymJcAQ5kPq3BoRA501HjFfRKjLE',
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'John Doe',
    },
    type: ReviewType.TEXT,
    videoPlaybackId: '1LBJJsDWSjr7eT1ZGF4isb00U5SvIhQ00Ed402vORoU7n8',
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    type: ReviewType.VIDEO,
    videoPlaybackId: '01QWV3KJMQmVCU02vIi01o8FjwSNvCuB6by2BMp02AVrZJI',
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
    type: ReviewType.TEXT,
    videoPlaybackId: 'yYn01ObPqZdxGXK55e01wIL878H01iG00a3zRgV900NtzFCs',
  },
];
