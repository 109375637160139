import React from 'react';
import phone from './iphone_blank.png';
import { Stack } from '@mui/material';
import { useSelector } from 'src/redux/store';

export default function PhonePlaceholder({ children }: { children: React.ReactNode }) {
  const slug = useSelector((state) => state.publicConfig.slug);

  return (
    <Stack direction="row" justifyContent="center" sx={{ position: 'relative' }}>
      {children}
      <img src={phone} style={{ width: '43%' }} alt="sms-template-preview" />
    </Stack>
  );
}
