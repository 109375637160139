import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { getCollection } from 'src/api';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection, RequestState } from 'src/enum';
import { PublicConfig } from 'src/redux/slices/publicConfig';
import { useSelector } from 'src/redux/store';

export default function usePublicConfigs() {
  const [state, setState] = useState(RequestState.PENDING);
  const [workspaces, setWorkspaces] = useState<
    { workspaceTitle: string; workspaceId: string; workspaceLogo?: string }[]
  >([]);
  const workspaceIds = useSelector((state) =>
    uniq([
      ...state.workspaceReview.workspaceIds,
      ...state.workspaceFlow.workspaceIds,
      ...state.workspaceLeads.workspaceIds,
      ...state.workspaceLinks.workspaceIds,
    ])
  );

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceIds?.length < 1) return;
    setState(RequestState.PENDING);
    const q = query(
      collection(DB, getCollection[Collection.PUBLIC]()),
      where('userId', 'in', workspaceIds)
    );

    unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const publicConfig = querySnapshot.docs.map((doc) => {
          const config = doc.data() as PublicConfig;
          return {
            workspaceId: config.userId as string,
            workspaceTitle: config.companyName as string,
            workspaceLogo: config?.companyLogo || config?.companyPageLogo?.thumbnail,
          };
        });
        if (publicConfig.length > 0) {
          setWorkspaces(publicConfig);
        }
        setState(RequestState.RESOLVED);
      },
      (error) => {
        console.error(error);
        setState(RequestState.REJECTED);
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, [workspaceIds.length]);

  return {
    isLoading: state === RequestState.PENDING,
    hasError: state === RequestState.REJECTED,
    workspaces,
  };
}
