'use client';

import {
  BadgeCheck,
  Bell,
  ChevronsUpDown,
  CreditCard,
  LogOut,
  Shield,
  Sparkles,
} from 'lucide-react';

import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from 'src/components/ui/sidebar';
import useAuth from 'src/hooks/useAuth';
import { getInitials } from '../../utils/names';
import getAvatarConfig from 'src/utils/getAvatarConfig';
import { useTranslation } from 'react-i18next';
import AdminUser from 'src/guards/UserRoles';
import { useNavigate } from 'react-router';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import { toast } from 'sonner';
import { TabType } from 'src/pages/account/UserAccount';

function AvatarUser({
  displayName,
  email,
  photoUrl,
}: {
  displayName?: string;
  email: string;
  photoUrl?: string;
}) {
  const initials = getInitials(displayName || email)?.toUpperCase();
  const config = getAvatarConfig(displayName || email);
  console.log({ config });

  return (
    <Avatar className="h-8 w-8 rounded-lg">
      <AvatarImage src={photoUrl} alt={displayName} />
      <AvatarFallback style={{ backgroundColor: config.color }} className="rounded-lg text-white">
        {initials}
      </AvatarFallback>
    </Avatar>
  );
}

export function NavUser() {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useSidebar();

  const userAvatar = (
    <AvatarUser displayName={user?.displayName} email={user?.email} photoUrl={user?.photoURL} />
  );

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      toast.error('Unable to logout!');
    }
  };

  const handleLink = (path: string) => {
    navigate(path);
  };

  return (
    <SidebarMenu data-joyride-id="new-sidebar-user">
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              {userAvatar}
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user?.displayName}</span>
                <span className="truncate text-xs">{user?.email}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? 'bottom' : 'right'}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                {userAvatar}
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user?.displayName}</span>
                  <span className="truncate text-xs">{user?.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {/* <DropdownMenuGroup>
              <DropdownMenuItem>
                <Sparkles />
                Upgrade to Pro
              </DropdownMenuItem>
            </DropdownMenuGroup> */}
            {/* <DropdownMenuSeparator /> */}
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => handleLink(PATH_DASHBOARD.user.account)}>
                <BadgeCheck />
                {t('general.settings')}
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => handleLink(`${PATH_DASHBOARD.user.account}?tab=${TabType.BILLING}`)}
              >
                <CreditCard />
                {t('account.tabs.billing')}
              </DropdownMenuItem>
              <AdminUser>
                <DropdownMenuItem onClick={() => handleLink(`/internal/auth`)}>
                  <Shield />
                  Admin view
                </DropdownMenuItem>
              </AdminUser>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>
              <LogOut />
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
