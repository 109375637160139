import React from 'react';
import { cn } from 'src/utils/utils';

export default function PageContent({
  className = '',
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return <div className={cn('p-6', className)}>{children}</div>;
}
