// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { allLang, currentLang, onChangeLang } = useLocales();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="min-w-6 w-6 h-6 p-0" variant="ghost">
            <Image
              sx={{ borderRadius: 0.5 }}
              disabledEffect
              src={currentLang.icon}
              alt={currentLang.label}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-40">
          <DropdownMenuGroup>
            {allLang.map((option) => (
              <>
                <DropdownMenuItem
                  onClick={() => {
                    onChangeLang(option.value);
                  }}
                >
                  <Image
                    disabledEffect
                    alt={option.label}
                    src={option.icon}
                    sx={{ width: 28, mr: 0, borderRadius: 0.5 }}
                  />

                  {option.label}
                  {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
                </DropdownMenuItem>
                {/* <DropdownMenuSeparator /> */}
              </>
            ))}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
