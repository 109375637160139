import * as React from 'react';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import { useSelector } from 'src/redux/store';
import { Platforms } from 'src/redux/slices/platform';
import { Stack, Typography } from '@mui/material';
import reviewPlatform from '../../assets/widget/review_visual.png';
import Image from 'src/components/Image';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isLoadingWorkspaceLeads } from 'src/redux/slices/workspaceLeadsSlice';
import { isWorkspaceOwner, selectHasPersonalWorkspace } from 'src/redux/slices/userSlice';
import SetupLeads from './setupLeads';

export default function ModalLeadsInitialContainer() {
  const { t } = useTranslation();

  const isLeadsPlatform = useSelector((state) => state.user.platform === Platforms.SIMPLY_LEADS);
  const open = useSelector(
    (state) =>
      state.workspaceLeads.workspaceIds.length <= 0 &&
      !isLoadingWorkspaceLeads(state) &&
      state.user.id
  );

  const hasPersonalWorkspace = useSelector(selectHasPersonalWorkspace);

  const canSetupProduct = useSelector(isWorkspaceOwner);

  if (!isLeadsPlatform) return null;

  if (!canSetupProduct) return null;

  if (!open && hasPersonalWorkspace) return null;

  return (
    <div className="fixed flex flex-col p-6 pt-12 justify-center items-center h-[calc(100%)] w-[calc(100%)] mt-0 pl-[--sidebar-width] z-10 backdrop-blur-lg gap-4">
      <div>
        {/* <DialogTitle>Optional sizes</DialogTitle> */}

        {/* <Stack justifyContent="center" alignItems="center">
          <Logo />
        </Stack>
        <Box mt={4} /> */}
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h2">{t('leads.modal.initial.title')}</Typography>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Paper elevation={10} sx={{ borderRadius: 2 }}>
            <Image sx={{ width: 250 }} src={reviewPlatform} />
          </Paper>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <SetupLeads />
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <DialogContentText textAlign="center" width="60%">
            {t('leads.modal.initial.description')}
          </DialogContentText>
        </Stack>
      </div>
    </div>
  );
}
