import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Member } from 'src/redux/slices/workspaceReviewsSlice';
import { getInitials } from '../../utils/names';
import { useTheme } from '@mui/material';
import getAvatarConfig from 'src/utils/getAvatarConfig';

interface AvatarGroupProps {
  users: Array<Member>;
  max?: number;
  onClick?: () => void;
}

export function TeamAvatarGroup({ users, max = 3, onClick }: AvatarGroupProps) {
  const theme = useTheme();
  const visibleUsers = users.slice(0, max);
  const remainingUsers = users.slice(max);

  return (
    <div className="flex -space-x-3 cursor-pointer" role="button" onClick={onClick}>
      {visibleUsers.map((user, index) => {
        const config = getAvatarConfig(user.displayName || user.email);
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Avatar key={index} className="w-9 h-9 border-2 border-background">
                {/* <AvatarImage src={user.image} alt={user.name} /> */}
                <AvatarFallback
                  style={{ backgroundColor: config.color }}
                  className="bg-slate-500 text-white text-xs"
                >
                  {getInitials(user.displayName || user.email)?.toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </TooltipTrigger>
            <TooltipContent>
              <p>{user.displayName || user.email}</p>
            </TooltipContent>
          </Tooltip>
        );
      })}
      {remainingUsers.length > 0 && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Avatar className="w-9 h-9 border-2 border-background bg-muted">
              <AvatarFallback className="text-sm">+{remainingUsers.length}</AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent>
            <p>{remainingUsers.map((user) => user.displayName || user.email).join(', ')}</p>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
}
