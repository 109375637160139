import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Intl, RequestState, Subscription } from '../../enum';
import { Platforms } from './platform';

export enum Industry {
  HEALTH_FITNESS = 1,
  CONSTRUCTION = 2,
  SOFTWARE = 3,
  E_COMMERCE = 4,
  RETAIL = 5,
  FINANCIAL = 6,
  OTHER = 0,
}
export interface User {
  createTime: number;
  email: string | null;
  // name: string | null;
  provider: string | null;
  photoURL: string | null;
  stripeRole: Subscription;
}

export interface UserDoc extends User {
  id: string | null;
  displayName: string;
  email: string | null;
  website?: string;
  industry?: Industry;
  intl: Intl;
  platform?: Platforms;
  currentWorkspace?: string;
  workspaceReviewsCurrent?: string;
  workspaceFlowCurrent?: string;
  workspaceLeadsCurrent?: string;
  workspaceLinksCurrent?: string;
  workspaceBookingsCurrent?: string;
  workspaceChatsCurrent?: string;
  settingUpWorkspace?: boolean;
  notificationPreferences?: {
    newReviewEmail: boolean;
    newReviewPush: boolean;
    newLeadEmail: boolean;
    newLeadPush: boolean;
  };
}

// Define a type for the slice state
interface UserState extends UserDoc {
  stripeRole: Subscription;
  website?: string;
  industry?: Industry;
  intl: Intl;
  settingUpWorkspace?: boolean;
  platform: Platforms;
  state: RequestState;
}

// Define the initial state using that type
const initialState: UserState = {
  id: null,
  displayName: '',
  createTime: 0,
  email: null,
  provider: null,
  photoURL: null,
  state: RequestState.IDLE,
  stripeRole: Subscription.FREE,
  platform: getInitialPlatform(),
  intl: Intl.EN,
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<Partial<User | UserDoc | undefined>>) => ({
      ...state,
      ...action.payload,
      // TODO: ignore platform from database we don't need it anymore
      // @ts-ignore
      platform: action.payload?.platform || state.platform,
      state: RequestState.RESOLVED,
    }),
    setUserPlaform: (state, action: PayloadAction<Platforms>) => ({
      ...state,
      platform: action.payload,
    }),
    setCurrentWorkspace(state, action: PayloadAction<string>) {
      return {
        ...state,
        currentWorkspace: action.payload,
      };
    },
  },
});

export const { setUser, setUserPlaform, setCurrentWorkspace } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user;
export const selectCurrentWorkspaceId = (state: RootState) =>
  state.user.currentWorkspace || state.user.id;
export const selectUserId = (state: RootState) => state.user.id;
export const selectIsOwner = (state: RootState) =>
  selectCurrentWorkspaceId(state) === state.publicConfig?.userId;

export const isWorkspaceOwner = (state: RootState) =>
  selectCurrentWorkspaceId(state) === state.user.id;

export const selectIsSuperOwner = (state: RootState) =>
  state.user.id === state.publicConfig?.userId;

export const selectIsUserReady = (state: RootState) => state.user.state === RequestState.RESOLVED;

export const selectNotificationPreferences = createSelector(
  (state: RootState) => state.user.notificationPreferences,
  (notificationPreferences) => {
    if (!notificationPreferences) {
      return {
        newReviewEmail: true,
        newReviewPush: true,
        newLeadEmail: true,
        newLeadPush: true,
      };
    }

    return {
      newReviewEmail:
        notificationPreferences?.newReviewEmail === undefined
          ? true
          : notificationPreferences.newReviewEmail,
      newReviewPush:
        notificationPreferences?.newReviewPush === undefined
          ? true
          : notificationPreferences.newReviewPush,
      newLeadEmail:
        notificationPreferences?.newLeadEmail === undefined
          ? true
          : notificationPreferences.newLeadEmail,
      newLeadPush:
        notificationPreferences?.newLeadPush === undefined
          ? true
          : notificationPreferences.newLeadPush,
    };
  }
);

export const selectPlatform = (state: RootState) => state.user.platform;

export default userSlice.reducer;

export function getInitialPlatform() {
  if (window.location.hostname?.includes('simplyleads')) {
    return Platforms.SIMPLY_LEADS;
  }
  if (window.location.hostname?.includes('simplyflow')) {
    return Platforms.SIMPLY_FLOW;
  }
  if (window.location.hostname?.includes('simplyleads')) {
    return Platforms.SIMPLY_LEADS;
  }
  if (window.location.hostname?.includes('simplylink')) {
    return Platforms.SIMPLY_LINKS;
  }
  if (window.location.hostname?.includes('simplybooking')) {
    return Platforms.SIMPLY_BOOKING;
  }
  return Platforms.SIMPLY_REVIEW;
}

export const selectHasPersonalWorkspace = (state: RootState) => {
  const reviews = state.workspaceReview.workspaceIds;
  const flow = state.workspaceFlow.workspaceIds;
  const leads = state.workspaceLeads.workspaceIds;
  const links = state.workspaceLinks.workspaceIds;
  const bookings = state.workspaceBookings.workspaceIds;
  const chats = state.workspaceChats.workspaceIds;
  // unique workspaceIds
  const workspaces = new Set([...reviews, ...flow, ...leads, ...links, ...bookings, ...chats]);
  // does any equal userId
  const hasPersonalWorkspace = workspaces.has(state.user.id || '');
  const isCurrentPersonWorkspace =
    !state.user.currentWorkspace && state.user.currentWorkspace !== state.user.id;

  if (!state.user.currentWorkspace && !hasPersonalWorkspace) {
    return false;
  }
  if (state.user.currentWorkspace !== state.user.id && !hasPersonalWorkspace) {
    return true;
  }

  return hasPersonalWorkspace || isCurrentPersonWorkspace;
};
