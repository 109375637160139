import { useEffect, useState } from 'react';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
// import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import {
  setWorkspaceLeads,
  setWorkspaceLeadsState,
  WorkspaceLeads,
} from '../redux/slices/workspaceLeadsSlice';

export default function useWorkspaceLeads() {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) =>
      state.workspaceLeads.state !== RequestState.RESOLVED &&
      state.workspaceLeads.state !== RequestState.REJECTED
  );
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_LEADS]()),
        where(`members.${userId}.uid`, '==', userId)
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.group('useWorkspaceLeads');
          const workspaceLeads = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as WorkspaceLeads[];

          async function setWorkspaceLeadsData() {
            dispatch(setWorkspaceLeads({ workspaces: workspaceLeads }));
          }
          setWorkspaceLeadsData();
        },
        (error) => {
          dispatch(setWorkspaceLeadsState(RequestState.REJECTED));
          console.error('useWorkspaceLeads', error);
          dispatch(setWorkspaceLeads({ workspaces: [] }));
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingWorkspaceLeads: isLoading };
}
