import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Card } from 'src/components/ui/card';
import { Button } from 'src/components/ui/button';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormControl,
  FormLabel,
  FormField,
  FormItem,
  FormMessage,
} from 'src/components/ui/form';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { AUTH } from 'src/contexts/FirebaseContext';
import { toast } from 'sonner';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { t } = useTranslation();

  const ChangePassWordSchema = z
    .object({
      oldPassword: z
        .string()
        .min(1, { message: t('changePassword.validation.oldRequired') as string }),
      newPassword: z
        .string()
        .min(6, { message: t('changePassword.validation.min6Chars') as string })
        .min(1, { message: t('changePassword.validation.newRequired') as string }),
      confirmNewPassword: z
        .string()
        .min(1, { message: t('changePassword.validation.mustMatch') as string }),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: t('changePassword.validation.mustMatch') as string,
      path: ['confirm'],
    });

  const form = useForm<z.infer<typeof ChangePassWordSchema>>({
    resolver: zodResolver(ChangePassWordSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  const {
    reset,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data: z.infer<typeof ChangePassWordSchema>) => {
    try {
      const user = AUTH.currentUser;
      if (!user) throw new Error('No user found');
      if (!user?.email) throw new Error('No user email found');
      const credential = EmailAuthProvider.credential(user?.email, data.oldPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, data.newPassword);
      toast.success('Password changed successfully');
      reset();
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <Card className="p-5 shadow-none">
      {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}> */}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="oldPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-slate-500">
                    {t('changePassword.oldPass')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('changePassword.oldPass') as string}
                      autoComplete="current-password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  {/* <FormDescription>This is your public display name.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-slate-500">
                    {t('changePassword.newPass')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('changePassword.newPass') as string}
                      autoComplete="new-password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  {/* <FormDescription>This is your public display name.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmNewPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-slate-500">
                    {t('changePassword.confirmPass')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('changePassword.confirmPass') as string}
                      autoComplete="new-password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  {/* <FormDescription>This is your public display name.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {/* <RHFTextField
            name="oldPassword"
            type="password"
            autoComplete="current-password"
            label={t('changePassword.oldPass')}
          />

          <RHFTextField
            name="newPassword"
            type="password"
            autoComplete="new-password"
            label={t('changePassword.newPass')}
          />

          <RHFTextField
            name="confirmNewPassword"
            type="password"
            autoComplete="new-password"
            label={t('changePassword.confirmPass')}
          /> */}

          <Button type="submit" loading={isSubmitting}>
            {t('general.form.saveChanges')}
          </Button>
        </form>
      </Form>
      {/* </FormProvider> */}
    </Card>
  );
}
