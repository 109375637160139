// ----------------------------------------------------------------------

import palette from 'src/theme/palette';

const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

function getFirstCharacter(name: string) {
  return name && name.charAt(0).toUpperCase();
}

function getAvatarColor(name: string) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return palette.light.primary.dark; // 'primary';
  if (INFO_NAME.includes(getFirstCharacter(name))) return palette.light.info.dark; // 'info';
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return palette.light.success.dark; // 'success';
  if (WARNING_NAME.includes(getFirstCharacter(name))) return palette.light.warning.dark; // 'warning';
  if (ERROR_NAME.includes(getFirstCharacter(name))) return palette.light.error.dark; // 'error';
  return palette.light.secondary.dark; // 'default';
}

export default function getAvatarConfig(name: string) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  } as const;
}
