import React from 'react';
import { Tooltip, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Pulse } from '../PulseDot';
import { useSelector } from 'src/redux/store';
import { useTranslation } from 'react-i18next';

export default function OnboardingBadge({ path }: { path: string }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const logo = useSelector((state) => state.publicConfig.companyPageLogo);
  const title = useSelector((state) => state.publicConfig.title);

  if (path.includes('/c/') && !logo) {
    return (
      <Tooltip placement="top" title={t('onboarding.companyPage.setup.tooltip') as string}>
        <Box sx={{ position: 'absolute', transform: 'scale(0.5)', top: 0, right: 24 }}>
          <Pulse color={theme.palette.error.main} />
        </Box>
      </Tooltip>
    );
  }
  if (path.includes('/r/') && !title) {
    return (
      <Tooltip placement="top" title={t('onboarding.reviewPage.setup.tooltip') as string}>
        <Box sx={{ position: 'absolute', transform: 'scale(0.5)', top: 0, right: 24 }}>
          <Pulse color={theme.palette.error.main} />
        </Box>
      </Tooltip>
    );
  }
  return null;
}
