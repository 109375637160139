import { useState } from 'react';
import CoverImage from 'src/components/Review/CoverImage';
import CoverVideoOptimzing from 'src/components/Review/CoverVideoOptimzing';
import { useTranslation } from 'react-i18next';
import MuxPlayer from '@mux/mux-player-react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  videoPlaybackId?: string;
  videoProcessed?: boolean;
  reviewerName?: string;
  thumbFrame?: number;
  rating: number;
}

export default function DisplayVideo({
  videoPlaybackId,
  videoProcessed,
  reviewerName,
  thumbFrame = 2,
  rating,
}: Props) {
  const { t } = useTranslation();
  const [playVideo, setPlayVideo] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  if (videoPlaybackId && videoProcessed === false) {
    return <CoverVideoOptimzing text={t('videoPlaceholder.optimizing')} />;
  }
  // if (!toggleVideo && videoPlaybackId) {
  return (
    <Box position="relative">
      <Box
        sx={{
          opacity: playVideo && canPlay ? 0 : 1,
        }}
      >
        <CoverImage
          text={reviewerName}
          rating={rating}
          thumbnail={`https://image.mux.com/${videoPlaybackId}/thumbnail.png?time=${thumbFrame}`} // ?width=400&height=200&fit_mode=preserve
          hoverThumbnail={`https://image.mux.com/${videoPlaybackId}/animated.gif?start=${thumbFrame}`} // https://image.mux.com/${videoPlaybackId}/thumbnail.png?width=400&height=200&fit_mode=preserve&time=35
          onClick={() => setPlayVideo(true)}
        />
      </Box>
      {playVideo && !canPlay && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            zIndex: 2,
            top: 0,
          }}
        >
          <CircularProgress size={48} style={{ color: '#ffffff' }} />
        </div>
      )}
      {playVideo && (
        <Box px={0}>
          <MuxPlayer
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              opacity: playVideo && canPlay ? 1 : 0,
              // @ts-ignore
              '--media-object-fit': 'cover',
              '--media-background-color': 'transparent',
              '--controls': 'none',
              borderRadius: 8,
              // aspectRatio: '9/16',
              maxHeight: 500,
              width: '100%',
              padding: 0,
              cursor: 'pointer',
            }}
            // metadata={{
            //   video_id: token,
            //   video_title: token,
            //   viewer_user_id: workspaceId,
            // }}
            // paused={minimize}
            onCanPlay={() => setCanPlay(true)}
            autoPlay
            className="mux-player"
            streamType="on-demand"
            playbackId={videoPlaybackId}
            // primaryColor
            // secondaryColor
          />
        </Box>
      )}
    </Box>
  );
}
