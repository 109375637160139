// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  MenuItem,
  Stack,
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import InputStyle from '../../../../components/InputStyle';
import { useSelector } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import MenuPopover from '../../../../components/MenuPopover';
import { PremiumFeatureLabel } from '../../../../components/PremiumFeature';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Subscription } from '../../../../enum';
import {
  hasPremiumSelector,
  hasSmsLimitExceeded,
} from '../../../../redux/slices/workspaceReviewsSlice';
import { AutomationType } from 'src/redux/slices/automationsSlice';
import { WarningIcon } from 'src/theme/overrides/CustomIcons';
import { Box } from '@mui/system';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button';
import { ChevronsUpDown } from 'lucide-react';
import { position } from 'stylis';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  numSelected: number;
  filterName: string;
  onFilterName: (value: string) => void;
  onDeleteUsers: VoidFunction;
  additionalParameters?: string;
  disabled?: boolean;
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDeleteUsers,
  additionalParameters,
  disabled,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('general.selected')}
        </Typography>
      ) : (
        <InputStyle
          size="small"
          stretchStart={240}
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          placeholder={`${t('general.search')}...`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onDeleteUsers}>
            <Iconify icon={'eva:trash-2-outline'} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('invites.addTooltip') as string}>
          <MoreMenuButton disabled={disabled} additionalParameters={additionalParameters} />
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={onInvite}
          >
            {hasSlug ? t('invites.add.btn') : t('invites.add.setup')}
          </Button> */}
        </Tooltip>
      )}
    </RootStyle>
  );
}

function MoreMenuButton({
  additionalParameters,
  disabled,
}: {
  additionalParameters?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSmsDisabled = useSelector(hasSmsLimitExceeded);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button disabled={disabled}>
          {t('invites.add.btn')}
          <ChevronsUpDown />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem
          disabled={isSmsDisabled}
          onClick={() =>
            navigate(`add?includeSms=true${additionalParameters ? `&${additionalParameters}` : ''}`)
          }
        >
          {t('invites.form.inviteEmailSms')}
          {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => navigate(`add${additionalParameters ? `?${additionalParameters}` : ''}`)}
        >
          {t('invites.form.inviteEmail')}
          {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() =>
            navigate(`add?manual=true${additionalParameters ? `&${additionalParameters}` : ''}`)
          }
        >
          {t('invites.form.inviteManual')}
          {/* <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut> */}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
