import { Stack, TextField, Typography } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Color } from 'react-color';
import { useTranslation } from 'react-i18next';
import { getCollection } from 'src/api';
import ColorPicker from 'src/components/colorPicker';
import Android12Switch from 'src/components/customSwitch';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { useSelector } from 'src/redux/store';

type Props = {
  videoConfig: {
    buttonText?: string | null;
    buttonLink?: string | null;
  };
  widgetId: string;
};

export default function ActionButton({ videoConfig, widgetId }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const [buttonText, setButtonText] = useState('');
  const [buttonLink, setButtonLink] = useState('');

  const isActive = videoConfig?.buttonText !== null && videoConfig?.buttonText !== undefined;
  // @ts-ignore
  const buttonColor = videoConfig?.buttonColor || '#333333';
  // @ts-ignore
  const buttonTextColor = videoConfig?.buttonTextColor || '#333333';
  useEffect(() => {
    // @ts-ignore
    setButtonLink(videoConfig?.buttonLink);
  }, [videoConfig?.buttonLink]);
  useEffect(() => {
    // @ts-ignore
    setButtonText(videoConfig?.buttonText);
  }, [videoConfig?.buttonText]);

  const updateConfig = async (
    config: Partial<{
      buttonText: string | null;
      buttonLink: string | null;
      buttonColor: Color;
      buttonTextColor: Color;
    }>
  ) => {
    try {
      if (!workspaceId || !widgetId) {
        throw new Error('Workspace ID not found');
      }
      await setDoc(
        doc(DB, getCollection[Collection.WORKSPACE_INTEGRATIONS](workspaceId), widgetId),
        {
          config,
        },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'), { variant: 'success' });
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };

  const onBlurButtonText = () => {
    updateConfig({ buttonText });
  };
  const onBlurButtonLink = () => {
    updateConfig({ buttonLink });
  };

  const toggleActionButton = async () => {
    updateConfig({
      buttonText: isActive ? null : '',
      buttonLink: isActive ? null : '',
      buttonColor,
    });
  };
  const onButtonColorChange = (color: Color) => {
    if (!color) return;
    updateConfig({ buttonColor: color });
  };
  //   const onButtonTextColorChange = (color: Color) => {
  //     if (!color) return;
  //     updateConfig({ buttonTextColor: color });
  //   };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t('widgets.videoSettings.actionButton.headline')}</Typography>
        <Android12Switch checked={isActive} onChange={toggleActionButton} />
      </Stack>
      {isActive && (
        <Stack spacing={2}>
          <TextField
            value={buttonText}
            size="small"
            placeholder={t('widgets.videoSettings.actionButton.text') as string}
            onChange={(e) => setButtonText(e.target.value)}
            onBlur={onBlurButtonText}
          />
          <TextField
            value={buttonLink}
            size="small"
            placeholder={t('widgets.videoSettings.actionButton.url') as string}
            onChange={(e) => setButtonLink(e.target.value)}
            onBlur={onBlurButtonLink}
          />
          <ColorPicker color={buttonColor} onChange={onButtonColorChange} />
          {/* <ColorPicker color={buttonTextColor} onChange={onButtonTextColorChange} /> */}
        </Stack>
      )}
    </>
  );
}
