import React, { useState } from 'react';
import { subtitles } from '../../_mock/subtitles';
import { Typography, Container, Stack, IconButton, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SubtitleEditorContainer from 'src/components/subtitles/Container';
import { useSelector } from 'src/redux/store';
import useReview from 'src/hooks/useReview';
import useReviewTranscript from 'src/hooks/useReviewTranscript';
import { useSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import { DB, Functions } from 'src/contexts/FirebaseContext';
import { LoadingButton } from '@mui/lab';
import Grow from '@mui/material/Grow';
import DownloadMenu from './DownloadMenu';
import ReactPlayer from 'react-player/lazy';
// @ts-ignore
import placeholderVideo from '../../assets/placeholder_subtitles.mp4';
import { loadingReviewState } from 'src/redux/slices/reviewSlice';
import DownloadRenderedVideo from './DownloadRenderedVideo';
import { setDoc, doc } from 'firebase/firestore';
import { getCollection } from '../../api';
import { Collection } from '../../enum';
import { SubTitle } from 'src/components/subtitles';
import useReviewRenders from 'src/hooks/useReviewRenders';
import SubtitlePageLoader from './SubtitlePageLoader';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function VideoCustomisation() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const reviewId = searchParams.get('reviewId');
  const { render } = useReviewRenders(reviewId);
  useReview(reviewId);
  const { isLoading, transcriptId, subtitles } = useReviewTranscript(reviewId);
  const review = useSelector((state) => state.reviews.list.find((r) => r.id === reviewId));
  // const reviewLoading = useSelector(loadingReviewState);
  const { themeStretch } = useSettings();

  const playbackId = review?.videoPlaybackId;

  const generateSubtitles = async () => {
    try {
      setLoading(true);
      if (!workspaceId || !review?.id || !review?.videoPlaybackId) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }
      const functionRef = httpsCallable(Functions, 'getVideoReviewTranscript');
      const response: any = await functionRef({
        workspaceId,
        reviewId: review?.id,
        videoPlaybackId: review?.videoPlaybackId,
      });

      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }

      enqueueSnackbar(t('flow.setup.workspace.success'));
      // window.location.reload();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSubtitles = async (subtitles: SubTitle[]) => {
    try {
      if (!workspaceId || !review?.id) throw new Error(`userId invalid`);
      await setDoc(
        doc(
          DB,
          getCollection[Collection.USERS_REVIEW_TRANSCRIPT](workspaceId, review.id),
          'transcript'
        ),
        { timeframes: subtitles },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () => {
    // show loading if review is loading and there is no review
    if (reviewId && (!review || isLoading)) {
      return <SubtitlePageLoader />;
    }
    if (playbackId) {
      return (
        <SubtitleEditorContainer
          playbackId={playbackId}
          subtitles={subtitles}
          handleSaveSubtitles={handleSaveSubtitles}
          renderNoSubtitles={() => (
            <GenerateSubtitlesButton loading={loading} onClick={generateSubtitles} />
          )}
        />
      );
    }
    return (
      <Stack justifyContent="center" alignItems="center">
        <ReactPlayer
          className="react-player"
          height="100%"
          width="30%"
          playing
          muted
          loop
          style={{ minHeight: 207 }}
          url={placeholderVideo}
          controls={false}
          onError={(e) => console.log('onError', e)}
        />
        <Typography variant="body1" color="" textAlign="center">
          {t('videoCustomisation.placeholder.title')}
        </Typography>
      </Stack>
    );
  };

  return (
    <Page title="Video customisation">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            mb: 5,
          }}
        >
          <Typography variant="h4">{t('videoCustomisation.headline.title')}</Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            {review?.id && <DownloadRenderedVideo render={render} />}
            {playbackId && (
              <DownloadMenu
                videoUrl={`https://stream.mux.com/${playbackId}/low.mp4`}
                subtitles={subtitles}
                reviewId={review.id}
                isRenderInprogress={!render?.url && Boolean(render?.createTime)}
              />
            )}
          </Stack>
        </Box>

        {renderContent()}
      </Container>
    </Page>
  );
}

function GenerateSubtitlesButton({
  loading,
  onClick,
}: {
  loading: boolean;
  onClick: VoidFunction;
}) {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" direction="column" justifyContent="center" sx={{ height: '100%' }}>
      {loading && (
        <Grow in={loading} mountOnEnter unmountOnExit>
          <Stack>
            <Typography variant="body1" color="" textAlign="center">
              {t('videoCustomisation.placeholder.subtitle')}
              {/* It may take a moment to generate your subtitles from the video, but it is gonna be
              great! */}
            </Typography>
            <Box mt={2} />
          </Stack>
        </Grow>
      )}
      <LoadingButton variant="contained" size="large" loading={loading} onClick={onClick}>
        {t('videoCustomisation.placeholder.button')}
        {/* Generate Subtitles */}
      </LoadingButton>
      <Box mt={2} />
      <Typography variant="body2" color="grey.500">
        {t('videoCustomisation.placeholder.subtitle2')}
        {/* (We support most languages, and it is pretty good) */}
      </Typography>
    </Stack>
  );
}
