import { ReactNode, useEffect, useState } from 'react';
import LoadingScreen from '../components/LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';
import ReviewPageOwnerBanner from '../pages/client/ReviewPageOwnerBanner';
import { selectCurrentWorkspaceId, selectIsOwner } from '../redux/slices/userSlice';
import { useSelector } from '../redux/store';
// routes
import PageSettings from '../pages/booking/initial/PageSettings';
import BookingPageOwnerBanner from 'src/pages/booking/BookingPageOwnerBanner';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPublicBookingScheduleId } from 'src/redux/slices/bookingSchedules';

// ----------------------------------------------------------------------

type ReviewPageProps = {
  children: ReactNode;
};

export default function BookingPageGuard({ children }: ReviewPageProps) {
  const dispatch = useDispatch();
  const { slug, scheduleId } = useParams<{ slug: string; scheduleId: string }>();
  useEffect(() => {
    if (scheduleId) dispatch(setPublicBookingScheduleId({ scheduleId }));
  }, [scheduleId]);

  const { isInitialized } = useAuth();
  const isOwner = useSelector(selectIsOwner);
  const userId = useSelector(selectCurrentWorkspaceId) as string;
  const [adminView, setAdminView] = useState(isOwner);

  // console.log({ adminView, isOwner, isAuthenticated, isInitialized });

  useEffect(() => {
    if (isOwner) {
      setAdminView(isOwner);
    }
  }, [isOwner]);

  if (!isInitialized) return <LoadingScreen />;

  if (adminView) {
    return (
      <>
        <BookingPageOwnerBanner isAdminView toggleView={() => setAdminView(!adminView)} />
        <PageSettings userId={userId} />
      </>
    );
  }

  return (
    <>
      <BookingPageOwnerBanner isAdminView={false} toggleView={() => setAdminView(!adminView)} />
      {children}
    </>
  );
}
