import { Button, Tooltip, CircularProgress, Typography } from '@mui/material';
import { RenderDoc } from 'src/hooks/useReviewRenders';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

export default function DownloadRenderedVideo({ render }: { render: RenderDoc | null }) {
  const { t } = useTranslation();

  const isRenderInProgress = !render?.url;

  const handleDownloadVideo = () => {
    if (render) {
      window.open(render.url, '_blank');
    }
  };
  if (render) {
    return (
      <LoadingButton
        variant="contained"
        onClick={handleDownloadVideo}
        disabled={isRenderInProgress}
      >
        {isRenderInProgress && <CircularProgress size={16} sx={{ mr: 1, color: 'grey.500' }} />}
        <Typography variant="button">
          {isRenderInProgress
            ? t('videoCustomisation.rendering.inProgress')
            : t('videoCustomisation.rendering.downloadBtn')}
        </Typography>
      </LoadingButton>
    );
  }
  return null;
}
