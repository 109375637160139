'use client';

import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowUpDown, Mail, MoreHorizontal, UserMinus } from 'lucide-react';

import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Input } from 'src/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { useSelector } from 'src/redux/store';
import { selectIsSuperOwner } from 'src/redux/slices/userSlice';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import { FLOW, LEADS, REVIEW } from 'src/theme/palette';
import SimplyFlowIcon from 'src/assets/Logo/SimplyFlowIcon';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { ConfirmDeleteMember } from './removeMemberDialog';
import { AddTeamMember } from './AddTeamMember';
import { Avatar, AvatarFallback } from '../ui/avatar';
import getAvatarConfig from 'src/utils/getAvatarConfig';
import { getInitials } from 'src/utils/names';
import useWorkspaceTeamMembers, { TeamMember } from './useWorkspaceTeamMembers';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { GREY } from '@simplylabs_/shared';
import { Platforms } from 'src/redux/slices/platform';
import { ConfirmRemoveFromProduct } from './removeMemberFromProductDialog';
import { ConfirmAddToProduct } from './addMemberToProductDialog';
import { ConfirmVoidTeamMemberInvite } from './voidTeamMemberInvite';
import { Skeleton } from '../ui/skeleton';

export enum ConfirmType {
  DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER',
  REMOVE_FROM_PRODUCT = 'REMOVE_FROM_PRODUCT',
  INVITE_TO_PRODUCT = 'INVITE_TO_PRODUCT',
  ADD_TO_PRODUCT = 'ADD_TO_PRODUCT',
}

export type TeamMemberManager = TeamMember & {
  confirmType: ConfirmType | null;
  platform?: Platforms | null;
};
export type TeamMemberInvite = TeamMember & {
  inviteId: string | null;
};

export function TeamMemberTable() {
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useWorkspaceTeamMembers();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [member, setMember] = React.useState<TeamMemberManager | TeamMemberInvite | null>(null);
  const userId = useSelector((state) => state.user.id);
  const isSuperOwner = useSelector(selectIsSuperOwner);

  const handleRemoveMember = (member: TeamMember) => {
    if (isSuperOwner && userId !== member?.uid) {
      return setMember({ ...member, confirmType: ConfirmType.DELETE_TEAM_MEMBER });
    }
    if (isSuperOwner && userId === member?.uid) {
      return toast.info(t('team.members.guard.removeYou'));
    }
    return toast.info(t('team.members.guard.owner'));
  };

  const handleClose = () => {
    setMember(null);
  };

  // const handleInviteTeam = () => {
  //   if (hasPremium) {
  //     dispatch(setModal({ type: ModalType.INVITE_MEMBER, context: null }));
  //   } else {
  //     return toast.error(t('general.premiumFeature'));
  //   }
  // };

  const columns: ColumnDef<TeamMember>[] = [
    // {
    //   id: 'select',
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={
    //         table.getIsAllPageRowsSelected() ||
    //         (table.getIsSomePageRowsSelected() && 'indeterminate')
    //       }
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false,
    // },
    {
      accessorKey: 'avatar',
      header: '',
      cell: ({ row }) => {
        // @ts-ignore
        const isInvite = !row.original.uid;
        const email: string = row.getValue('email');
        const displayName: string = row.getValue('displayName');
        const config = getAvatarConfig(displayName || email);
        if (isInvite) {
          return (
            <div className="flex items-center justify-center w-8 h-8">
              <Mail width={18} color={GREY[500]} />
            </div>
          );
        }
        return (
          <Avatar className="w-8 h-8 border-2 border-background">
            {/* <AvatarImage src={user.image} alt={user.name} /> */}
            <AvatarFallback
              style={{ backgroundColor: config.color }}
              className="bg-slate-500 text-white text-xs"
            >
              {getInitials(displayName || email)?.toUpperCase()}
            </AvatarFallback>
          </Avatar>
        );
      },
    },
    {
      accessorKey: 'displayName',
      header: 'Name',
      cell: ({ row }) => <div className="capitalize">{row.getValue('displayName')}</div>,
    },
    {
      accessorKey: 'email',
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <>
            Email
            <ArrowUpDown />
          </>
        </Button>
      ),
      cell: ({ row }) => <div className="lowercase">{row.getValue('email')}</div>,
    },
    {
      accessorKey: 'review',
      header: ({ column }) => (
        <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
          <SimplyReviewIcon width={8} color={REVIEW.lighter} />
        </div>
      ),
      cell: ({ row }) => {
        const hasInvite = Boolean(row.original.reviewInvite);

        if (hasInvite) {
          return (
            <Tooltip>
              <TooltipTrigger asChild>
                <Checkbox
                  checked
                  onCheckedChange={() => {
                    setMember({
                      ...row.original,
                      inviteId: row.original.reviewInvite?.id || null,
                    });
                  }}
                />
              </TooltipTrigger>
              <TooltipContent>
                <div className="p-2">
                  <div className="text-sm font-medium">Invite pending</div>
                  <div className="text-xs text-muted-foreground">
                    The user has been invited to join SimplyReview
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          );
        }

        const isMember = Boolean(row.original.review);

        return (
          <div>
            <Checkbox
              checked={isMember}
              disabled={row.original.reviewDisabled}
              onCheckedChange={(checked) => {
                console.log({ checked });
                if (isMember) {
                  setMember({
                    ...row.original,
                    confirmType: ConfirmType.REMOVE_FROM_PRODUCT,
                    platform: Platforms.SIMPLY_REVIEW,
                  });
                } else {
                  setMember({
                    ...row.original,
                    confirmType: ConfirmType.ADD_TO_PRODUCT,
                    platform: Platforms.SIMPLY_REVIEW,
                  });
                }
              }}
            />
          </div>
        );
      },
    },
    {
      accessorKey: 'flow',
      header: ({ column }) => (
        <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
          <SimplyFlowIcon width={10} color={FLOW.lighter} />
        </div>
      ),
      cell: ({ row }) => {
        // @ts-ignore
        const hasInvite = Boolean(row.original.flowInvite);

        if (hasInvite) {
          return (
            <Tooltip>
              <TooltipTrigger asChild>
                <Checkbox
                  checked
                  onCheckedChange={() => {
                    setMember({
                      ...row.original,
                      inviteId: row.original.flowInvite?.id || null,
                    });
                  }}
                />
              </TooltipTrigger>
              <TooltipContent>
                <div className="p-2">
                  <div className="text-sm font-medium">Invite pending</div>
                  <div className="text-xs text-muted-foreground">
                    The user has been invited to join SimplyFlow.
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          );
        }
        const isMember = Boolean(row.original.flow);
        return (
          <div>
            <Checkbox
              checked={isMember}
              disabled={row.original.flowDisabled}
              onCheckedChange={(checked) => {
                if (isMember) {
                  setMember({
                    ...row.original,
                    confirmType: ConfirmType.REMOVE_FROM_PRODUCT,
                    platform: Platforms.SIMPLY_FLOW,
                  });
                } else {
                  setMember({
                    ...row.original,
                    confirmType: ConfirmType.ADD_TO_PRODUCT,
                    platform: Platforms.SIMPLY_FLOW,
                  });
                }
              }}
            />
          </div>
        );
      },
    },
    // TODO: ENABLE TEAM FOR LEADS
    // {
    //   accessorKey: 'leads',
    //   header: ({ column }) => (
    //     <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
    //       <SimplyLeadsIcon width={14} color={LEADS.light} />
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     const hasInvite = Boolean(row.original.leadsInvite);

    //     if (hasInvite) {
    //       return (
    //         <Tooltip>
    //           <TooltipTrigger asChild>
    //             <Checkbox
    //               checked
    //               onCheckedChange={() => {
    //                 setMember({
    //                   ...row.original,
    //                   inviteId: row.original.leadsInvite?.id || null,
    //                 });
    //               }}
    //             />
    //           </TooltipTrigger>
    //           <TooltipContent>
    //             <div className="p-2">
    //               <div className="text-sm font-medium">Invite pending</div>
    //               <div className="text-xs text-muted-foreground">
    //                 The user has been invited to join SimplyLeads
    //               </div>
    //             </div>
    //           </TooltipContent>
    //         </Tooltip>
    //       );
    //     }

    //     const isMember = Boolean(row.original.leads);

    //     return (
    //       <div>
    //         <Checkbox
    //           checked={isMember}
    //           disabled={row.original.leadsDisabled}
    //           onCheckedChange={(checked) => {
    //             if (isMember) {
    //               setMember({
    //                 ...row.original,
    //                 confirmType: ConfirmType.REMOVE_FROM_PRODUCT,
    //                 platform: Platforms.SIMPLY_LEADS,
    //               });
    //             } else {
    //               setMember({
    //                 ...row.original,
    //                 confirmType: ConfirmType.ADD_TO_PRODUCT,
    //                 platform: Platforms.SIMPLY_LEADS,
    //               });
    //             }
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const member = row.original as TeamMember;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>

              <DropdownMenuItem
                className="text-red-600 hover:text-red-300"
                onClick={() => handleRemoveMember(member)}
              >
                <UserMinus /> Remove member
              </DropdownMenuItem>

              {/* <DropdownMenuSeparator />
              <DropdownMenuItem>View customer</DropdownMenuItem>
              <DropdownMenuItem>View payment details</DropdownMenuItem> */}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns: React.useMemo(() => columns, []),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full">
      <div className="flex items-center py-4 justify-between">
        <Input
          placeholder="Filter emails..."
          value={(table.getColumn('email')?.getFilterValue() as string) ?? ''}
          onChange={(event) => table.getColumn('email')?.setFilterValue(event.target.value)}
          className="max-w-sm"
        />

        <AddTeamMember />
        {/* <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu> */}
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
      <ConfirmVoidTeamMemberInvite
        member={member as TeamMemberInvite}
        refetch={refetch}
        onClose={handleClose}
      />
      <ConfirmAddToProduct
        member={member as TeamMemberManager}
        refetch={refetch}
        onClose={handleClose}
      />
      <ConfirmRemoveFromProduct
        member={member as TeamMemberManager}
        refetch={refetch}
        onClose={handleClose}
      />
      <ConfirmDeleteMember
        member={member as TeamMemberManager}
        refetch={refetch}
        onClose={handleClose}
      />
    </div>
  );
}

function TableLoader() {
  return (
    <>
      <RowLoader />
      <RowLoader />
      <RowLoader />
      <RowLoader />
      <RowLoader />
    </>
  );
}

function RowLoader() {
  return (
    <TableRow>
      <TableCell />
      <TableCell>
        <Skeleton className="h-4 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-full" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-full" />
      </TableCell>
    </TableRow>
  );
}
