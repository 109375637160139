'use client';

import { ChevronRight, Lock, type LucideIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from 'src/components/ui/sidebar';
import { useSelector } from 'src/redux/store';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { selectHasPersonalWorkspace } from 'src/redux/slices/userSlice';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';

export function NavMain({
  items,
}: {
  items: {
    title: string;
    url: string;
    icon?: React.ReactNode;
    isActive?: boolean;
    isAdminOnly?: boolean;
    items?: {
      title: string;
      url: string;
      icon?: LucideIcon;
      badge?: React.ReactNode;
      external?: boolean;
    }[];
  }[];
}) {
  const { isMobile, state } = useSidebar();
  const { t } = useTranslation();
  const activeTitle = items.find((item) => item.isActive)?.title;
  const [active, setActive] = useState<string>(activeTitle || '');
  const currentPath = location.pathname;
  const hasPersonalWorkspace = useSelector(selectHasPersonalWorkspace);
  const isAdmin = useSelector((state) => Boolean(state.user.email?.includes('@simply')));
  const isCollapsed = state === 'collapsed';
  if (isCollapsed) {
    return (
      <SidebarMenu className="flex flex-col gap-2 p-2" data-joyride-id="new-sidebar-products">
        {items.map((item) => {
          if (item.isAdminOnly && !isAdmin) return null;
          return (
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton
                    size="lg"
                    className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                  >
                    <div className="rounded bg-black h-8 w-8 items-center justify-center flex">
                      {item.icon}
                    </div>
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                  side={isMobile ? 'bottom' : 'right'}
                  align="start"
                  sideOffset={4}
                >
                  <DropdownMenuLabel className="text-xs text-muted-foreground">
                    {item?.title}
                  </DropdownMenuLabel>
                  {item.items?.map((subItem) => (
                    <DropdownMenuItem key={subItem.title} asChild>
                      <Link to={subItem.url}>
                        {subItem?.icon && <subItem.icon />}
                        <span>{t(subItem.title)}</span>
                        {subItem.badge}
                      </Link>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          );
        })}
      </SidebarMenu>
    );
  }
  return (
    <SidebarGroup data-joyride-id="new-sidebar-products">
      <SidebarGroupLabel>Platform</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => {
          if (item.isAdminOnly && !isAdmin) return null;
          return (
            <Collapsible
              key={item.title}
              asChild
              // disabled={!hasPersonalWorkspace}
              open={active === item.title}
              onOpenChange={() => setActive(item.title)}
              // defaultOpen={item.isActive}
              className={'group/collapsible'}
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton tooltip={item.title}>
                    <div className="rounded bg-black h-6 w-6 items-center justify-center flex">
                      {item.icon}
                    </div>
                    {!isCollapsed && (
                      <>
                        {/* {!hasPersonalWorkspace && <Lock />} */}
                        <span>{item.title}</span>
                        <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </>
                    )}
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {item.items?.map((subItem) => (
                      <SidebarMenuSubItem key={subItem.title}>
                        <SidebarMenuSubButton
                          asChild
                          isActive={currentPath === subItem.url}
                          className="justify-between"
                        >
                          {subItem?.external && !hasPersonalWorkspace ? (
                            <span className="opacity-50 cursor-not-allowed">
                              {subItem?.icon && <subItem.icon />}
                              <span>{t(subItem.title)}</span>
                              {subItem.badge}
                            </span>
                          ) : (
                            <Link to={subItem.url}>
                              {subItem?.icon && <subItem.icon />}
                              <span>{t(subItem.title)}</span>
                              {subItem.badge}
                            </Link>
                          )}
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
}
