import React, { useState } from 'react';
import Iconify from '../../../../components/Iconify';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Highlight, themes } from 'prism-react-renderer';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { Check, Copy } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button';

interface Props {
  code: string;
}

export default function WidgetCodeBlock({ code }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
    toast.success(t('general.copied'));
  };
  if (!code) return null;

  return (
    <div
      aria-label="Click to copy code"
      role="button"
      className="relative border border-gray-200 rounded-lg overflow-hidden"
      onClick={copyToClipboard}
    >
      <Highlight theme={themes.vsDark} code={code} language="html">
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={`${className} p-4 overflow-x-auto text-xs`} style={style}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
      <Button
        size="sm"
        type="button"
        className="absolute top-2 right-2 p-2 bg-white border border-gray-200 rounded-md text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
        aria-label={isCopied ? 'Copied!' : 'Copy code to clipboard'}
      >
        {isCopied ? <Check className="w-5 h-5" /> : <Copy className="w-5 h-5" />}
      </Button>
    </div>
  );

  return (
    <>
      <Box>
        <Typography variant="h6">{t('general.copyCode')}</Typography>
      </Box>
      <Box sx={{ mt: 2 }} />
      {code && (
        <CopyToClipboard text={code} onCopy={(text) => enqueueSnackbar(t('general.copied'))}>
          <pre
            style={{
              backgroundColor: theme.palette.grey[200],
              borderRadius: 8,
              position: 'relative',
              fontSize: 12,
              whiteSpace: 'break-spaces',
              cursor: 'pointer',
            }}
          >
            <Box sx={{ py: 1, px: 2 }}>
              <Typography variant="body2">{code}</Typography>
            </Box>

            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <Tooltip title={t('general.copy') as string}>
                <IconButton>
                  <Iconify
                    style={{
                      color: theme.palette.primary.main,
                      // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
                    }}
                    icon={'eva:copy-fill'}
                    width={24}
                    height={24}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </pre>
        </CopyToClipboard>
      )}
    </>
  );
}
