import React from 'react';
import { Transition } from '../sections/@dashboard/user/initial/InitialSettings';
import Dialog from '@mui/material/Dialog';
// import InviteForm from './TeamMemberInviteForm';
import { useDispatch, useSelector } from '../redux/store';
import { ModalType, setModal } from '../redux/slices/modal';
import RemoveMember from './RemoveMember';
import AcceptInvite from './AcceptInvite';
import { Platforms } from '../redux/slices/platform';

export default function ModalContainer() {
  const dispatch = useDispatch();
  // const platform = useSelector((state) => state.user.platform);
  const isModalInviteModal = useSelector((state) => state.modal.type === ModalType.INVITE_MEMBER);
  const isModalAcceptInvite = useSelector(
    (state) => state.modal.type === ModalType.ACCEPT_INVITE_MEMBER
  );
  const isModalRemoveMember = useSelector((state) => state.modal.type === ModalType.REMOVE_MEMBER);
  const isOpen = isModalInviteModal || isModalRemoveMember || isModalAcceptInvite;

  const handleCloseModal = () => dispatch(setModal(null));
  // if (platform === Platforms.SIMPLY_REVIEW) {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      aria-describedby="invite-member"
      maxWidth="md"
    >
      {/* {isModalInviteModal && <InviteForm handleClose={handleCloseModal} />} */}
      {isModalAcceptInvite && <AcceptInvite handleClose={handleCloseModal} />}
      {isModalRemoveMember && <RemoveMember handleClose={handleCloseModal} />}
    </Dialog>
  );
  // }
  // return null;
}
