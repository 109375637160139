import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intl, RequestState } from '../../enum';
import type { RootState } from '../store';
import { QuestionType } from 'src/pages/simplyleads/leads/QuestionsSection';
import { keyBy } from 'lodash';

export interface BookingScheduleType {
  id: string;
  createTime: Date;
  title: string;
  description: string;
  duration: number;
  active: boolean;
  rollingdays: number;
  weekAvailability: {
    monday?: {
      from: number;
      to: number;
    }[];
    tuesday?: {
      from: number;
      to: number;
    }[];
    wednesday?: {
      from: number;
      to: number;
    }[];
    thursday?: {
      from: number;
      to: number;
    }[];
    friday?: {
      from: number;
      to: number;
    }[];
    saturday?: {
      from: number;
      to: number;
    }[];
    sunday?: {
      from: number;
      to: number;
    }[];
  };
  language: Intl;
  color: string;
  logo: {
    original: string;
    thumbnail: string;
  };
}

// Define a type for the slice state
interface BookingScheduleState {
  list: string[];
  map: { [key: string]: BookingScheduleType };
  loading: RequestState;
  currentIdState: RequestState;
  currentId: string | null;
}

// Define the initial state using that type
const initialState: BookingScheduleState = {
  list: [],
  map: {},
  loading: RequestState.IDLE,
  currentIdState: RequestState.IDLE,
  currentId: null,
};

export const bookingSchedulesSlice = createSlice({
  name: 'bookingSchedules',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setBookingSchedulesLoadingState: (state) => ({
      ...state,
      loading: RequestState.PENDING,
    }),
    setBookingSchedules: (state, action: PayloadAction<BookingScheduleType[]>) => ({
      ...state,
      list: action.payload.map((i) => i.id),
      map: keyBy(action.payload, 'id'),
      loading: RequestState.RESOLVED,
    }),
    setPublicBookingScheduleLoading: (state) => ({
      ...state,
      currentIdState: RequestState.PENDING,
    }),
    setPublicBookingSchedule: (state, action: PayloadAction<BookingScheduleType>) => ({
      ...state,
      list: Array.from(new Set([...state.list, action.payload.id])),
      map: {
        ...state.map,
        [action.payload.id]: action.payload,
      },
      currentId: action.payload.id,
      currentIdState: RequestState.RESOLVED,
    }),
    setPublicBookingScheduleId: (state, action: PayloadAction<{ scheduleId: string }>) => ({
      ...state,
      currentId: action.payload.scheduleId,
      currentIdState: RequestState.RESOLVED,
    }),
  },
});

export const {
  setBookingSchedulesLoadingState,
  setBookingSchedules,
  setPublicBookingScheduleLoading,
  setPublicBookingSchedule,
  setPublicBookingScheduleId,
} = bookingSchedulesSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const loadingBookingSchedulesState = (state: RootState) =>
  state.bookingSchedules.loading === RequestState.IDLE ||
  state.bookingSchedules.loading === RequestState.PENDING;

export const selectHasLeads = (state: RootState) => state.bookingSchedules.list.length > 0;

export const selectCurrentBookingSchedule = (state: RootState) =>
  state.bookingSchedules.currentId
    ? state.bookingSchedules.map[state.bookingSchedules.currentId]
    : null;

export default bookingSchedulesSlice.reducer;
