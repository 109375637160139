// @mui
import { Container } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';

import IntegrationsWizard from './IntegrationsWizard';
import PageContent from 'src/layouts/dashboard/PageContent';

export default function IntegrationsFlowNew() {
  const { themeStretch } = useSettings();

  return (
    <Page title="General: Widgets">
      <PageContent>
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          {t('Widgets')}
        </Typography> */}

        <IntegrationsWizard />
      </PageContent>
      {/* <RequiredWidgetFormContainer /> */}
    </Page>
  );
}
