import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from '../../../redux/store';
import { Grid, Box, InputAdornment, Typography } from '@mui/material';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { FormProvider } from '../../../components/hook-form';

import { useSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import { Functions } from '../../../contexts/FirebaseContext';
import { selectPlatformDisplayName } from '../../../redux/selectors/platform';
import { selectCurrentWorkspaceId, selectPlatform } from '../../../redux/slices/userSlice';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

type InitialSettingsProps = { message: string; email: string };

export default function SendToAFriendForm({
  widgetId,
  handleClose,
}: {
  widgetId: string;
  handleClose: VoidFunction;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const platformDisplayName = useSelector(selectPlatformDisplayName);
  const userId = useSelector(selectCurrentWorkspaceId);
  const platform = useSelector(selectPlatform);
  const UpdateUserSchema = z.object({
    message: z.string().min(1, { message: t('validation.description') as string }),
    email: z.string().email({ message: t('validation.email.required') as string }),
  });
  const defaultValues = {
    message: t('widgets.email.sendToFriend', { platform: platformDisplayName }),
    email: '',
  };

  const methods = useForm<InitialSettingsProps>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues,
  });
  const {
    // watch,
    // setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = methods;
  // const values = watch();
  const onSubmitSlug = async (data: any) => {
    try {
      if (!widgetId) throw new Error(`widgetId invalid`);
      if (!userId) throw new Error(`userId invalid`);

      const functionRef = httpsCallable(Functions, 'sendWidgetInstructions');
      const response: any = await functionRef({
        email: data.email,
        widgetId,
        workspaceId: userId,
        message: data.message,
        platform,
      });
      // const configId = response?.data?.id;

      // @ts-ignore
      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        enqueueSnackbar(t(error.message), { variant: 'error' });
        return;
      }

      enqueueSnackbar(t('widgets.sendToFriend.success'));
      // onClose();
      // setStep(Steps.INDUSTRY);
      // setOpen(false);
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    }
  };
  const onSubmit = async (data: InitialSettingsProps) => {
    try {
      await onSubmitSlug(data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <DialogTitle>{t('widgets.sendToFriend.header')}</DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Box>
              <Typography variant="body2">{t('widgets.sendToFriend.description')}</Typography>
              <Box sx={{ mt: 3 }} />
            </Box>
            <Box>
              <RHFTextField
                name="message"
                // disabled
                multiline
                rows={8}
                // placeholder={t(
                //   ''
                // )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <Typography variant="subtitle2" color={theme.palette.grey[500]}>
              {t('widgets.sendToFriend.attachedCode')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2 }}>
            <RHFTextField
              name="email"
              type="email"
              placeholder={t('general.placeholder.email') as string}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {t('general.send')}
                    </LoadingButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </FormProvider>
      </DialogContent>
    </>
  );
}
