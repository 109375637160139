import { Badge, Tooltip } from '@mui/material';
import { Box } from '@mui/material';
import { useSelector } from 'src/redux/store';
import { selectZenfitApi } from 'src/redux/slices/apiIntegrationsSlice';
import { selectZenfitAutomation } from 'src/redux/slices/automationsSlice';
import { useTranslation } from 'react-i18next';

export default function MissingAutomationSetup() {
  const { t } = useTranslation();
  const connectedAPIKey = useSelector(selectZenfitApi);
  const hasAutomation = useSelector(selectZenfitAutomation);
  const display = connectedAPIKey && !hasAutomation ? ' ' : 0;
  return (
    <Tooltip title={t('integrations.zenfit.automation.missing.warning')}>
      <Box sx={{ position: 'absolute', right: 18, top: 4 }}>
        <Badge color="warning" badgeContent={display} />
      </Box>
    </Tooltip>
  );
}
