import { useSelector } from '../../../../redux/store';
import { ROOTS } from '../../../../enum';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogHeader } from 'src/components/ui/dialog';
import {
  hasAllPlatformsLoaded,
  hasWorkspacesByNotPersonalWorkspace,
} from 'src/redux/selectors/platform';
import usePublicConfigs from 'src/components/sidebar/usePublicConfigs';
import { DropdownMenuItem } from 'src/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { useDispatch } from 'react-redux';
import { setCurrentWorkspace } from 'src/redux/slices/userSlice';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { toast } from 'sonner';
import { Card } from 'src/components/ui/card';

export default function InitialPickWorkspace() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state) => state.user.id);
  const { workspaces } = usePublicConfigs();
  const open = useSelector(hasAllPlatformsLoaded);
  const hasCurrentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const hasOnlyInviteWorkspaces = useSelector(hasWorkspacesByNotPersonalWorkspace);
  const shouldOpen = open && !hasCurrentWorkspace && hasOnlyInviteWorkspaces;

  const switchWorkspace = async (workspaceId: string) => {
    try {
      if (!userId) throw new Error('User not found');
      dispatch(setCurrentWorkspace(workspaceId));
      const userRef = doc(collection(DB, 'users'), userId);
      await setDoc(userRef, { currentWorkspace: workspaceId }, { merge: true });
      toast.success('Workspace switched successfully');
    } catch (error) {
      console.error(error);
      toast.error('Failed to switch workspace');
    }
  };

  return (
    <Dialog
      open={Boolean(shouldOpen)}
      // TransitionComponent={Transition}
      // keepMounted
      // onClose={handleConfirmModal}
      aria-describedby="alert-dialog-slide-description"
    >
      {/* <DialogTitle> */}
      {/* </DialogTitle> */}

      <DialogContent>
        <DialogHeader>
          <p className="text-xl font-bold">{t('form.initialWorkspacePicker.header')}</p>
          <p className="text-xs">{t('form.initialWorkspacePicker.subheader')}</p>
        </DialogHeader>
        <div className="grid gap-6 pt-4">
          {workspaces.map((workspace) => (
            <Card
              key={workspace.workspaceId}
              className={
                'flex gap-2 p-4 items-center cursor-pointer shadow-none hover:bg-slate-100 transition-all'
              }
              onClick={() => switchWorkspace(workspace.workspaceId)}
            >
              <div className="flex size-8 items-center justify-center rounded-sm border overflow-hidden">
                <Avatar className="size-8 rounded-none">
                  <AvatarImage src={workspace?.workspaceLogo} alt={workspace?.workspaceTitle} />
                  <AvatarFallback className="rounded-none">
                    {workspace.workspaceTitle?.[0]}
                  </AvatarFallback>
                </Avatar>
              </div>
              {workspace?.workspaceTitle}
            </Card>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
