import React from 'react';
import { Box, Container, Grid, Stack, Typography, alpha, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Page from 'src/components/Page';
import usePublicSlug from 'src/hooks/usePublicSlug';
import useSettings from 'src/hooks/useSettings';
import { useSelector } from 'src/redux/store';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSnackbar } from 'notistack';
import Image from 'src/components/Image';
import { Avatar } from '@mui/material';
import Wave from 'src/components/animate/backgrounds/Wave';
import Snakes from 'src/components/animate/backgrounds/snakes';
import Sundown from 'src/components/animate/backgrounds/sundown';
import Blobs from 'src/components/animate/backgrounds/blobs';
import FloatBalls from 'src/components/animate/backgrounds/floatBalls';
import { Card } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { customShadows } from 'src/theme/shadows';
import { getLinkTypeConfig } from '../linksCustomisePage';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import { LinkType } from 'src/redux/slices/publicConfig';
import SimplyLinksLogo from 'src/assets/Logo/SimplyLinksLogo';
import PageContent from '../../../layouts/dashboard/PageContent';

export default function LinksPageContainer() {
  const { slug } = useParams<{ slug: string }>();

  const { isLoadingPublicConfig, userId, companyPageLogo, title, color, companyName } =
    usePublicSlug(slug);

  if (isLoadingPublicConfig) {
    return <LoadingScreen />;
  }
  return <LinksPage />;
}

export function LinksPage({
  linkPageColor,
  linkPageLogo,
  linkPageName,
  linkPagePattern,
  linkPageLinks,
  preview = false,
}: {
  linkPageColor?: string;
  linkPageLogo?: string;
  linkPageName?: string;
  linkPagePattern?: string | null;
  linkPageLinks?: any[];
  preview?: boolean;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const {
    color,
    companyName,
    companyPageLogo,
    linkPageName: configLinksPageName,
    linkPageLogo: configLinksPageLogo,
    linkPageColor: configLinksPageColor,
    linkPagePattern: configLinksPagePattern,
    linkPageLinks: configLinksPageLinks,
    userId,
  } = useSelector((state) => state.publicConfig);

  const customColor = linkPageColor || configLinksPageColor || color || '#161615';

  const backgroundColor = alpha(customColor, 0.1);
  // const patternColor = alpha(customColor, 0.25);

  const pattern = linkPagePattern !== undefined ? linkPagePattern : configLinksPagePattern;
  const links = linkPageLinks || configLinksPageLinks;
  return (
    <Page title="Links" /* height="100vh" sx={{ overflowY: 'scroll', backgroundColor, pb: 4 }} */>
      {/* <Box
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,

          width: '100%',
          height: '100vh',
          zIndex: -1,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            // width: 500,
            // height: 500,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <FloatBalls color={customColor} />
        </Box>
      </Box> */}
      {pattern === 'blobs' && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,

            width: '100%',
            height: '100vh',
            zIndex: -1,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              // width: 500,
              // height: 500,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Blobs color={customColor} />
          </Box>
        </Box>
      )}
      {pattern === 'sundown' && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              // width: 500,
              // height: 500,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Sundown color={customColor} />
          </Box>
        </Box>
      )}
      <PageContent className="h-screen">
        <Grid container spacing={3} sx={{ zIndex: 1, height: '100%', marginTop: 0 }}>
          {!preview && <Grid item md={2} xs={0} display={{ md: 'block', xs: 'none' }} />}
          <Grid item md={preview ? 12 : 8} xs={12} alignSelf="center">
            <Stack>
              <Avatar
                src={linkPageLogo || configLinksPageLogo?.thumbnail || companyPageLogo?.thumbnail}
                sx={{
                  mx: 'auto',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: 'common.white',
                  width: { xs: 80, md: 128 },
                  height: { xs: 80, md: 128 },
                }}
              />
            </Stack>
            <Stack alignItems="center" mb={2}>
              <Typography variant="subtitle1" fontSize={44} color="textPrimary">
                {linkPageName || configLinksPageName || companyName}
              </Typography>
            </Stack>
            <Stack alignItems="center" spacing={1} pb={8}>
              {links
                ?.filter((item) => item.visible)
                .map((item) => {
                  const isSimply = [
                    LinkType.LEADS_PAGE,
                    LinkType.REVIEW_PAGE,
                    LinkType.REVIEW_COMPANY_PAGE,
                  ].includes(item.type);
                  const linkConfig = getLinkTypeConfig(item.type);
                  const image = item.thumbnail?.preview || item.thumbnail;
                  return (
                    <Card
                      key={item.id}
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        p: 1,
                        boxShadow: customShadows.dark.z1,
                        '&:hover': {
                          boxShadow: customShadows.dark.z8,
                        },
                      }}
                      onClick={() => window.open(item.link, '_blank')}
                    >
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        minHeight={48}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {(item.type === LinkType.CUSTOM ||
                            item.type === LinkType.REVIEW_COMPANY_PAGE) &&
                            image && (
                              <Image
                                sx={{ height: 48, minWidth: 48, maxWidth: 48, borderRadius: 1 }}
                                src={image}
                              />
                            )}

                          {item.type === LinkType.REVIEW_PAGE && (
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                background: linkConfig.background,
                                width: 48,
                                height: 48,
                                borderRadius: 1,
                              }}
                            >
                              <SimplyReviewIcon color="#161615" width={14} />

                              {item.type === LinkType.LEADS_PAGE && (
                                <SimplyLeadsIcon color="#161615" width={24} />
                              )}
                            </Stack>
                          )}
                          {item.type === LinkType.LEADS_PAGE && (
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                background: linkConfig.background,
                                width: 48,
                                height: 48,
                                borderRadius: 1,
                              }}
                            >
                              <SimplyLeadsIcon color="#161615" width={24} />
                            </Stack>
                          )}

                          {!isSimply && item.type !== LinkType.CUSTOM && (
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                background: linkConfig.background,
                                width: 48,
                                height: 48,
                                borderRadius: 1,
                              }}
                            >
                              <Iconify
                                icon={linkConfig.icon}
                                color="white"
                                height={28}
                                width={28}
                              />
                            </Stack>
                          )}
                          <Typography variant="subtitle1" fontSize={18} color="textPrimary">
                            {item.title}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Iconify fontSize={24} icon="radix-icons:arrow-top-right" />
                        </Stack>
                      </Stack>
                    </Card>
                  );
                })}
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              spacing={1}
              pb={8}
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open('https://app.simplylink.io', '_blank')}
            >
              <Typography>Make your own</Typography> <SimplyLinksLogo />
            </Stack>
          </Grid>
        </Grid>
      </PageContent>
    </Page>
  );
}
