import { PATH_AUTH } from 'src/routes/paths';
import { Link } from 'react-router-dom';
import { cn } from 'src/utils/utils';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import SignInGoogle from '../SignInGoogle';
import { toast } from 'sonner';

const formSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(8, {
      message: 'Password should be at least 8 characters.',
    })
    .max(50),
});
export function SigninForm() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    reset,
    formState: { isSubmitting },
    setError,
  } = form;

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    try {
      const error = await login(values.email, values.password);
      console.log({ error });

      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.error(error);
      toast.error(error);
      reset();
    }
  }
  return (
    <div className={'flex flex-col gap-6'}>
      <div className="flex flex-col items-center gap-2 text-center">
        <h1 className="text-2xl font-bold">{t('login.headline')}</h1>
        <p className="text-balance text-sm text-muted-foreground">{t('login.subheadline')}</p>
      </div>
      <div className="grid gap-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className="flex flex-col gap-6">
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('general.form.email')}</FormLabel>
                      <FormControl>
                        <Input placeholder="m@example.com" {...field} />
                      </FormControl>
                      {/* <FormDescription>This is your public display name.</FormDescription> */}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center">
                        <FormLabel>{t('general.form.password')}</FormLabel>
                        <Link
                          to={PATH_AUTH.resetPassword}
                          className="ml-auto inline-block text-sm underline-offset-4 hover:underline"
                        >
                          {t('login.forgotPassword')}
                        </Link>
                      </div>
                      <FormControl>
                        <Input type="password" placeholder="*******" {...field} />
                      </FormControl>
                      {/* <FormDescription>This is your public display name.</FormDescription> */}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button
                type="submit"
                className="w-full"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('login.button')}
              </Button>
            </div>
          </form>
        </Form>
        <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
          <span className="relative z-10 bg-background px-2 text-muted-foreground">
            Or continue with
          </span>
        </div>
        <SignInGoogle />
      </div>
      <div className="text-center text-sm">
        {t('login.dontHaveAccount')}{' '}
        <Link to={PATH_AUTH.register} className="underline underline-offset-4">
          {t('login.getStarted')}
        </Link>
      </div>
    </div>
  );
}
