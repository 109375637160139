import React, { useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import Iconify from '@simplytech/simply.general.iconify';
import Menu from '@mui/material/Menu';
import { SubTitle } from 'src/components/subtitles';
import { jsonToSrt } from '../../utils/captions';
import { Functions } from 'src/contexts/FirebaseContext';
import { httpsCallable } from 'firebase/functions';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function DownloadMenu({
  videoUrl,
  subtitles,
  reviewId,
  isRenderInprogress,
}: {
  videoUrl: string;
  subtitles: SubTitle[];
  reviewId: string;
  isRenderInprogress: boolean;
}) {
  const [isRendering, setisRendering] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const exportData = () => {
    if (!subtitles || subtitles?.length < 1) {
      enqueueSnackbar(t('videoCustomisation.export.noSubtitles'), { variant: 'error' });
      return;
    }
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(jsonToSrt(subtitles))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'subtitles.srt';
    link.click();
  };

  const renderVideo = async () => {
    try {
      if (!workspaceId || !reviewId) {
        throw new Error('WorkspaceId or reviewId is missing');
      }
      if (!subtitles || subtitles?.length < 1) {
        enqueueSnackbar(t('videoCustomisation.export.noSubtitles'), { variant: 'error' });
        return;
      }
      setisRendering(true);
      enqueueSnackbar(t('videoCustomisation.rendering.start'), { variant: 'info' });
      const functionRef = httpsCallable(Functions, 'renderVideo');
      const response: any = await functionRef({
        reviewId,
        workspaceId,
        videoUrl,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
        return;
      }
      enqueueSnackbar(t('videoCustomisation.rendering.success'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setisRendering(false);
    }
  };

  return (
    <>
      <IconButton size="large" onClick={handleClick}>
        <Iconify icon="material-symbols:download" />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => window.open(videoUrl, '_blank')}>
          {t('videoCustomisation.export.downloadVideo')}
        </MenuItem>

        <MenuItem onClick={exportData}>{t('videoCustomisation.export.subtitles')}</MenuItem>

        <MenuItem onClick={renderVideo} disabled={isRendering || isRenderInprogress}>
          {t('videoCustomisation.export.videoWithSubtitles')}
        </MenuItem>
      </Menu>
    </>
  );
}
