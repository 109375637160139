import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Iconify from 'src/components/Iconify';
import useInviteActivity, { INVITE_ACTIVITY_TYPE } from 'src/hooks/useInviteActivity';
import { RequestState } from 'src/enum';
import { CircularProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useInviteResendReminders, { InviteResendReminder } from 'src/hooks/useInviteResendReminders';

function ActivityItem({ type, createTime }: { type: INVITE_ACTIVITY_TYPE; createTime: Date }) {
  const { t } = useTranslation();
  const theme = useTheme();
  switch (type) {
    case INVITE_ACTIVITY_TYPE.EMAIL:
      return (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('general.dateToNow', { date: createTime })}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <Iconify icon="ic:round-email" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('invites.drawer.activity.email')}
          </TimelineContent>
        </TimelineItem>
      );
    case INVITE_ACTIVITY_TYPE.EMAIL_REMINDER:
      return (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('general.dateToNow', { date: createTime })}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <Iconify icon="ic:round-email" />
              <Iconify
                icon="pajamas:retry"
                color={theme.palette.primary.main}
                sx={{
                  position: 'absolute',
                  ml: -1.25,
                  mt: -1.25,
                  stroke: theme.palette.common.white,
                  strokeWidth: 0.5,
                }}
                fontSize={36}
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('invites.drawer.activity.emailReminder')}
          </TimelineContent>
        </TimelineItem>
      );
    case INVITE_ACTIVITY_TYPE.LINK:
      return (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('general.dateToNow', { date: createTime })}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <Iconify icon="akar-icons:link-chain" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{ display: 'flex', alignItems: 'center' }}
            color="text.secondary"
            variant="body2"
          >
            {t('invites.drawer.activity.link')}
          </TimelineContent>
        </TimelineItem>
      );
    case INVITE_ACTIVITY_TYPE.SMS:
      return (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('general.dateToNow', { date: createTime })}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            <TimelineDot color="secondary">
              <Iconify icon="fa6-solid:comment-sms" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('invites.drawer.activity.sms')}
          </TimelineContent>
        </TimelineItem>
      );
    case INVITE_ACTIVITY_TYPE.SMS_REMINDER:
      return (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('general.dateToNow', { date: createTime })}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            <TimelineDot color="secondary">
              <Iconify icon="fa6-solid:comment-sms" />
              <Iconify
                icon="pajamas:retry"
                color={theme.palette.secondary.main}
                sx={{
                  position: 'absolute',
                  ml: -1.25,
                  mt: -1.25,
                  stroke: theme.palette.common.white,
                  strokeWidth: 0.5,
                }}
                fontSize={36}
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('invites.drawer.activity.smsReminder')}
          </TimelineContent>
        </TimelineItem>
      );
    case INVITE_ACTIVITY_TYPE.REVIEW_RECEIVED:
      return (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('general.dateToNow', { date: createTime })}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            <TimelineDot color="secondary">
              <Iconify icon="carbon:star-review" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {t('invites.drawer.activity.reviewed')}
          </TimelineContent>
        </TimelineItem>
      );
    default:
      return null;
  }
}

export default function InviteActivity({ inviteId }: { inviteId: string | null }) {
  const { requestState, activities } = useInviteActivity(inviteId);
  const { requestState: reminderStatus, reminders } = useInviteResendReminders(inviteId);

  if (RequestState.PENDING === requestState) return <CircularProgress />;
  return (
    <Timeline>
      {activities.map((activity) => (
        <ActivityItem key={activity.id} type={activity.type} createTime={activity.createTime} />
      ))}
      {reminders.map((reminder) => (
        <ReminderItem reminder={reminder} key={reminder.id} />
      ))}
    </Timeline>
  );
}

function ReminderItem({ reminder }: { reminder: InviteResendReminder }) {
  const { t } = useTranslation();
  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {t('general.dateToNow', { date: reminder.createTime })}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'grey.200' }} />
        <TimelineDot color="grey">
          <CircularProgress size={16} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
        {t('invites.drawer.reminder.pending')}
      </TimelineContent>
    </TimelineItem>
  );
}
