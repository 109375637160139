import { Box, MenuItem, Tooltip } from '@mui/material';
import { useSelector } from '../../../redux/store';
import { getSlugSelector } from '../../../redux/slices/publicConfig';
import Iconify from '../../../components/Iconify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { ROOTS } from '../../../enum';
import { useSnackbar } from 'notistack';
import { selectPlatform } from '../../../redux/slices/userSlice';
import { Platforms } from '../../../redux/slices/platform';
import { inviteLinkRoot } from 'src/utils/link';
import MenuPopover from 'src/components/MenuPopover';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import ProductButton from 'src/components/buttons/ProductButton';
import { LEADS, LINKS, REVIEW } from 'src/theme/palette';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import SimplyLinksIcon from 'src/assets/Logo/SimplyLinksIcon';
import {
  selectWorkspaceLeadsHasWorkspaces,
  selectWorkspaceLinksHasWorkspaces,
  selectWorkspaceReviewHasWorskpaces,
} from 'src/redux/selectors/workspaces';
import { Button } from 'src/components/ui/button';

// ----------------------------------------------------------------------

export default function Slug() {
  const { t } = useTranslation();
  const slug = useSelector(getSlugSelector);
  const platform = useSelector(selectPlatform);
  const hasReview = useSelector(selectWorkspaceReviewHasWorskpaces);
  const hasLink = useSelector(selectWorkspaceLinksHasWorkspaces);
  const hasLeads = useSelector(selectWorkspaceLeadsHasWorkspaces);
  const reviewUrl = `https://app.simplyreview.com/r`;
  const leadsUrl = `https://app.simplyleads.io/l`;
  const linksUrl = `https://simplylink.io`;
  const { enqueueSnackbar } = useSnackbar();

  const onCopy = () => {
    if (slug) {
      enqueueSnackbar(t('general.copied'));
    }
  };
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  // if (platform === Platforms.SIMPLY_REVIEW) {
  return (
    <Box>
      {/* <CopyToClipboard text={reviewUrl} onCopy={onCopy}> */}
      <Button variant="ghost" onClick={handleOpen}>
        /{slug}
        <Iconify icon="eva:copy-fill" />
      </Button>
      {/* </CopyToClipboard> */}
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 'inherit',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <Tooltip
            title={!hasReview ? t('general.workspace.setup.required.link') : t('general.copy')}
            placement="right"
          >
            <div>
              <CopyToClipboard text={`${reviewUrl}/${slug}`} onCopy={onCopy}>
                <MenuItem
                  key="review"
                  onClick={() => handleClose()}
                  sx={{ width: '100%' }}
                  disabled={!hasReview}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ProductButton
                      variant="contained"
                      size="small"
                      backgroundColor={REVIEW.darker}
                      disableElevation
                      sx={{
                        maxWidth: 28,
                        minWidth: 28,
                        height: 28,
                        backgroundColor: 'common.black',
                        boxShadow: 'none',
                        borderRadius: 0.75,
                      }}
                    >
                      <SimplyReviewIcon width={8} color={REVIEW.lighter} />
                    </ProductButton>
                    <Typography variant="body2">
                      {reviewUrl.replace('https://', '')}
                      <span style={{ fontWeight: 'bold' }}>/{slug}</span>
                    </Typography>
                  </Stack>
                </MenuItem>
              </CopyToClipboard>
            </div>
          </Tooltip>
          <Tooltip
            title={!hasLeads ? t('general.workspace.setup.required.link') : t('general.copy')}
            placement="right"
          >
            <div>
              <CopyToClipboard text={`${leadsUrl}/${slug}`} onCopy={onCopy}>
                <MenuItem
                  key="review"
                  onClick={() => handleClose()}
                  sx={{
                    width: '100%',
                  }}
                  disabled={!hasLeads}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ProductButton
                      variant="contained"
                      size="small"
                      backgroundColor={LEADS.darker}
                      disableElevation
                      sx={{
                        maxWidth: 28,
                        minWidth: 28,
                        height: 28,
                        backgroundColor: 'common.black',
                        boxShadow: 'none',
                        borderRadius: 0.75,
                        p: 0.8,
                      }}
                    >
                      <SimplyLeadsIcon width={18} color={LEADS.light} />
                    </ProductButton>
                    <Typography variant="body2">
                      {leadsUrl.replace('https://', '')}
                      <span style={{ fontWeight: 'bold' }}>/{slug}</span>
                    </Typography>
                  </Stack>
                </MenuItem>
              </CopyToClipboard>
            </div>
          </Tooltip>
          <Tooltip
            title={!hasLink ? t('general.workspace.setup.required.link') : t('general.copy')}
            placement="right"
          >
            <div>
              <CopyToClipboard text={`${linksUrl}/${slug}`} onCopy={onCopy}>
                <MenuItem
                  key="links"
                  onClick={() => handleClose()}
                  sx={{ width: '100%' }}
                  disabled={!hasLink}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ProductButton
                      variant="contained"
                      size="small"
                      backgroundColor={LINKS.darker}
                      disableElevation
                      sx={{
                        minWidth: 20,
                        width: 28,
                        height: 28,
                        backgroundColor: 'common.black',
                        boxShadow: 'none',
                        borderRadius: 0.75,
                        p: 0.8,
                      }}
                    >
                      <SimplyLinksIcon width={12} color={LINKS.light} />
                    </ProductButton>
                    <Typography variant="body2">
                      {linksUrl.replace('https://', '')}
                      <span style={{ fontWeight: 'bold' }}>/{slug}</span>
                    </Typography>
                  </Stack>
                </MenuItem>
              </CopyToClipboard>
            </div>
          </Tooltip>
        </Stack>
      </MenuPopover>
    </Box>
  );
  // }
  // if (platform === Platforms.SIMPLY_LEADS) {
  //   return (
  //     <Box>
  //       {/* <CopyToClipboard text={leadsUrl} onCopy={onCopy}> */}
  //       <Button endIcon={<Iconify icon="eva:copy-fill" />}>/{slug}</Button>
  //       {/* </CopyToClipboard> */}
  //     </Box>
  //   );
  // }
  // if (platform === Platforms.SIMPLY_LINKS) {
  //   return (
  //     <Box>
  //       {/* <CopyToClipboard text={linksUrl} onCopy={onCopy}> */}
  //       <Button endIcon={<Iconify icon="eva:copy-fill" />}>/{slug}</Button>
  //       {/* </CopyToClipboard> */}
  //     </Box>
  //   );
  // }
  return null;
}
