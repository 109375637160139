import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TextField, IconButton, Tooltip } from '@mui/material';
import Iconify from 'src/components/Iconify';
import Accordion from '@mui/material/Accordion';
import UploadSquareLogo from 'src/components/upload/UploadSquarelogo';
import { Stack } from '@mui/material';
import { styled } from '@mui/material';
import { Card } from '@mui/material';
import VisibilitySwitch from 'src/components/customSwitch/visibility';
import { getLinkTypeConfig } from '.';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import Image from 'src/components/Image';
import { LinkType } from 'src/redux/slices/publicConfig';
import i18next from 'i18next';

// // fake data generator
// const getItems = (count: number) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `Question ${k} blablbabla`,
//   }));

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  display: 'flex',
  alignItems: 'center',
  margin: `0 0 ${grid}px 0`,

  opacity: isDragging ? 0.5 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // padding: grid,
  // width: 250,
  width: '100%',
});

export default class DraggableLinks extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.props.initialItems, result.source.index, result.destination.index);

    this.props.onChange(items);
  }

  onRemove(itemToReomve: any) {
    const items = this.props.initialItems.filter((item: any) => item.id !== itemToReomve.id);
    this.props.onChange(items);
  }

  onDrop = (acceptedFiles: any[], item: any) => {
    const file = acceptedFiles[0];

    if (file) {
      const items = this.props.initialItems.map((it: any) => {
        if (it.id === item.id) {
          return {
            ...it,
            thumbnail: Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          };
        }
        return it;
      });

      this.props.onChange(items);

      // setavatar(URL.createObjectURL(file));
    }
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.initialItems.map((item: any, index: number) => {
                const linkConfig = getLinkTypeConfig(item.type);
                const isSimply = [
                  LinkType.LEADS_PAGE,
                  LinkType.REVIEW_PAGE,
                  LinkType.REVIEW_COMPANY_PAGE,
                ].includes(item.type);
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        // style={{ display: 'flex' }}
                      >
                        <div
                          {...provided.dragHandleProps}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Iconify
                            icon="ic:baseline-drag-indicator"
                            color="grey.400"
                            sx={{ height: 24, width: 24 }}
                          />
                        </div>
                        <Card sx={{ width: '100%', p: 2 }}>
                          <Stack spacing={1} direction="row">
                            <Stack flexGrow={1} spacing={1}>
                              <Stack direction="row" spacing={1} width="100%" alignItems="center">
                                {item.type === LinkType.CUSTOM && (
                                  <UploadSquareLogo
                                    accept={{
                                      'image/*': [],
                                    }}
                                    file={item.thumbnail?.preview || item.thumbnail}
                                    onDrop={(acceptedFiles) => this.onDrop(acceptedFiles, item)}
                                  />
                                )}
                                {item.type === LinkType.REVIEW_COMPANY_PAGE && item?.thumbnail && (
                                  <Tooltip title={i18next.t('links.customise.companyPage.tooltip')}>
                                    <Stack
                                      alignItems="center"
                                      justifyContent="center"
                                      sx={{
                                        width: 57,
                                        height: 50,
                                        borderRadius: 1,
                                      }}
                                    >
                                      <Image src={item.thumbnail} sx={{ borderRadius: 1 }} />
                                    </Stack>
                                  </Tooltip>
                                )}
                                {item.type === LinkType.REVIEW_COMPANY_PAGE && !item?.thumbnail && (
                                  <Tooltip title={i18next.t('links.customise.companyPage.tooltip')}>
                                    <Stack
                                      alignItems="center"
                                      justifyContent="center"
                                      sx={{
                                        backgroundColor: 'grey.400',
                                        width: 57,
                                        height: 50,
                                        borderRadius: 1,
                                      }}
                                    >
                                      <Image src={item.thumbnail} />
                                    </Stack>
                                  </Tooltip>
                                )}
                                {item.type === LinkType.REVIEW_PAGE && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                      background: linkConfig.background,
                                      width: 57,
                                      height: 50,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <SimplyReviewIcon color="#161615" width={14} />
                                  </Stack>
                                )}
                                {item.type === LinkType.LEADS_PAGE && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                      background: linkConfig.background,
                                      width: 57,
                                      height: 50,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <SimplyLeadsIcon color="#161615" width={24} />
                                  </Stack>
                                )}
                                {!isSimply && item.type !== LinkType.CUSTOM && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                      background: linkConfig.background,
                                      width: 57,
                                      height: 50,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <Iconify
                                      icon={linkConfig.icon}
                                      color="white"
                                      height={28}
                                      width={28}
                                    />
                                  </Stack>
                                )}
                                <TextField
                                  size="small"
                                  fullWidth
                                  placeholder={
                                    i18next.t('links.customise.linkTitlePlaceholder') as string
                                  }
                                  // "Name your link..."
                                  value={item.title}
                                  onChange={(e) => {
                                    const items = this.props.initialItems.map((it: any) => {
                                      if (it.id === item.id) {
                                        return {
                                          ...it,
                                          title: e.target.value,
                                        };
                                      }
                                      return it;
                                    });

                                    this.props.onChange(items);
                                  }}
                                />
                              </Stack>
                              {/* <Typography>Share your experience with me</Typography> */}
                              <Stack>
                                <TextField
                                  size="small"
                                  fullWidth
                                  disabled={isSimply}
                                  placeholder={
                                    i18next.t('links.customise.linkPlaceholder') as string
                                  }
                                  value={item.link}
                                  onChange={(e) => {
                                    const items = this.props.initialItems.map((it: any) => {
                                      if (it.id === item.id) {
                                        return {
                                          ...it,
                                          link: e.target.value,
                                        };
                                      }
                                      return it;
                                    });

                                    this.props.onChange(items);
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack alignItems="center" justifyContent="center">
                              <VisibilitySwitch
                                checked={item.visible}
                                onChange={(e: any) =>
                                  this.props.onToggle(item.id, e.target.checked)
                                }
                              />
                            </Stack>
                          </Stack>
                        </Card>
                        <IconButton
                          sx={{ display: isSimply ? 'none' : 'inherit' }}
                          onClick={() => this.onRemove(item)}
                        >
                          <Iconify icon="ic:round-delete-outline" color="grey.400" />
                        </IconButton>
                        {/* {item.content} */}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '::before': {
    display: 'none',
  },
  '& > .Mui-focusVisible': {
    backgroundColor: 'transparent !important',
  },
  boxShadow: theme.customShadows.card,
  borderRadius: 8,
}));

// import * as React from 'react';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import Iconify from 'src/components/Iconify';

// export default function LinksList() {
//   return (
//     <div>
//       <Accordion>
//         <AccordionSummary
//           expandIcon={<Iconify icon="ic:round-expand-more" />}
//           aria-controls="panel1a-content"
//           id="panel1a-header"
//         >
//           <Typography>Share your experience with me</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//             malesuada lacus ex, sit amet blandit leo lobortis eget.
//           </Typography>
//         </AccordionDetails>
//       </Accordion>
//       <Accordion>
//         <AccordionSummary
//           expandIcon={<Iconify icon="ic:round-expand-more" />}
//           aria-controls="panel2a-content"
//           id="panel2a-header"
//         >
//           <Typography>Get in touch</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//             malesuada lacus ex, sit amet blandit leo lobortis eget.
//           </Typography>
//         </AccordionDetails>
//       </Accordion>
//       <Accordion disabled>
//         <AccordionSummary
//           expandIcon={<Iconify icon="ic:round-expand-more" />}
//           aria-controls="panel3a-content"
//           id="panel3a-header"
//         >
//           <Typography>Disabled Accordion</Typography>
//         </AccordionSummary>
//       </Accordion>
//     </div>
//   );
// }
