import React from 'react';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { Button } from 'src/components/ui/button';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';

export default function SetupLeads() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const onSetupLeadsWorkspace = async () => {
    try {
      setLoading(true);
      const functionRef = httpsCallable(Functions, 'settings');
      const response: any = await functionRef({
        request: 'SETUP_LEADS_WORKSPACE',
      });

      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        toast.error(t('general.error.default'));
        return;
      }

      toast.success(t('leads.setup.workspace.success'));
      // window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(t('general.error.default'));
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button size="lg" onClick={onSetupLeadsWorkspace} loading={loading}>
      {t('flow.initial.startTrial')}
    </Button>
  );
}
