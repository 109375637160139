import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Button, Typography, Box, Stack, Switch } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import { PriceId } from '../../enum';
import Subscribe from '../../stripe/components/Subscribe';
import ManageSubscription from '../../stripe/components/ManageSubscription';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import { selectIsSuperOwner } from 'src/redux/slices/userSlice';
import { hasLeadsPremiumSelector } from 'src/redux/slices/workspaceLeadsSlice';
import {
  StripeSubscriptionStatus,
  selectHasStripeLoaded,
  selectLatestBundleSubscription,
  selectLatestLeadsSubscription,
  selectLatestReviewSubscription,
  selectLeadsSubscriptions,
} from 'src/redux/slices/stripeSlice';
import { LoadingButton } from '@mui/lab';
import { FLOW, LEADS, LINKS, REVIEW, SITE } from 'src/theme/palette';
import ProductButton from 'src/components/buttons/ProductButton';
import SimplyReviewIcon from 'src/assets/Logo/SimplyReviewIcon';
import SimplyFlowIcon from 'src/assets/Logo/SimplyFlowIcon';
import SimplyLinksIcon from 'src/assets/Logo/SimplyLinksIcon';
import SimplyLeadsIcon from 'src/assets/Logo/SimplyLeadsIcon';
import SimplySiteIcon from 'src/assets/Logo/SimplySiteIcon';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5),
  },
}));

const lists = [
  { text: 'pricing.premiumleads.item1', isAvailable: true },
  { text: 'pricing.premiumleads.item2', isAvailable: true },
  { text: 'pricing.premiumleads.item3', isAvailable: true },
  { text: 'pricing.premiumleads.item4', isAvailable: true },
  { text: 'pricing.premiumleads.item5', isAvailable: true },
  { text: 'pricing.premiumleads.item6', isAvailable: true },
];

// ----------------------------------------------------------------------

type Props = {
  index: number;
  isBillingPage?: boolean;
};

export default function PricingPlanCardBundle() {
  const { user } = useAuth();
  const [monthly, setMonthly] = useState(true);
  const { t } = useTranslation();
  const isSuperOwner = useSelector(selectIsSuperOwner);
  const stripeHasLoaded = useSelector(selectHasStripeLoaded);
  const hasSubscription = useSelector(selectLeadsSubscriptions);
  const bundleSub = useSelector(selectLatestBundleSubscription);

  const togglePrice = () => {
    setMonthly((prev) => !prev);
  };

  const latestSubscriptionIsCancelled = bundleSub?.status === StripeSubscriptionStatus.Canceled;

  const renderSubscription = () => {
    if (!stripeHasLoaded) {
      return <LoadingButton loading size="large" fullWidth variant="contained" />;
    }
    if (bundleSub?.status) {
      return <ManageSubscription />;
    }
    // if (!isSuperOwner) {
    //   return (
    //     <Button variant="contained" disabled fullWidth size="large">
    //       Only the owner can upgrade billing
    //     </Button>
    //   );
    // }
    // if (bundleSub?.) {
    //   return (
    //     <LoadingButton size="large" disabled fullWidth variant="contained">
    //       Seems like you got this one for free, lucky you
    //     </LoadingButton>
    //   );
    // }
    // only allow if not premium and no subscription or latest subscription is cancelled

    return (
      <Button variant="contained" disabled fullWidth size="large">
        Reach out in the support chat to get on the bundle
      </Button>
    );
  };

  console.log({ bundleSub });
  if (!bundleSub) return null;
  return (
    <RootStyle>
      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        Simply Bundle
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t('general.yearly')}</Typography>
        <Switch
          checked={monthly}
          onChange={togglePrice}
          inputProps={{ 'aria-label': 'ant design' }}
        />
        <Typography>{t('general.monthly')}</Typography>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          €
        </Typography>

        <Typography variant="h2" sx={{ mx: 1 }}>
          <span>{monthly ? 59 : 49}</span>
        </Typography>
        <Typography
          gutterBottom
          component="span"
          variant="subtitle2"
          sx={{
            alignSelf: 'flex-end',
            color: 'text.secondary',
          }}
        >
          /{t('general.date.month.short')}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {!monthly ? (
          `(${t('pricing.premium.priceSubText')})`
        ) : (
          <span style={{ visibility: 'hidden' }}>_</span>
        )}
      </Typography>
      {/* <Box sx={{ width: 80, height: 80, mt: 3 }}>
        <PlanPremiumIcon />
      </Box> */}
      <Stack>
        <Typography variant="overline" sx={{ color: 'text.secondary' }}>
          Includes
        </Typography>
      </Stack>
      <Stack component="ul" spacing={1.5} sx={{ my: 2, mb: 3, width: 1 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <ProductButton
            variant="contained"
            size="small"
            backgroundColor={REVIEW.darker}
            disableElevation
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow: 'none',
            }}
          >
            <SimplyReviewIcon color={REVIEW.lighter} />
          </ProductButton>
          <Stack>
            <Typography variant="body1">Simply Review</Typography>
            <Typography variant="body2" color="text.secondary">
              Normal price €21 per month
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <ProductButton
            variant="contained"
            size="small"
            backgroundColor={LEADS.darker}
            disableElevation
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow: 'none',
            }}
          >
            <SimplyLeadsIcon width={18} color={LEADS.light} />
          </ProductButton>
          <Stack>
            <Typography variant="body1">Simply leads</Typography>
            <Typography variant="body2" color="text.secondary">
              Normal price €9 per month
            </Typography>
          </Stack>{' '}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <ProductButton
            variant="contained"
            size="small"
            backgroundColor={FLOW.darker}
            disableElevation
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow: 'none',
            }}
          >
            <SimplyFlowIcon width={18} color={FLOW.light} />
          </ProductButton>
          <Stack>
            <Typography variant="body1">Simply Flow</Typography>
            <Typography variant="body2" color="text.secondary">
              Normal price €9 per month
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <ProductButton
            variant="contained"
            size="small"
            backgroundColor={LINKS.darker}
            disableElevation
            sx={{
              minWidth: 40,
              height: 40,
              backgroundColor: 'common.black',
              boxShadow: 'none',
            }}
          >
            <SimplyLinksIcon width={18} color={LINKS.light} />
          </ProductButton>
          <Stack>
            <Typography variant="body1">Simply Links</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <ProductButton
              variant="contained"
              size="small"
              backgroundColor={SITE.darker}
              disableElevation
              sx={{
                minWidth: 40,
                height: 40,
                backgroundColor: 'common.black',
                boxShadow: 'none',
              }}
            >
              <SimplySiteIcon width={20} color={SITE.light} />
            </ProductButton>

            <Stack>
              <Typography variant="body1">Simply Site</Typography>
              <Typography variant="body2" color="text.secondary">
                Normal price €30 per month
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="body2" color="text.secondary" justifySelf="flex-end">
            (only with bundle)
          </Typography>
        </Stack>
      </Stack>
      <Box flexGrow={1} />
      {renderSubscription()}
    </RootStyle>
  );
}

//   return (
//     <Card sx={{ p: 3 }}>
//       <Stack direction="row" alignItems="center" spacing={3}>
//         <Stack spacing={0.5}>
//           <Stack direction="row" spacing={0.5}>
//             <ProductButton
//               variant="contained"
//               size="small"
//               backgroundColor={REVIEW.darker}
//               disableElevation
//               sx={{
//                 minWidth: 40,
//                 height: 40,
//                 backgroundColor: 'common.black',
//                 boxShadow: 'none',
//               }}
//             >
//               <SimplyReviewIcon color={REVIEW.lighter} />
//             </ProductButton>
//             <ProductButton
//               variant="contained"
//               size="small"
//               backgroundColor={LINKS.darker}
//               disableElevation
//               sx={{
//                 minWidth: 40,
//                 height: 40,
//                 backgroundColor: 'common.black',
//                 boxShadow: 'none',
//               }}
//             >
//               <SimplyLinksIcon width={18} color={LINKS.light} />
//             </ProductButton>
//           </Stack>
//           <Stack direction="row" spacing={0.5}>
//             <ProductButton
//               variant="contained"
//               size="small"
//               backgroundColor={LEADS.darker}
//               disableElevation
//               sx={{
//                 minWidth: 40,
//                 height: 40,
//                 backgroundColor: 'common.black',
//                 boxShadow: 'none',
//               }}
//             >
//               <SimplyLeadsIcon width={18} color={LEADS.light} />
//             </ProductButton>
//             <ProductButton
//               variant="contained"
//               size="small"
//               backgroundColor={FLOW.darker}
//               disableElevation
//               sx={{
//                 minWidth: 40,
//                 height: 40,
//                 backgroundColor: 'common.black',
//                 boxShadow: 'none',
//               }}
//             >
//               <SimplyFlowIcon width={18} color={FLOW.light} />
//             </ProductButton>
//           </Stack>
//         </Stack>
//         <Stack>
//           <Typography variant="h2">Simply Bundle</Typography>
//           <Typography variant="body1">
//             Includes all simply products, reach out to us in the support chat to go bundle 🥳
//           </Typography>
//         </Stack>
//       </Stack>
//     </Card>
//   );
