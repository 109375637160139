import { useEffect, useRef, useState } from 'react';
import { Message } from 'src/hooks/useMessages';
import { ExpandableChatBody } from 'src/components/ui/chat/expandable-chat';
import { ChatMessageList } from 'src/components/ui/chat/chat-message-list';
import { useSelector } from 'src/redux/store';
import {
  ChatBubble,
  ChatBubbleAvatar,
  ChatBubbleMessage,
} from 'src/components/ui/chat/chat-bubble';
import { Dialog, DialogContent } from 'src/components/ui/dialog';
import { formatMessageDate } from '../support/formatChatTime';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { Loader2 } from 'lucide-react';

export default function SupportChatMessages({ messages }: { messages: Message[] }) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const userId = useSelector((state) => state.user.id);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [messages]);
  return (
    <>
      <ExpandableChatBody>
        <ChatMessageList ref={scrollRef} className="dark:bg-muted/40 gap-1">
          {messages
            // .sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime())
            .map((m, i) => {
              const isMe = m.sender.uid === userId;
              // check if previous message is same sender
              // Same sender as next message
              // Check if previous message was from same sender
              const isNextSameSender =
                i < messages.length - 1 && m.sender.uid === messages[i + 1].sender.uid;

              // Check time difference with next message
              const nextTimeDiff =
                i < messages.length - 1
                  ? new Date(messages[i + 1].createTime).getTime() -
                    new Date(m.createTime).getTime()
                  : Infinity;

              // Show timestamp if either:
              // - First message in sequence
              // - More than 5 minutes passed since last message
              const shouldShowTimestamp = !isNextSameSender || nextTimeDiff >= 300000;

              return (
                <>
                  <ChatBubble
                    variant={isMe ? 'sent' : 'received'}
                    timestamp={shouldShowTimestamp ? formatMessageDate(m.createTime) : ''}
                  >
                    {shouldShowTimestamp && !isMe ? (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div>
                            <ChatBubbleAvatar
                              className="h-5 w-5 text-xs"
                              src={m.sender?.photoURL || undefined}
                              fallback={(m.sender.displayName?.[0] || '')?.toUpperCase()}
                            />
                          </div>
                        </TooltipTrigger>

                        <TooltipContent side="left">
                          <p>{m.sender.displayName}</p>
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      !isMe && <div className="pl-5" />
                    )}
                    {m.image?.thumbnail ? (
                      <div
                        className="cursor-pointer"
                        role="button"
                        onClick={() =>
                          setSelectedImage(m.image?.original || (m.image?.thumbnail as string))
                        }
                      >
                        <img
                          src={m.image.thumbnail}
                          alt="attachment"
                          className="w-40 h-40 object-cover rounded-lg"
                        />
                      </div>
                    ) : (
                      <ChatBubbleMessage>
                        {m.image && !m.image?.thumbnail && (
                          <Loader2 className="w-5 h-5 animate-spin" />
                        )}
                        {m.message}
                      </ChatBubbleMessage>
                    )}
                  </ChatBubble>
                  {/* <div>
                    <p className="text-xs text-gray-500">{formatMessageDate(m.createTime)}</p>
                  </div> */}
                </>
              );
            })}
        </ChatMessageList>
      </ExpandableChatBody>
      <Dialog open={Boolean(selectedImage)} onOpenChange={() => setSelectedImage(null)}>
        <DialogContent className="sm:max-w-[425px]">
          {selectedImage && (
            <img src={selectedImage} alt="attachment" className="w-full h-full object-contain" />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
