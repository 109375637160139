import { GalleryVerticalEnd } from 'lucide-react';
import { SignupForm } from 'src/sections/auth/register/SignupForm';
import signin from '../../assets/signin.jpg';
import ingela from '../../assets/ingela.png';

export default function SignupPage() {
  return (
    <div className="grid min-h-svh lg:grid-cols-2">
      <div className="flex flex-col gap-4 p-6 md:p-10">
        <div className="flex justify-center gap-2 md:justify-start">
          <a href="#" className="flex items-center gap-2 font-medium">
            <div className="flex h-6 w-6 items-center justify-center rounded-md bg-primary text-primary-foreground">
              <GalleryVerticalEnd className="size-4" />
            </div>
            Simply
          </a>
        </div>
        <div className="flex flex-1 items-center justify-center">
          <div className="w-full max-w-sm">
            <SignupForm />
          </div>
        </div>
      </div>
      <div className="relative hidden bg-muted lg:block">
        <img
          src={signin}
          alt="logo"
          className="absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
        <div className="flex flex-col p-8 bottom-0 z-1 absolute w-full items-center gap-4">
          <p className="text-l max-w-md text-center text-muted-foreground">
            It's never been easier for me to collect reviews from my customers. It's so easy to use,
            so easy to get started and it looks professional, clean and nice.
          </p>
          <div className="flex flex-row gap-2">
            <img src={ingela} alt="ingela" className="w-8" />
            <div className="flex flex-col justify-center ">
              <h1 className="text-balance text-xs text-muted-foreground">Ingela</h1>
              <p className="text-balance text-xs text-muted-foreground">Health & Nutrition Coach</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
