import { httpsCallable } from 'firebase/functions';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';
import { Functions } from 'src/contexts/FirebaseContext';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { useSelector } from 'src/redux/store';
import { ConfirmType, TeamMemberManager } from './TeamMember';

export function ConfirmDeleteMember({
  member,
  refetch,
  onClose,
}: {
  member: TeamMemberManager | null;
  refetch: () => void;
  onClose: () => void;
}) {
  const [isRemoving, setIsRemoving] = useState(false);
  const { t } = useTranslation();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const handleRemoveMember = async () => {
    try {
      if (!workspaceId || !member) return alert(t('general.error.default'));
      setIsRemoving(true);
      const functionRef = httpsCallable(Functions, 'settings');
      const response: any = await functionRef({
        request: 'DELETE_TEAM_MEMBER',
        member,
        workspaceId,
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        toast.error(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName
        );
      } else {
        toast.success(t('team.remove.member.success'));
        onClose();
        refetch();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsRemoving(false);
    }
  };

  const open = member?.confirmType === ConfirmType.DELETE_TEAM_MEMBER;
  return (
    <AlertDialog open={open} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            You are about to remove {member?.displayName || member?.email} from the workspace. This
            action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleRemoveMember} disabled={isRemoving}>
            {isRemoving && <Loader2 className="animate-spin" />}
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
