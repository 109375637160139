import * as React from 'react';
import { Input } from 'src/components/ui/input';

interface PrefixedInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix: string;
}
export const PrefixedInput = React.forwardRef<HTMLInputElement, PrefixedInputProps>(
  ({ prefix, className, ...props }, ref) => (
    <div className="flex rounded-md shadow-sm">
      <span className="flex select-none items-center px-3 rounded-l-md border border-r-0 border-input bg-muted text-muted-foreground">
        {prefix}
      </span>
      <Input {...props} ref={ref} className={`rounded-none rounded-r-md ${className}`} />
    </div>
  )
);

PrefixedInput.displayName = 'PrefixedInput';
