import { useEffect, useState } from 'react';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { WorkspaceReview } from '../redux/slices/workspaceReviewsSlice';
import { setWorkspaceFlow, setWorkspaceFlowState } from '../redux/slices/workspaceFlowSlice';

export default function useWorkspaceFlow() {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) =>
      state.workspaceFlow.state !== RequestState.RESOLVED &&
      state.workspaceFlow.state !== RequestState.REJECTED
  );
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_FLOW]()),
        where(`members.${userId}.uid`, '==', userId)
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.group('useWorkspaceFlow');
          const workspaceFlow = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as WorkspaceReview[];

          async function setWorkspaceReviewData() {
            dispatch(setWorkspaceFlow({ workspaces: workspaceFlow }));
            console.groupEnd();
          }

          setWorkspaceReviewData();
        },
        (error) => {
          dispatch(setWorkspaceFlowState(RequestState.REJECTED));
          console.error('useWorkspaceFlow', error);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingWorkspaceFlow: isLoading };
}
