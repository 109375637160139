import React, { Dispatch } from 'react';
import { Typography, Collapse, Box, Button, Grid, Stack, Switch, Divider } from '@mui/material';
import { useSelector } from 'src/redux/store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { selectIsOwner } from 'src/redux/slices/userSlice';
import Iconify from 'src/components/Iconify';
import InfoTooltip from 'src/components/InfoTooltip';
import { PATH_DASHBOARD } from '../../routes/paths';
import Android12Switch from 'src/components/customSwitch';

export default function BookingPageOwnerBanner({
  isAdminView,
  toggleView,
}: {
  isAdminView: boolean;
  toggleView: () => void;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isOwner = useSelector(selectIsOwner);
  return (
    <Box
      sx={{
        // position: 'fixed',
        width: '100%',
        zIndex: 1,
        // top: 0,
        backgroundColor: theme.palette.common.white,
        // display: 'flex',
        // justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Collapse in={Boolean(isOwner)}>
        <Stack direction="row" sx={{ p: 2 }} alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Button
              startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />}
              variant="text"
              onClick={() => navigate(PATH_DASHBOARD.general.bookingSchedules)}
            >
              {t('adminbanner.backToDashboard')}
            </Button>

            {isAdminView && (
              <>
                <Typography sx={{ pr: 1, fontSize: 20 }} color={theme.palette.grey[400]}>
                  /
                </Typography>
                <Typography variant="subtitle1">{t('bookingPage.media.header')}</Typography>
                <InfoTooltip title={t('bookingPage.media.tooltip')} />
              </>
            )}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">Live</Typography>
              <Android12Switch
              // disabled={!hasPremium}
              // checked={onlyVideo}
              // onChange={(e: any) => onOnlyVideoChange(e.target.checked)}
              />
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: 30, mx: 1, alignSelf: 'center' }}
              />
            </Stack>

            {isAdminView && <Typography variant="body2">{t('adminbanner.adminView')}</Typography>}
            <Box sx={{ mr: 2 }} />
            <Button
              // startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />}
              variant="contained"
              onClick={toggleView}
            >
              {isAdminView
                ? t('adminbanner.reviewPage.viewAs.guest')
                : t('adminbanner.reviewPage.viewAs.admin')}
            </Button>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
}
