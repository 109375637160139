import { Button } from '../ui/button';
import { ExternalLink } from 'lucide-react';

interface ExternalLinkButtonProps {
  href: string;
  children: React.ReactNode;
}

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ href, children }) => {
  const handleExternalLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(href, '_blank');
  };
  return (
    <Button
      onClick={handleExternalLink}
      variant="ghost" // or "text" for a link style
    >
      <ExternalLink />
      {children}
    </Button>
  );
};

export default ExternalLinkButton;
