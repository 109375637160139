import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { useWorkspaceSettings } from '../context';
import { AppSidebar } from '../sidebar/appSidebar';
import { BreadcrumbWorkspace } from './breadcrumb';
import { TeamMemberTable } from '../TeamMember';
import { useSelector } from 'src/redux/store';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export function WorkspaceSettingsDialog() {
  const { isOpen, closeDialog } = useWorkspaceSettings();
  const currentWorkspaceId = useSelector(selectCurrentWorkspaceId);

  return (
    <Dialog open={isOpen} onOpenChange={closeDialog}>
      {/* <DialogTrigger asChild>
        <Button variant="outline">Share</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-5xl h-4/5 p-0">
        <div className="grid grid-cols-[240px,1fr]">
          <div>
            <AppSidebar />
          </div>
          <div className="pt-4">
            <div className="border-b border-gray-200 pb-4 px-4">
              <BreadcrumbWorkspace workspaceId={currentWorkspaceId} />
            </div>
            <div className="p-4 space-y-4">
              <DialogHeader>
                <DialogTitle>Team members</DialogTitle>
                <DialogDescription>
                  Manage your simply team and how is invited to each product
                </DialogDescription>
              </DialogHeader>
              <TeamMemberTable />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
