import { isAfter } from 'date-fns';
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { DB } from 'src/contexts/FirebaseContext';
import { RequestState } from 'src/enum';
import { useSelector } from 'src/redux/store';
import { convertFStoDate } from 'src/utils/formatTime';

export default function useAppMessages() {
  const latestMessageTime = useSelector((state) => state.onboarding.latestAppMessage);
  const onboardingLoaded = useSelector(
    (state) => state.onboarding.requestState === RequestState.RESOLVED
  );
  const [appMessages, setAppMessages] = React.useState<any[]>([]);
  // use userCreateTime to get messages after user was created

  const userCreateTime = useSelector((state) => state.user.createTime);

  useEffect(() => {
    if (!onboardingLoaded || userCreateTime < 1) return;
    let unsubscribe: () => void;
    const q = query(
      collection(DB, 'appMessages'),
      where('active', '==', true),
      where('createTime', '>', new Date(userCreateTime)),
      orderBy('createTime', 'asc'),
      limit(100)
    );
    unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            createTime: convertFStoDate(data.createTime),
          };
        });

        setAppMessages(messages);
      },
      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, [onboardingLoaded]);

  return appMessages?.filter((m) => isAfter(m.createTime, latestMessageTime));
}
