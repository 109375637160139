import { httpsCallable, httpsCallableFromURL } from 'firebase/functions';
import { groupBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Functions } from 'src/contexts/FirebaseContext';
import { RequestState } from 'src/enum';
import { Platforms } from 'src/redux/slices/platform';
import { TeamInvite } from 'src/redux/slices/teamInviteSlice';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { Member } from 'src/redux/slices/workspaceReviewsSlice';
import { selectWorkspaceAllTeamInvites } from 'src/redux/slices/workspaceTeamInvitesSlice';
import { useSelector } from 'src/redux/store';

export type TeamMember = {
  uid: string | null;
  displayName: string;
  email: string;
  review: Member | null;
  flow: Member | null;
  leads: Member | null;
  reviewDisabled: boolean;
  flowDisabled: boolean;
  leadsDisabled: boolean;
  reviewInvite: TeamInvite | undefined;
  flowInvite: TeamInvite | undefined;
  leadsInvite: TeamInvite | undefined;
};

function getUniqueInvites(invites: TeamInvite[]): TeamMember[] {
  if (invites.length < 1) return [];
  const inviteByEmail = groupBy(invites, (invite) => invite.email);

  return Object.entries(inviteByEmail).map(([email, invites]) => ({
    uid: null,
    displayName: invites[0].displayName,
    email,
    review: null,
    flow: null,
    leads: null,
    reviewInvite: invites.find((invite) => invite.platform === Platforms.SIMPLY_REVIEW),
    flowInvite: invites.find((invite) => invite.platform === Platforms.SIMPLY_FLOW),
    leadsInvite: invites.find((invite) => invite.platform === Platforms.SIMPLY_LEADS),
    reviewDisabled: false,
    flowDisabled: false,
    leadsDisabled: false,
  }));
}

export default function useWorkspaceTeamMembers() {
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const { t } = useTranslation();
  const [data, setData] = useState<TeamMember[]>([]);
  const [request, setRequest] = useState<RequestState>(RequestState.IDLE);
  const invites = useSelector(selectWorkspaceAllTeamInvites);
  const filteredInvites = invites.filter((invite) => !data.find((d) => d.email === invite.email));
  const groupedInvites = getUniqueInvites(filteredInvites);

  const list = useMemo(() => [...data, ...groupedInvites], [data, invites]);

  const getTeam = async () => {
    try {
      if (!workspaceId) return alert(t('general.error.default'));
      setRequest(RequestState.PENDING);
      const functionRef = httpsCallable(Functions, 'settings');
      const response: any = await functionRef({
        request: 'GET_TEAM_MEMBERS',
        workspaceId,
      });

      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        setRequest(RequestState.REJECTED);
        //   enqueueSnackbar(
        //     response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
        //     { variant: 'error' }
        //   );
      } else {
        setData(
          response?.data.workspaceMembers.map((member: TeamMember) => ({
            ...member,
            reviewInvite: invites.find(
              (invite) =>
                invite.email === member.email && invite.platform === Platforms.SIMPLY_REVIEW
            ),
            flowInvite: invites.find(
              (invite) => invite.email === member.email && invite.platform === Platforms.SIMPLY_FLOW
            ),
            leadsInvite: invites.find(
              (invite) =>
                invite.email === member.email && invite.platform === Platforms.SIMPLY_LEADS
            ),
          }))
        );
        setRequest(RequestState.RESOLVED);
        //   enqueueSnackbar(t('team.remove.member.success'), { variant: 'success' });
        //   handleClose();
      }
    } catch (error) {
      console.error(error);
      setRequest(RequestState.REJECTED);
    } finally {
      // setIsRemoving(false);
    }
  };
  useEffect(() => {
    getTeam();
    return () => {};
  }, [workspaceId]);

  return {
    data: list,
    isLoading: request === RequestState.IDLE || request === RequestState.PENDING,
    refetch: getTeam,
  };
}
