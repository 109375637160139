import { useState } from 'react';
import { format } from 'date-fns';
import { IconifyIcon } from '@iconify/react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Avatar,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  IconButton,
  TableContainer,
  Rating,
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import MenuPopover from '../../../../components/MenuPopover';
import useReviews from '../../../../hooks/useReviews';
import { useSelector } from '../../../../redux/store';
// import Lightbox from 'react-image-lightbox';
import SimplyVerifiedAvatar from '../../../../components/SimplyVerifiedAvatar';
import { useTranslation } from 'react-i18next';
import { ReviewType } from '../../../../enum';
import { truncateString } from '../../../../utils/truncate';
import FlatCard from '../../../../components/simply/Cards/FlatCard';

// ----------------------------------------------------------------------

export default function AppRatingTable() {
  useReviews();
  const reviews = useSelector((state) => state.reviews.list?.slice?.(0, 5));
  const theme = useTheme();
  const { t } = useTranslation();

  // const [photoIndex, setPhotoIndex] = useState<number | null>(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const images = reviews.map((r) => r?.image?.path).filter(Boolean) as string[];
  const isLight = theme.palette.mode === 'light';

  return (
    <>
      <FlatCard data-joyride-id="dashboard-recent-reviews">
        <CardHeader title={t('dashboard.review.headline')} sx={{ mb: 3 }} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('general.name')}</TableCell>
                  <TableCell>{t('rating.singular')}</TableCell>
                  <TableCell>{t('review.singular')}</TableCell>
                  <TableCell>{t('review.table.header.date')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {reviews.map((row) => {
                  const isVideoReview = ReviewType.VIDEO === row.type;
                  const videoReviewThumbnail = isVideoReview
                    ? `https://image.mux.com/${row.videoPlaybackId}/thumbnail.png?width=48&fit_mode=preserve`
                    : undefined;
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <SimplyVerifiedAvatar
                            avatar={row?.reviewer?.img}
                            avatarUrl={videoReviewThumbnail}
                            name={row?.reviewer?.name}
                          />
                          <Box sx={{ ml: 2 }}>{row?.reviewer?.name}</Box>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Rating value={row.rating} precision={0.5} readOnly />
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">{row.title}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontStyle: isVideoReview ? 'italic' : 'inherit',
                            color: 'text.secondary',
                          }}
                        >
                          {isVideoReview
                            ? t('review.table.video.description')
                            : truncateString(row.description, 100)}
                        </Typography>
                      </TableCell>

                      <TableCell width={150}>
                        <Typography variant="subtitle2">
                          {
                            // @ts-ignore
                            format(new Date(row.createTime?.toDate()), 'dd MMM yyyy')
                          }
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {
                            // @ts-ignore
                            format(new Date(row.createTime?.toDate()), 'p')
                          }
                        </Typography>
                      </TableCell>

                      {/* <TableCell align="right">
                      <MoreMenuButton />
                    </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 2, textAlign: 'right' }}>
          <Button
            href="/dashboard/reviews"
            size="small"
            color="inherit"
            endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
          >
            {t('general.viewAll')}
          </Button>
        </Box>
      </FlatCard>
      {/* {!!photoIndex && images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex] as string}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setPhotoIndex(null)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )} */}
    </>
  );
}

// ----------------------------------------------------------------------

type AvatarIconProps = {
  icon: IconifyIcon | string;
};

export function AvatarIcon({ icon }: AvatarIconProps) {
  return (
    <Avatar
      sx={{
        width: 48,
        height: 48,
        color: 'text.secondary',
        bgcolor: 'background.neutral',
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Avatar>
  );
}

// ----------------------------------------------------------------------

function renderAvatar(avatar?: string) {
  return avatar ? (
    <Avatar
      alt={'user'}
      src={avatar}
      sx={{ width: 48, height: 48, boxShadow: (theme) => theme.customShadows.z8 }}
    />
  ) : (
    <AvatarIcon icon={'eva:heart-fill'} />
  );
}

// ----------------------------------------------------------------------

function MoreMenuButton() {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:download-fill'} sx={{ ...ICON }} />
          Download
        </MenuItem>

        <MenuItem>
          <Iconify icon={'eva:printer-fill'} sx={{ ...ICON }} />
          Print
        </MenuItem>

        <MenuItem>
          <Iconify icon={'eva:share-fill'} sx={{ ...ICON }} />
          Share
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
