import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { InviteStatus, Subscription, RequestState } from '../../enum';
import keyBy from 'lodash.keyby';
import { Timestamp } from 'firebase/firestore';
import { selectCurrentWorkspaceId } from './userSlice';

export interface Member {
  uid: string;
  email: string;
  displayName: string;
  status: InviteStatus;
}

export interface WorkspaceLinks {
  uid: string;
  id: string;
  companyName?: string;
  members: {
    [key: string]: Member;
  };
  subscription: Subscription;
  trialStart: Timestamp;
  smsLimit: number;
}

// Define a type for the slice state
interface WorkpsaceLinksState {
  state: RequestState;
  workspaceIds: string[];
  workspaceMap: { [key: string]: WorkspaceLinks };
}

// Define the initial state using that type
const initialState: WorkpsaceLinksState = {
  state: RequestState.IDLE,
  workspaceIds: [],
  workspaceMap: {},
};

export const workspaceLinksSlice = createSlice({
  name: 'workspaceLinks',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setWorkspaceLinks: (
      state,
      action: PayloadAction<{ workspaces: WorkspaceLinks[]; current?: string | null }>
    ) => {
      const workspaceMap = keyBy(action.payload.workspaces, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        workspaceIds: action.payload.workspaces.map((workspace) => workspace.id),
        workspaceMap,
        state: RequestState.RESOLVED,
      };
    },
    setWorkspaceLinksState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      state: action.payload,
    }),
  },
});

export const { setWorkspaceLinks, setWorkspaceLinksState } = workspaceLinksSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentWorkspaceMembers = (state: RootState) => {
  const currentWorkspaceId = selectCurrentWorkspaceId(state);
  if (!currentWorkspaceId) return [];
  const currentWorkspace = state.workspaceLinks.workspaceMap[currentWorkspaceId];
  return (
    currentWorkspace?.members
      ? Object.keys(currentWorkspace?.members).map((key) => currentWorkspace?.members[key])
      : []
  ) as Member[];
};

export const selectWorkspaces = (state: RootState) =>
  Object.keys(state.workspaceLinks.workspaceMap).map(
    (key) => state.workspaceLinks.workspaceMap[key]
  );
/**
 * Everyone has premium for now but only on trial
 * @param state
 * @returns
 */
export const hasLinksPremiumSelector = (state: RootState) => {
  const currentWorkspaceId = selectCurrentWorkspaceId(state);
  if (!currentWorkspaceId) return false;
  return (
    state.workspaceLinks.workspaceMap[currentWorkspaceId]?.subscription === Subscription.PREMIUM
  );
};

export const isLoadingWorkspaceLinks = (state: RootState) =>
  state.workspaceLinks.state === RequestState.IDLE ||
  state.workspaceLinks.state === RequestState.PENDING;

export default workspaceLinksSlice.reducer;
