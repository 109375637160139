import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Table,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// hooks
// components
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
// sections
import { UserListHead, UserListToolbar } from 'src/sections/@dashboard/user/list';
import { useSelector } from 'src/redux/store';
import { writeBatch, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { getCollection } from 'src/api';
import { useTranslation } from 'react-i18next';
import SkeletonTableList from 'src/components/skeleton/SkeletonTableList';
import { useDispatch } from 'react-redux';
import { setOnboarding } from 'src/redux/slices/onboarding';
import FlatCard from 'src/components/simply/Cards/FlatCard';
import { getInitials } from 'src/utils/names';
import { useSnackbar } from 'notistack';
import { InviteReminder, loadinInviteReminders } from 'src/redux/slices/inviteReminders';
import { AutomationType } from 'src/redux/slices/automationsSlice';
import StatusCell from './Status';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

const TABLE_HEAD = [
  { id: 'name', label: 'invites.list.headers.name', alignRight: false },
  { id: 'email', label: 'invites.list.headers.email', alignRight: false },
  { id: 'endDate', label: 'invites.list.headers.endDate', alignRight: false },
  // { id: 'phone', label: 'Telefon', alignRight: false },
  { id: 'phone', label: 'invites.list.headers.phone', alignRight: false },
  { id: 'status', label: 'invites.list.headers.status', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

export default function InviteReminderTable() {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector(selectCurrentWorkspaceId);
  const inviteReminders = useSelector((state) => state.inviteReminders.list);
  const hasSlug = useSelector((state) => state.publicConfig?.slug);
  const isLoading = useSelector(loadinInviteReminders);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const [selected, setSelected] = useState<string[]>([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = inviteReminders.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const deleteInvites = async (inviteIds: string[]) => {
    if (!userId || inviteIds.length < 1) return null;
    const batch = writeBatch(DB);
    inviteIds.forEach((inviteId) => {
      batch.delete(doc(DB, getCollection[Collection.USERS_INVITES](userId), inviteId));
    });

    await batch.commit();
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteInvite = async (userId: string) => {
    if (!userId) return;
    await deleteInvites([userId]);
    setSelected([]);
    // setUserList(deleteUser);
  };

  const handleDeleteMultiUser = async (selected: string[]) => {
    try {
      await deleteInvites(selected);
      setSelected([]);
      enqueueSnackbar(t('invites.deleteSuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('invites.deleteError'), { variant: 'error' });
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - inviteReminders.length) : 0;

  const filteredUsers = applySortFilter(inviteReminders, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && Boolean(filterName);

  const handleInvite = async () => {
    if (hasSlug) {
      // setOpen(true);
    } else {
      try {
        if (!userId) throw new Error(`userId invalid`);
        await setDoc(
          doc(DB, getCollection[Collection.ONBOARDING](userId), 'details'),
          { completedStartReviewCollection: false },
          { merge: true }
        );
        dispatch(setOnboarding({ isModalOpen: true }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <FlatCard>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          onDeleteUsers={() => handleDeleteMultiUser(selected)}
          additionalParameters={`zenfit=${AutomationType.REVIEW_REMINDER}`}
          disabled={inviteReminders.length < 1}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {isLoading && <SkeletonTableList sx={{ pl: 3, pr: 3 }} />}
            {!isLoading && (
              <Table size="small">
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD as any[]}
                  rowCount={inviteReminders.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, endDate, name, status, countryCode, photo, email, phone } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onClick={() => handleClick(id)} />
                          </TableCell>
                          <TableCell
                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          >
                            {photo ? (
                              <Avatar alt={name} sx={{ mr: 2 }} src={photo} />
                            ) : (
                              <Avatar alt={name} sx={{ mr: 2 }}>
                                {getInitials(name)}
                              </Avatar>
                            )}
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">
                            {t('general.dateToNow', { date: endDate })}
                            {/* {email} */}
                          </TableCell>
                          {/* <TableCell align="left">{'+45122134'}</TableCell> */}
                          <TableCell align="left">
                            {phone && countryCode}
                            {phone || 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            <StatusCell id={id} workspaceId={userId} status={status} />
                          </TableCell>

                          <TableCell align="right">
                            {/* <UserMoreMenu
                            onEdit={() => setOpenDrawer(id)}
                            onDelete={() => handleDeleteInvite(id)}
                            userName={name}
                          /> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={inviteReminders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('general.pagination.label')}
        />
      </FlatCard>
    </>
  );
}

type Anonymous = Record<string | number, string>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: InviteReminder[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
