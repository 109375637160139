import { Skeleton, Stack } from '@mui/material';
import Image from '../../../../../components/Image';
import zenfit from '../../../../../assets/integrations/zenfit.png';
import { Card } from 'src/components/ui/card';

export default function LoadingZenfit() {
  return (
    <Card className="p-6 shadow-none">
      <Stack spacing={3}>
        <Stack alignItems="center" py={3}>
          <Image src={zenfit} alt="zenfit" />
        </Stack>
        <Skeleton variant="rounded" height={36} />
      </Stack>
    </Card>
  );
}
