import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Intl, InviteStatus, Subscription, RequestState } from '../../enum';
import keyBy from 'lodash.keyby';
import { Member } from './workspaceReviewsSlice';
import { Timestamp } from 'firebase/firestore';
import { selectCurrentWorkspaceId } from './userSlice';

export interface WorkspaceFlow {
  id: string;
  // companyName?: string;
  members: {
    [key: string]: Member;
  };
  subscription: Subscription;
  trialStart: Timestamp;
  uid: string;
}

// Define a type for the slice state
interface UserState {
  state: RequestState;
  workspaceIds: string[];
  workspaceMap: { [key: string]: WorkspaceFlow };
}

// Define the initial state using that type
const initialState: UserState = {
  state: RequestState.IDLE,
  workspaceIds: [],
  workspaceMap: {},
};

export const workspaceFlowSlice = createSlice({
  name: 'workspaceFlow',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setWorkspaceFlow: (
      state,
      action: PayloadAction<{ workspaces: WorkspaceFlow[]; current?: string | null }>
    ) => {
      const workspaceMap = keyBy(action.payload.workspaces, 'id');
      return {
        ...state,
        // pick the first workspace if current is not in the list or there is no current
        workspaceIds: action.payload.workspaces.map((workspace) => workspace.id),
        workspaceMap,
        state: RequestState.RESOLVED,
      };
    },
    setWorkspaceFlowState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      state: action.payload,
    }),
  },
});

export const { setWorkspaceFlow, setWorkspaceFlowState } = workspaceFlowSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentWorkspaceFlowMembers = (state: RootState) => {
  const currentReviewWorkspace = selectCurrentWorkspaceId(state);

  if (!currentReviewWorkspace) return [];
  const currentWorkspace = state.workspaceFlow.workspaceMap[currentReviewWorkspace];

  return currentWorkspace?.members as { [uid: string]: Member };
};

export const selectWorkspaces = (state: RootState) =>
  Object.keys(state.workspaceFlow.workspaceMap).map((key) => state.workspaceFlow.workspaceMap[key]);

export const hasFlowPremiumSelector = (state: RootState) => {
  const currentReviewWorkspace = selectCurrentWorkspaceId(state);
  if (!currentReviewWorkspace) return false;
  return (
    state.workspaceFlow.workspaceMap[currentReviewWorkspace]?.subscription === Subscription.PREMIUM
  );
};

export const isLoadingWorkspaceFlow = (state: RootState) =>
  state.workspaceFlow.state === RequestState.IDLE ||
  state.workspaceFlow.state === RequestState.PENDING;

export default workspaceFlowSlice.reducer;
