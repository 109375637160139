import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where, doc } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export interface RenderDoc {
  id: string;
  url: string;
  createTime: Date;
}

// if passed here it will load X users reviews
export default function useReviewRenders(reviewId: string | null) {
  const [render, setRender] = useState<RenderDoc | null>(null);
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;

  useEffect(() => {
    let unsubscribe: () => void;
    if (workspaceId && reviewId) {
      const q = query(
        collection(DB, getCollection[Collection.USERS_REVIEW_RENDERS](workspaceId, reviewId)),
        orderBy('createTime', 'desc'),
        limit(1)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const [render] = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as RenderDoc)
        );
        setRender(render);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [reviewId, workspaceId]);
  return { render };
}
