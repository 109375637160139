// @mui
import { Grid } from '@mui/material';
// hooks

import { useTheme } from '@mui/material/styles';
// sections

import Step2 from './Steps/Step2';
import { useSelector } from 'react-redux';
import { Integration } from 'src/redux/slices/integrationSlice';
import { useParams } from 'react-router-dom';
import { WidgetType } from '../../enum';
import { selectCurrentPlatformIntegrationsMap } from '../../redux/selectors/integrations';
import Page from 'src/components/Page';
import PageContent from 'src/layouts/dashboard/PageContent';

/**
 *
 * @returns Loading all widgets from else where so it will always be present
 */
export default function Widget() {
  const { widgetId } = useParams<{ widgetId: string }>();

  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);

  const currentWidget = (widgetId && integrationMap[widgetId]) as Integration;

  const widgetType = currentWidget?.type as WidgetType;

  return (
    <Page title="Widget - setup">
      <PageContent>
        <Grid container spacing={3} sx={{}}>
          <Grid item xs={12} sm={12} md={12} sx={{ pb: 12 }}>
            <Grid container spacing={3}>
              <Step2 currentWidgetId={widgetId} widgetType={widgetType} />
            </Grid>
          </Grid>
        </Grid>
      </PageContent>
    </Page>
  );
}
