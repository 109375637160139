import React from 'react';

export default function TrustPilot() {
  return (
    <svg viewBox="0 0 799.89 761" width="25" height="23.78">
      <path
        d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z"
        fill="#00b67a"
      />
      <path d="M574.04 536.24l-21.23-65.78-152.72 110.91z" fill="#005128" />
    </svg>
  );
}
