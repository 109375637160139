import { useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { LeadForm, setLeadForms, setLeadFormsLoadingState } from 'src/redux/slices/leadFormsSlice';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function useLeadForms(userId?: string) {
  const dispatch = useDispatch();
  const currentWorkspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const workspaceId = userId || currentWorkspaceId;

  useEffect(() => {
    let unsubscribe: () => void;
    if (workspaceId) {
      dispatch(setLeadFormsLoadingState(RequestState.PENDING));
      const q = query(
        collection(DB, getCollection[Collection.LEADS_FORMS](workspaceId)),
        orderBy('createTime', 'desc'),
        limit(1)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const forms = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as LeadForm)
        );

        dispatch(setLeadForms(forms));
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
