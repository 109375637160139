import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import ChainedBackend from 'i18next-chained-backend';
// import LocizeBackend from 'i18next-locize-backend';
// import LocalStorageBackend from 'i18next-localstorage-backend';
//
import {
  format as formatDate,
  formatRelative,
  formatDistance,
  isDate,
  formatDistanceToNow,
} from 'date-fns';

import daLocales from './da.json';
import enLocales from './en.json';
import deLocales from './de.json';
import nnLocales from './nn.json';
import frLocales from './fr.json';
import svLocales from './sv.json';

import { enUS, da, nn, de, sv } from 'date-fns/locale';

// const locizeOptions = {
//   projectId: '83ffdf93-d1cf-4e5d-877d-c36fc077c1cd',
//   apiKey: '76130cd0-71aa-4db5-b9af-52f391886b4d', // YOU should not expose your apps API key to production!!!
//   referenceLng: 'en',
// };
// ----------------------------------------------------------------------
const locales = { enUS, da, nn, no: nn, de, sv };

i18n
  // .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // backend: {
    //   backends: [LocalStorageBackend, LocizeBackend],
    //   backendOptions: [
    //     {
    //       expirationTime: 1 * 24 * 60 * 60 * 1000, // 7 days
    //     },
    //     locizeOptions,
    //   ],
    // },
    // saveMissing: true,
    resources: {
      da: { translations: daLocales },
      en: { translations: enLocales },
      de: { translations: deLocales },
      nn: { translations: nnLocales },
      fr: { translations: frLocales },
      sv: { translations: svLocales },
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: ['en', 'da', 'nn', 'de', 'sv'],
    preload: ['en', 'da', 'nn', 'de', 'sv'],
    debug: true,
    ns: ['translations', 'en'],
    // defaultNS: 'simplytranslation',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value)) {
          // @ts-ignore
          const locale = locales[lng];

          if (format === 'dateShort') return formatDate(value, 'MMM do', { locale });
          if (format === 'dateShortYear') return formatDate(value, 'MMM do yyyy', { locale });
          if (format === 'dateTime') return formatDate(value, 'dd MMM yyyy p', { locale });
          if (format === 'long') return formatDate(value, 'PPPP', { locale });
          if (format === 'relative') return formatRelative(value, new Date(), { locale });
          if (format === 'bookingDateTime') return formatDate(value, 'do HH:mm', { locale });
          if (format === 'time') return formatDate(value, 'HH:mm', { locale });
          if (format === 'ago')
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true,
            });
          if (format === 'dateToNow') {
            return formatDistanceToNow(value, {
              addSuffix: true,
              locale,
            });
          }
          if (format === 'dateToNowWithoutSuffix') {
            return formatDistanceToNow(value, {
              locale,
            });
          }
          // @ts-ignore
          return formatDate(value, format, { locale });
        }

        return value;
      },
    },
  });

export default i18n;
