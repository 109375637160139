import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconButton, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Iconify from '../../../../components/Iconify';
import { LEADS } from '../../../../theme/palette';

// // fake data generator
// const getItems = (count: number) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `Question ${k} blablbabla`,
//   }));

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  // margin: `0 0 ${grid}px 0`,
  // padding: 12,
  // paddingLeft: 8,
  // paddingRight: 8,
  // paddingTop: 12,
  cursor: 'pointer',
  opacity: isDragging ? 0.5 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // padding: grid,
  // width: 250,
});

export default class DraggableQuestions extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.props.initialItems, result.source.index, result.destination.index);
    // console.log({ items });

    // this.setState({
    //   items,
    // });
    this.props.onChange(items);
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable-2">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.initialItems.map((item: any, index: number) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      role="button"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                        opacity: this.props.current === item.id ? 1 : 0.7,
                        backgroundColor: this.props.current === item.id ? `#F2F6FE` : 'transparent',
                      }}
                      onClick={() => this.props.onChangeCurrent(item?.id)}
                    >
                      <div
                        {...provided.dragHandleProps}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'grab',
                          paddingLeft: 8,
                          paddingTop: 12,
                          paddingBottom: 12,
                        }}
                      >
                        <Iconify
                          icon="ic:baseline-drag-indicator"
                          color="grey.400"
                          sx={{ height: 20, width: 20 }}
                        />
                      </div>
                      <Box ml={0.5}>
                        <Typography variant="body2">
                          {index + 1}. {item.content}
                        </Typography>
                      </Box>

                      {/* {item.content} */}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
