// @mui
import { Grid } from '@mui/material';
// sections

import Step2 from '../Steps/Step2';

import { WidgetType } from '../../../enum';
import { useSelector } from 'src/redux/store';
// import { selectLeadFormIntegration } from '../../../redux/selectors/integrations';

/**
 *
 * @returns Loading all widgets from else where so it will always be present
 */
export default function Widget() {
  // const { widgetId } = useParams<{ widgetId: string }>();

  const currentWidget = useSelector((state) => ({
    ...state.leadForms.list[0],
    type: WidgetType.LEAD_FORM,
  }));
  const widgetId = currentWidget.id;
  // const currentWidget = integrationMap; //(widgetId && integrationMap[widgetId]) as Integration;

  const widgetType = currentWidget?.type as WidgetType;

  return (
    <Grid container spacing={3} sx={{ pl: 2 }}>
      <Grid item xs={12} sm={12} md={12} sx={{ pb: 12 }}>
        <Grid container spacing={3}>
          <Step2 currentWidgetId={widgetId} widgetType={widgetType} />
        </Grid>
      </Grid>
    </Grid>
  );
}
