import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { Review, selectReviewsLoadedCount, setReviews } from '../redux/slices/reviewSlice';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

const pageSize = 18;

// if passed here it will load X users reviews
export default function useReviews(uid?: string | null) {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const reviewsCount = useSelector(selectReviewsLoadedCount);
  const [limitSize, setLimitSize] = useState(pageSize);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    let unsubscribe: () => void;

    if (uid || workspaceId) {
      // dispatch(setReviewLoadingState(RequestState.PENDING));
      const q = query(
        collection(DB, getCollection[Collection.USERS_REVIEWS](uid || workspaceId)),
        where('verified', '==', true),
        orderBy('createTime', 'desc'),
        limit(limitSize)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const reviews = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Review)
        );
        console.log({ reviews: reviews.length, limitSize });

        dispatch(setReviews(reviews));
        setLoadingMore(false);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [uid, workspaceId, limitSize]);

  const loadMore = () => {
    setLoadingMore(true);
    setLimitSize(limitSize + pageSize);
  };

  return { hasMore: reviewsCount === limitSize, loadMore, loadingMore };
}
