import { collection, doc, setDoc } from 'firebase/firestore';
import { toast } from 'sonner';
import { DB } from 'src/contexts/FirebaseContext';
import { getPlatformDisplayName } from 'src/redux/selectors/platform';
import { Platforms } from 'src/redux/slices/platform';

export async function switchPlatform(userId: string | null, platform: Platforms) {
  try {
    if (!userId) throw new Error('User not found');
    const userRef = doc(collection(DB, 'users'), userId);
    await setDoc(userRef, { platform }, { merge: true });
    const platformDisplayName = getPlatformDisplayName(platform);
    toast.success(`You are now using ${platformDisplayName}`);
  } catch (error) {
    console.error(error);
    toast.error('Failed to switch platform');
  }
}
