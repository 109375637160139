import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';
import { selectCurrentWorkspaceId, selectUserId } from 'src/redux/slices/userSlice';
import { useSelector } from 'src/redux/store';
import { ConfirmType, TeamMemberManager } from './TeamMember';
import uuidv4 from 'src/utils/uuidv4';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { Collection } from 'src/enum';
import { TeamInviteStatus } from 'src/redux/slices/teamInviteSlice';

export function ConfirmAddToProduct({
  member,
  refetch,
  onClose,
}: {
  member: TeamMemberManager | null;
  refetch: () => void;
  onClose: () => void;
}) {
  const [isAdding, setIsAdding] = useState(false);
  const { t } = useTranslation();
  const companyName = useSelector((state) => state.publicConfig.companyName);
  const userId = useSelector(selectUserId);
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const handleAddMember = async () => {
    try {
      if (!workspaceId || !member || !member.platform) return alert(t('general.error.default'));
      setIsAdding(true);

      const inviteId = uuidv4();
      await setDoc(doc(collection(DB, getCollection[Collection.TEAM_INVITES]())), {
        inviteId,
        createTime: new Date(),
        email: member.email,
        displayName: member?.displayName,
        status: TeamInviteStatus.PENDING,
        platform: member.platform,
        workspaceId,
        companyName: companyName || 'team',
        invitedBy: userId,
      });
      toast.success(t('invites.invitesSent'));
      onClose();
      refetch();
    } catch (error) {
      console.error(error);
      toast.error(t('general.error.default'));
    } finally {
      setIsAdding(false);
    }
  };

  const open = member?.confirmType === ConfirmType.ADD_TO_PRODUCT;
  return (
    <AlertDialog open={open} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            You are about to add {member?.displayName || member?.email} to this product.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleAddMember} disabled={isAdding}>
            {isAdding && <Loader2 className="animate-spin" />}
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
