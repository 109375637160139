import { sentenceCase } from 'change-case';
import { useState, forwardRef, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { Container, Typography, Stack, Box, Fade, Alert } from '@mui/material';
// hooks
import useSettings from 'src/hooks/useSettings';
// components
import Page from 'src/components/Page';
// sections
import useInvites from 'src/hooks/useInvites';
import { useSelector } from 'src/redux/store';

import { writeBatch, doc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { Collection } from 'src/enum';
import { getCollection } from 'src/api';
import { useTranslation } from 'react-i18next';

import InviteTable from './InviteTable';
import InviteReminderTable from './InviteReminderTable';
import { AutomationType, selectZenfitAutomation } from 'src/redux/slices/automationsSlice';
import { selectInviteRemindersCount } from 'src/redux/slices/inviteReminders';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { TooltipContent, TooltipTrigger, Tooltip } from 'src/components/ui/tooltip';
import PageContent from '../../../layouts/dashboard/PageContent';

const INVITE_TABS = [
  {
    value: '0',
    // icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
    component: <InviteTable />,
    label: 'invite.tabs.general',
  },
  {
    value: '1',
    // icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
    component: (
      // <EarlyUser>
      // <>
      <InviteReminderTable />
      // </>
      // </EarlyUser>
    ),
    label: 'invite.tabs.reminders',
  },
];

export default function InviteList() {
  useInvites();
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');
  const navigate = useNavigate();
  const userId = useSelector(selectCurrentWorkspaceId);
  const [currentTab, setCurrentTab] = useState(
    tabParam === AutomationType.REVIEW_REMINDER ? '1' : '0'
  );
  const automation = useSelector(selectZenfitAutomation);
  const inviteReminderCount = useSelector(selectInviteRemindersCount);

  const deleteInvites = async (inviteIds: string[]) => {
    if (!userId || inviteIds.length < 1) return null;
    const batch = writeBatch(DB);
    inviteIds.forEach((inviteId) => {
      batch.delete(doc(DB, getCollection[Collection.USERS_INVITES](userId), inviteId));
    });

    await batch.commit();
  };
  const handleDeleteInvite = async (userId: string) => {
    if (!userId) return;
    await deleteInvites([userId]);
    // setSelected([]);
    // setUserList(deleteUser);
  };

  const isZenfitTabDisabled = !automation?.id;

  return (
    <Page title="Invite: List">
      <PageContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {t('invites.list.inviteHeadline')}
          </Typography>
          {inviteReminderCount > 0 && (
            <Alert sx={{ cursor: 'pointer' }} severity="warning" onClick={() => setCurrentTab('1')}>
              {t('invites.list.remindersAlert', { inviteReminderCount })}
              {/* You have {inviteReminderCount} pending invite reminders. */}
            </Alert>
          )}
        </Stack>

        <Tabs
          defaultValue={currentTab}
          className="w-1/2"
          onValueChange={(value) => setCurrentTab(value)}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value={'0'} className="gap-2">
              {/* {tab.icon} */}
              {t('invite.tabs.general')}
            </TabsTrigger>

            <Tooltip>
              <TooltipTrigger className={isZenfitTabDisabled ? 'cursor-not-allowed' : ''}>
                <TabsTrigger value={'1'} className="gap-2" disabled={isZenfitTabDisabled}>
                  {/* {tab.icon} */}
                  {t('invite.tabs.reminders')}{' '}
                  {inviteReminderCount ? `(${inviteReminderCount})` : ''}
                </TabsTrigger>
              </TooltipTrigger>
              {isZenfitTabDisabled && (
                <TooltipContent>
                  <p>{t('invite.tabs.remindersDisabled')}</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TabsList>
        </Tabs>

        {/* <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => (isZenfitTabDisabled && value === 1 ? {} : setCurrentTab(value))}
        >
          <Tab
            disableRipple
            key={0}
            label={t('invite.tabs.general')}
            // icon={tab.icon}
            value={0}
          />
          <Tab
            disableRipple
            key={1}
            label={
              isZenfitTabDisabled ? (
                <Tooltip title={t('invite.tabs.remindersDisabled')} arrow>
                  <Box>
                    {t('invite.tabs.reminders')}{' '}
                    {inviteReminderCount ? `(${inviteReminderCount})` : ''}
                  </Box>
                </Tooltip>
              ) : (
                <>
                  {t('invite.tabs.reminders')}{' '}
                  {inviteReminderCount ? `(${inviteReminderCount})` : ''}
                </>
              )
            }
            // icon={tab.icon}
            value={1}
          />
        </Tabs> */}

        {/* <Box sx={{ mb: 5 }} /> */}

        {INVITE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return (
            <Fade in={isMatched} key={tab.value}>
              <Box sx={isMatched ? { display: 'inherit' } : { display: 'none' }} key={tab.value}>
                {tab.component}
              </Box>
            </Fade>
          );
        })}
      </PageContent>
    </Page>
  );
}

// ----------------------------------------------------------------------
