import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TextField, IconButton } from '@mui/material';
import Iconify from '../../../../components/Iconify';

// // fake data generator
// const getItems = (count: number) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `Question ${k} blablbabla`,
//   }));

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 16;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  display: 'flex',
  alignItems: 'center',
  margin: `0 0 ${grid}px 0`,

  opacity: isDragging ? 0.5 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // padding: grid,
  // width: 250,
});

export default class DraggableOptions extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.props.initialItems, result.source.index, result.destination.index);

    this.props.onChange(items);
  }

  onRemove(itemToReomve: any) {
    const items = this.props.initialItems.filter((item: any) => item.id !== itemToReomve.id);
    this.props.onChange(items);
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.initialItems.map((item: any, index: number) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      // style={{ display: 'flex' }}
                    >
                      <div
                        {...provided.dragHandleProps}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Iconify
                          icon="ic:baseline-drag-indicator"
                          color="grey.400"
                          sx={{ height: 24, width: 24 }}
                        />
                      </div>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Question..."
                        value={item.content}
                        onChange={(e) => {
                          const items = this.props.initialItems.map((it: any) => {
                            if (it.id === item.id) {
                              return {
                                ...it,
                                content: e.target.value,
                              };
                            }
                            return it;
                          });

                          this.props.onChange(items);
                        }}
                      />
                      <IconButton onClick={() => this.onRemove(item)}>
                        <Iconify icon="ic:round-delete-outline" color="grey.400" />
                      </IconButton>
                      {/* {item.content} */}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
