import { styled, tooltipClasses } from '@mui/material';
import { IconButton, Tooltip, TooltipProps } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/Iconify';
import { useSelector } from 'src/redux/store';
import { getIconByLeadActivityType } from './LeadActivity';
import { Stack, Typography } from '@mui/material';
import { truncateString } from '../../../utils/truncate';
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';

export default function ActivityCell({ id }: { id: string }) {
  const lead = useSelector((state) => state.leadFormsSubmissions.map[id]);
  const activity = lead?.lastestActivity;
  console.log({ activity });

  if (!activity?.type) return null;
  const icon = getIconByLeadActivityType(activity.type);
  return (
    <LightTooltip placement="top" title={activity?.content}>
      <Stack>
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Iconify icon={icon} fontSize={24} />
          {truncateString(activity?.content, 30)}
        </Stack>
        <Typography variant="caption" color="text.secondary">
          {formatDistanceToNowStrict(activity?.createTime, { addSuffix: true })}
        </Typography>
      </Stack>
    </LightTooltip>
  );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    fontSize: 12,
    // minWidth: 300,
    whiteSpace: 'pre-line',
  },
}));
