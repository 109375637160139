import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { selectPlatformDisplayName } from 'src/redux/selectors/platform';
import { useSelector } from 'src/redux/store';

export function BreadcrumbWorkspace({ workspaceId }: { workspaceId: string | null }) {
  const platformDisplayName = useSelector(selectPlatformDisplayName);
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink>{platformDisplayName}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Manage team</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
}
