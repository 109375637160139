import * as React from 'react';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import { useSelector } from 'src/redux/store';
import { Platforms } from 'src/redux/slices/platform';
import { Stack, Typography } from '@mui/material';
import reviewPlatform from '../../assets/widget/review_visual.png';
import Image from 'src/components/Image';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { isLoadingWorkspaceLinks } from 'src/redux/slices/workspaceLinksSlice';
import { Button } from 'src/components/ui/button';
import { isWorkspaceOwner, selectHasPersonalWorkspace } from 'src/redux/slices/userSlice';
import { toast } from 'sonner';

export default function ModalLinksInitialContainer() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const isLinksPlatform = useSelector((state) => state.user.platform === Platforms.SIMPLY_LINKS);
  const open = useSelector(
    (state) =>
      state.workspaceLinks.workspaceIds.length <= 0 &&
      !isLoadingWorkspaceLinks(state) &&
      state.user.id
  );

  const hasPersonalWorkspace = useSelector(selectHasPersonalWorkspace);

  const canSetupProduct = useSelector(isWorkspaceOwner);

  const onSetupLinksWorkspace = async () => {
    try {
      setLoading(true);
      const functionRef = httpsCallable(Functions, 'setupLinksWorkspace');
      const response: any = await functionRef({
        request: 'SETUP_LINKS_WORKSPACE',
      });

      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        toast.error(t('general.error.default'));
        return;
      }

      toast.success(t('links.setup.workspace.success'));
      // window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(t('general.error.default'));
    } finally {
      setLoading(false);
    }
  };

  if (!isLinksPlatform) return null;

  if (!canSetupProduct) return null;

  if (!open && hasPersonalWorkspace) return null;

  return (
    <div className="fixed flex flex-col p-6 pt-12 justify-center items-center h-[calc(100%)] w-[calc(100%)] mt-0 pl-[--sidebar-width] z-10 backdrop-blur-lg gap-4">
      <div>
        {/* <Stack justifyContent="center" alignItems="center">
          <Logo />
        </Stack>
        <Box mt={4} /> */}
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h2">{t('links.modal.initial.title')}</Typography>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Paper elevation={10} sx={{ borderRadius: 2 }}>
            <Image sx={{ width: 250 }} src={reviewPlatform} />
          </Paper>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Button size="lg" onClick={onSetupLinksWorkspace} loading={loading}>
            {t('links.initial.startTrial')}
          </Button>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <DialogContentText textAlign="center" width="60%">
            {t('links.modal.initial.description')}
          </DialogContentText>
        </Stack>
      </div>
    </div>
  );
}
