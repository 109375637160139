import React, { useState } from 'react';
import zenfit from '../../../../../assets/integrations/zenfit.png';
import { Stack, DialogContent, Box, Dialog, Alert } from '@mui/material';
import Image from '../../../../../components/Image';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PulseDot from '../../../../../components/PulseDot';
import { useSelector } from 'react-redux';
import {
  selectZenfitApi,
  ApiIntegrationStatus,
} from '../../../../../redux/slices/apiIntegrationsSlice';
import { Functions } from '../../../../../contexts/FirebaseContext';
import { httpsCallable } from 'firebase/functions';
import { selectZenfitAutomation } from '../../../../../redux/slices/automationsSlice';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import { useNavigate } from 'react-router-dom';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { Card } from 'src/components/ui/card';
import { Button } from 'src/components/ui/button';

export default function Disconnect() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const connectedAPIKey = useSelector(selectZenfitApi);
  const hasAutomation = useSelector(selectZenfitAutomation);
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDisconnect = async () => {
    try {
      setLoading(true);
      if (!workspaceId) {
        throw new Error('Workspace ID is missing');
      }
      const functionRef = httpsCallable(Functions, 'disconnectZenfitClientApi');
      const response: any = await functionRef({ workspaceId });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }
      enqueueSnackbar(t('apiKey.disconnect.success'), { variant: 'success' });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(t('general.error'), { variant: 'error' });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const integrationHasIssues = connectedAPIKey.status !== ApiIntegrationStatus.STABLE;
  return (
    <>
      <Card className="p-6 shadow-none">
        <Stack spacing={3}>
          <Stack alignItems="center" py={3}>
            <Image src={zenfit} alt="zenfit" />
          </Stack>
          <Stack alignItems="center" direction="row">
            {integrationHasIssues ? (
              <>
                <Box mt={-2}>
                  <PulseDot size={16} color={theme.palette.error.main} />
                </Box>
                <Box ml={2}>
                  <Typography variant="body1" color="grey.700">
                    {t('integrations.zenfit.health.bad')}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box mt={-2}>
                  <PulseDot size={16} color={theme.palette.success.main} />
                </Box>
                <Box ml={2}>
                  <Typography variant="body1" color="grey.700">
                    {t('integrations.zenfit.health.good')}
                  </Typography>
                </Box>
              </>
            )}
          </Stack>
          {!hasAutomation && (
            <Stack>
              <Alert
                severity="warning"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(PATH_DASHBOARD.general.reviewAutomations);
                }}
              >
                <Typography variant="body1" color="grey.700">
                  {t('integrations.zenfit.automation.missing.warning')}
                </Typography>
              </Alert>
            </Stack>
          )}
          <Button variant="outline" onClick={handleOpen}>
            Disconnect Zenfit
          </Button>
        </Stack>
      </Card>
      <Dialog keepMounted open={open} onClose={handleClose}>
        <DialogTitle mb={2}>Disconnect Zenfit</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('integrations.zenfit.disconnect.description')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outline" onClick={handleClose}>
            {t('general.cancel')}
          </Button>
          <Button loading={loading} variant="destructive" onClick={onDisconnect}>
            Disconnect
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
