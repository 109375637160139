import React, { useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  useTheme,
  Stack,
  TextField,
  InputAdornment,
  Alert,
} from '@mui/material';
import Box from '@mui/material/Box';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../../redux/store';
import Image from '../../../../components/Image';
import zenfit from '../../../../assets/integrations/zenfit.png';
import { SteppedLineTo } from 'react-lineto';
import Android12Switch from '../../../../components/customSwitch';
import Iconify from '../../../../components/Iconify';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { doc, setDoc } from 'firebase/firestore';
import { Collection } from '../../../../enum';
import { DB } from '../../../../contexts/FirebaseContext';
import { getCollection } from '../../../../api';
import { selectZenfitAutomation } from '../../../../redux/slices/automationsSlice';
import { usePrompt } from '../../../../hooks/usePrompt';
import { Switch } from 'src/components/ui/switch';
import { Card } from 'src/components/ui/card';
import { Button } from 'src/components/ui/button';
import PageContent from 'src/layouts/dashboard/PageContent';

export default function ReviewAutomations() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();

  const automation = useSelector(selectZenfitAutomation);
  const [checked, setChecked] = useState(automation?.active || false);
  const [value, setValue] = useState<number | undefined>(automation?.config.reminderDays || 7);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value ? Number(event.target.value) : undefined);
  };
  console.log({ automation });

  const min = 0;
  const max = 30;

  const isDirty = checked !== automation?.active || value !== automation?.config.reminderDays;
  usePrompt(t('general.changes.unsaved'), isDirty);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (!automation?.id || !value) {
        throw new Error('Automation ID not found');
      }
      await setDoc(
        doc(DB, getCollection[Collection.AUTOMATIONS](), automation.id),
        {
          updateTime: new Date(),
          active: checked,
          config: {
            reminderDays: value,
          },
        },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Page title="Automations">
      <PageContent>
        <Grid container spacing={3} sx={{ height: 'calc(100vh - 56px)' }}>
          <Grid item xs={6} pr={3}>
            <Typography variant="h4">{t('reviewAutomation.headline.title')}</Typography>
            <Typography variant="body1">{t('reviewAutomation.headline.description')}</Typography>
            <Box mt={8} />
            <Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Iconify fontSize={28} icon="entypo:cycle" />
                <Typography variant="body2" color="grey.500">
                  ({t('general.comingSoon')})
                </Typography>
              </Stack>
              <Box mt={1} />
              <Typography variant="subtitle1">
                {t('reviewAutomation.automatedSend.headline')}
              </Typography>
              <Typography variant="body2">
                {t('reviewAutomation.automatedSend.description')}
              </Typography>
            </Stack>
            <Box mt={4} />
            <Stack>
              <Iconify fontSize={28} icon="lucide:mail-warning" />
              <Box mt={1} />
              <Typography variant="subtitle1">
                {t('reviewAutomation.reminderSend.headline')}
              </Typography>
              <Typography variant="body2">
                {t('reviewAutomation.reminderSend.description')}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              backgroundImage: `repeating-linear-gradient(to bottom, transparent, transparent 19px, white 19px, white 20px), repeating-linear-gradient(to right, ${theme.palette.grey[100]}, ${theme.palette.grey[100]} 19px, white 9px, white 20px)`,
            }}
          >
            <Stack height="100%" alignSelf="center" justifyContent="center" alignItems="center">
              <Card className="A shadow-none p-3 z-10">
                <Image sx={{ width: 120 }} src={zenfit} alt="zenfit" />
              </Card>

              <Box mt={6} />

              <Card className="B shadow-none px-4 py-2 z-10">
                {/* sx={{ px: 2, py: 1, zIndex: 1 }}> */}
                <Typography variant="body2">
                  {t('reviewAutomation.flow.clients.endofterm')}
                  {/* Completion of clients coaching period with you */}
                </Typography>
              </Card>
              <Box mt={6} />
              <Card className="C shadow-none p-4 z-10">
                {/* sx={{ p: 3, zIndex: 1 }}> */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Switch checked={checked} onCheckedChange={setChecked} />
                  <Typography variant="body2">
                    {checked ? (
                      <span>
                        {t('reviewAutomation.flow.clients.reminderheadline')}{' '}
                        <b>{t('general.toggle.turnedon')}</b>
                        {/* Client review reminders is now <b>turned on</b> */}
                      </span>
                    ) : (
                      <span>
                        {t('reviewAutomation.flow.clients.reminderheadline')}{' '}
                        <b>{t('general.toggle.turnedoff')}</b>
                        {/* Client review reminders is now <b>turned off</b> */}
                      </span>
                    )}
                  </Typography>
                </Stack>
                {checked && (
                  <>
                    <Box mt={2} />
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="body2">
                        {t('reviewAutomation.flow.clients.beforedayinput')}
                        {/* I want my reminders */}
                      </Typography>
                      <TextField
                        id="outlined-number"
                        type="number"
                        margin="dense"
                        size="small"
                        value={value}
                        onBlur={() => {
                          if (!value) {
                            setValue(min);
                          }
                          if (Number(value) <= min) {
                            setValue(min);
                          }
                          if (Number(value) > max) {
                            setValue(max);
                          }
                        }}
                        autoFocus
                        onChange={handleChange}
                        sx={{ width: 48, fontWeight: 'bold' }}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        InputProps={{
                          inputProps: { min, max },
                          sx: {
                            fontSize: 18,
                            fontWeight: 600,
                            input: {
                              paddingBottom: 0,
                              padding: 0,
                              textAlign: 'center',
                              '&::-webkit-outer-spin-button': {
                                opacity: 1,
                              },
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                              <Stack>
                                <Stack
                                  width={18}
                                  height={18}
                                  position="relative"
                                  sx={{
                                    overflow: 'hidden',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                      transform: 'scale(1.2)',
                                      cursor: 'pointer',
                                    },
                                  }}
                                  onClick={() =>
                                    Number(value) < max
                                      ? setValue(Number(value) + 1)
                                      : setValue(max)
                                  }
                                >
                                  <Iconify
                                    sx={{ position: 'absolute', bottom: -8, left: -3 }}
                                    fontSize={24}
                                    icon="ic:baseline-arrow-drop-up"
                                  />
                                </Stack>
                                <Stack
                                  width={18}
                                  height={18}
                                  sx={{
                                    overflow: 'hidden',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                      transform: 'scale(1.2)',
                                      cursor: 'pointer',
                                    },
                                  }}
                                  onClick={() =>
                                    Number(value) > min
                                      ? setValue(Number(value) - 1)
                                      : setValue(min)
                                  }
                                  position="relative"
                                >
                                  <Iconify
                                    sx={{ position: 'absolute', top: -7, left: -3 }}
                                    fontSize={24}
                                    icon="ic:baseline-arrow-drop-down"
                                  />
                                </Stack>
                              </Stack>
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                      <Typography variant="body2">
                        {t('reviewAutomation.flow.clients.afterdayinput')}
                        {/* days before the client ends period  */}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Card>
              <Box mt={4} />
              <Card className="D flex items-center gap-2 shadow-none p-4 text-center z-10">
                {/* sx={{ p: 2, zIndex: 1, textAlign: 'center' }}> */}
                <Iconify fontSize={32} icon="mdi:robot-happy-outline" />
                <Typography variant="body2">
                  {t('reviewAutomation.flow.simplyrobot.description')}
                </Typography>
              </Card>
              <Box mt={4} />
              <Alert severity="info" className="E" sx={{ width: '80%', zIndex: 1 }}>
                <Typography variant="body2">
                  {t('reviewAutomation.flow.clients.reminderExplainer', { value })}
                </Typography>
              </Alert>

              <ConnectionLines />
            </Stack>
            <Stack
              sx={{ position: 'fixed', zIndex: 2, bottom: 42, right: 42 }}
              direction="row"
              justifyContent="flex-end"
            >
              <Button size="lg" loading={isSubmitting} onClick={handleSubmit}>
                {t('form.saveBtn')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </PageContent>
    </Page>
  );
}

function ConnectionLines() {
  const theme = useTheme();

  // useEffect that the window size changed
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    // const debouncedHandleResize = debounce(handleResize, 10);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <>
      <SteppedLineTo
        zIndex={0}
        borderColor={theme.palette.common.black}
        from="A"
        to="B"
        orientation="v"
        delay={0}
      />
      <SteppedLineTo
        zIndex={0}
        borderColor={theme.palette.common.black}
        from="B"
        to="C"
        orientation="v"
        delay={0}
      />
      <SteppedLineTo
        zIndex={0}
        borderColor={theme.palette.common.black}
        from="C"
        to="D"
        orientation="v"
        delay={0}
      />
      <SteppedLineTo
        zIndex={0}
        borderColor={theme.palette.common.black}
        from="D"
        to="E"
        orientation="v"
        delay={0}
      />
    </>
  );
}
