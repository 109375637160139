import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Member } from 'src/redux/slices/workspaceReviewsSlice';
import { getInitials } from '../../utils/names';
import { TeamInvite } from 'src/redux/slices/teamInviteSlice';
import { UserCog, WatchIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface AvatarGroupProps {
  users: Array<TeamInvite>;
  max?: number;
  onClick?: () => void;
}

export function TeamAvatarGroupPending({ users, max = 3, onClick }: AvatarGroupProps) {
  const { t } = useTranslation();
  const visibleUsers = users.slice(0, max);
  const remainingUsers = users.slice(max);

  return (
    <div className="flex -space-x-4 cursor-pointer" role="button" onClick={onClick}>
      {visibleUsers.map((user, index) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Avatar key={index} className="h-9 w-9 border-2 border-background">
              <AvatarFallback>
                <UserCog size={16} />
              </AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {t('team.invitePending.tooltip', { displayName: user.displayName || user.email })}
            </p>
          </TooltipContent>
        </Tooltip>
      ))}
      {remainingUsers.length > 0 && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Avatar className="h-9 w-9 border-2 border-background bg-muted">
              <AvatarFallback>+{remainingUsers.length}</AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {t('team.invitePending.tooltip', {
                displayName: remainingUsers
                  .map((user) => getInitials(user.displayName || user.email))
                  .join(', '),
              })}
            </p>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
}
