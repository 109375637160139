import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash.keyby';
import { RequestState } from '../../enum';
import { RootState } from '../store';
import { Platforms } from './platform';
import { TeamInvite } from './teamInviteSlice';

// Define a type for the slice state
interface UserState {
  state: RequestState;
  workspaceTeamInviteIds: string[];
  workspaceTeamInviteMap: { [key: string]: TeamInvite };
}

// Define the initial state using that type
const initialState: UserState = {
  state: RequestState.IDLE,
  workspaceTeamInviteIds: [],
  workspaceTeamInviteMap: {},
};

export const workspaceTeamInvitesSlice = createSlice({
  name: 'workspaceTeamInvites',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setWorkspaceTeamInvitesLoadingState: (state) => ({
      ...state,
      state: RequestState.PENDING,
    }),
    // Use the PayloadAction type to declare the contents of `action.payload`
    setWorkspaceTeamInvites: (state, action: PayloadAction<TeamInvite[]>) => ({
      ...state,
      workspaceTeamInviteIds: action.payload.map((workspace) => workspace.id),
      workspaceTeamInviteMap: keyBy(action.payload, 'id'),
      state: RequestState.RESOLVED,
    }),
  },
});

export const { setWorkspaceTeamInvitesLoadingState, setWorkspaceTeamInvites } =
  workspaceTeamInvitesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectWorkspaceAllTeamInvites = (state: RootState) =>
  Object.keys(state.workspaceTeamInvites.workspaceTeamInviteMap).map((id) => ({
    ...state.workspaceTeamInvites.workspaceTeamInviteMap[id],
  }));

export const selectWorkspaceReviewTeamInvites = (state: RootState) =>
  Object.keys(state.workspaceTeamInvites.workspaceTeamInviteMap)
    .map((id) => ({
      ...state.workspaceTeamInvites.workspaceTeamInviteMap[id],
    }))
    .filter((invite) => invite.platform === Platforms.SIMPLY_REVIEW);

export const selectWorkspaceFlowTeamInvites = (state: RootState) =>
  Object.keys(state.workspaceTeamInvites.workspaceTeamInviteMap)
    .map((id) => ({
      ...state.workspaceTeamInvites.workspaceTeamInviteMap[id],
    }))
    .filter((invite) => invite.platform === Platforms.SIMPLY_FLOW);

export const selectWorkspaceLeadsTeamInvites = (state: RootState) =>
  Object.keys(state.workspaceTeamInvites.workspaceTeamInviteMap)
    .map((id) => ({
      ...state.workspaceTeamInvites.workspaceTeamInviteMap[id],
    }))
    .filter((invite) => invite.platform === Platforms.SIMPLY_LEADS);

export default workspaceTeamInvitesSlice.reducer;
