import { useEffect } from 'react';

import { selectPlatform, selectUserId, setUserPlaform } from '../redux/slices/userSlice';
import { dispatch, useSelector } from '../redux/store';
// routes
import { useLocation } from 'react-router-dom';
import { Platforms } from '../redux/slices/platform';
import { switchPlatform } from './switchCurrentPlatform';

// ----------------------------------------------------------------------

type ReviewPageProps = {
  children: any;
};
/**
 * Checks if it is a path of simply flow and if the platform is already set to simply flow
 * else it will set the platform to simply flow
 * to avoid bugs
 */
export default function SimplyReviewGuard({ children }: ReviewPageProps) {
  const platform = useSelector(selectPlatform);
  const userId = useSelector(selectUserId);

  const { pathname } = useLocation();
  useEffect(() => {
    // @ts-ignore
    if (platform !== Platforms.SIMPLY_REVIEW) {
      dispatch(setUserPlaform(Platforms.SIMPLY_REVIEW));
      switchPlatform(userId, Platforms.SIMPLY_REVIEW);
    }

    return () => {};
  }, [pathname]);

  return children;
}
