import { useState } from 'react';
// @mui
import { Box } from '@mui/material';
// _mock_
import { _userPayment, _userAddressBook, _userInvoices, _userAbout } from '../../_mock';
// components
import Page from 'src/components/Page';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AccountIntegrations from '../../sections/@dashboard/user/account/AccountIntegrations';
// sections
import {
  AccountGeneral,
  AccountBilling,
  AccountChangePassword,
} from '../../sections/@dashboard/user/account';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { Blocks, CreditCard, Lock, SquareUser } from 'lucide-react';

// ----------------------------------------------------------------------

export enum TabType {
  GENERAL = 'general',
  BILLING = 'billing',
  CHANGE_PASSWORD = 'change_password',
  INTEGRATIONS = 'integrations',
}

export default function UserAccount() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') as TabType;

  const supportedTabs = [
    TabType.GENERAL,
    TabType.BILLING,
    TabType.CHANGE_PASSWORD,
    TabType.INTEGRATIONS,
  ];
  const defaultTab = tabParam && supportedTabs.includes(tabParam) ? tabParam : TabType.GENERAL;
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const ACCOUNT_TABS = [
    {
      value: TabType.GENERAL,
      icon: <SquareUser className="w-4 h-4" />,
      component: <AccountGeneral />,
      label: 'account.tabs.general',
    },
    {
      value: TabType.BILLING,
      icon: <CreditCard className="w-4 h-4" />,
      component: (
        <AccountBilling
        // cards={_userPayment}
        // addressBook={_userAddressBook}
        // invoices={_userInvoices}
        />
      ),
      label: 'account.tabs.billing',
    },
    // {
    //   value: 'notifications',
    //   icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
    //   component: <AccountNotifications />,
    // },
    {
      value: TabType.CHANGE_PASSWORD,
      icon: <Lock className="w-4 h-4" />,
      component: <AccountChangePassword />,
      label: 'account.tabs.changePassword',
    },
    {
      value: TabType.INTEGRATIONS,
      icon: <Blocks className="w-4 h-4" />,
      component: (
        // <EarlyUser>
        <AccountIntegrations />
        // </EarlyUser>
      ),
      label: 'account.tabs.integrations',
    },
  ];
  const handleTabChange = (value: TabType) => {
    setCurrentTab(value);
    // Handle any side effects here
    console.log('Tab changed to:', value);
  };
  return (
    <Page title="Konto">
      <div className="p-4 px-8">
        <h4 className="text-2xl font-semibold pb-8">{t('account.account')}</h4>

        {/* <HeaderBreadcrumbs
          heading={t('account.account')}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: t('account.accountSettings') },
          ]}
        /> */}
        {/* <Card className="p-8 shadow-none"> */}
        <Tabs
          defaultValue={currentTab}
          className="w-2/3"
          onValueChange={(value) => handleTabChange(value as TabType)}
        >
          <TabsList className="grid w-full grid-cols-4">
            {ACCOUNT_TABS.map((tab) => (
              <TabsTrigger value={tab.value} className="gap-2">
                {tab.icon}
                {t(tab.label)}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        {/* <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={t(tab.label)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs> */}

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
        {/* </Card> */}
      </div>
    </Page>
  );
}
