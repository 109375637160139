import { useState } from 'react';
// @mui
import { MenuItem, Stack, TableCell, useTheme } from '@mui/material';
// components
import MenuPopover from 'src/components/MenuPopover';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { doc, setDoc } from 'firebase/firestore';
import { Collection, InviteReminderStatus, InviteStatus } from 'src/enum';
import { getCollection } from 'src/api';
import { DB } from 'src/contexts/FirebaseContext';
import { sentenceCase } from 'change-case';

export const statuses = [
  { value: InviteReminderStatus.COMPLETED, label: 'reviewInviteReminders.list.status.invited' },
];
function getInviteStatusLabelText(status: InviteReminderStatus) {
  switch (status) {
    case InviteReminderStatus.PENDING:
      return 'invites.status.pending';
    case InviteReminderStatus.PROCESSING:
      return 'invites.status.sent';
    case InviteReminderStatus.COMPLETED:
      return 'invites.status.ready';

    default:
      return 'default';
  }
}
function getInviteStatusLabelColor(status: InviteReminderStatus) {
  switch (status) {
    case InviteReminderStatus.PENDING:
      return 'default';
    case InviteReminderStatus.PROCESSING:
      return 'info';
    default:
      return 'default';
  }
}

// ----------------------------------------------------------------------

export default function StatusCell({
  id,
  workspaceId,
  status,
}: {
  id: string;
  workspaceId: string | null;
  status: InviteReminderStatus;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSelect = async (value?: InviteReminderStatus) => {
    try {
      if (!workspaceId || !id) {
        throw new Error(`Missing workspaceId or formSubmitId`);
      }

      await setDoc(
        doc(DB, getCollection[Collection.USERS_INVITES_REMINDERS](workspaceId), id),
        { status: value ?? null },
        { merge: true }
      );
      enqueueSnackbar(t('general.success.default'));
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'));
    }
  };
  const ariaid = open ? `simple-popover-${id}` : undefined;

  // Not using strict as relying null and undefined to give same result
  // eslint-disable-next-line
  const selected = statuses.find((s) => s.value == status);

  return (
    <TableCell
      id={`cell-${id}`}
      align="left"
      sx={{ cursor: 'pointer' }}
      aria-describedby={ariaid}
      // onClick={() => setOpenDrawer(id)}
    >
      {/* <Tooltip title={scriptRecommendation}> */}
      <Box onClick={handleOpen}>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={getInviteStatusLabelColor(status)}
        >
          {sentenceCase(t(getInviteStatusLabelText(status)))}
        </Label>
      </Box>
      <MenuPopover
        id={id}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        aria-labelledby={`cell-${id}`}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 200,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {statuses.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === status}
              onClick={() => handleSelect(option.value)}
            >
              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </TableCell>
  );
}
