import { useEffect } from 'react';
import { query, collection, where, limit, onSnapshot } from 'firebase/firestore';
import { Collection } from '../enum';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { useDispatch, useSelector } from 'react-redux';
import { ApiIntegration, setZenfitLeadApiIntegrations } from '../redux/slices/apiIntegrationsSlice';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function useZenfitLeadApiIntegrations(userId?: string) {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  // const [isLoadingApis, setIsLoadingApis] = useState(true);
  const queryUserId = userId || workspaceId;
  useEffect(() => {
    let unsubscribe: () => void;

    if (queryUserId) {
      const q = query(
        collection(DB, getCollection[Collection.API_INTEGRATIONS_READ](queryUserId, 'zenfitLead')),
        where('readable', '==', true),
        where('userId', '==', queryUserId),
        limit(1)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const [zenfitIntegration] = querySnapshot.docs.map(
            (doc) =>
              ({
                id: 'zenfitLead',
                ...doc.data(),
              } as ApiIntegration)
          );

          dispatch(setZenfitLeadApiIntegrations([zenfitIntegration]));
        },
        (error) => {
          console.error(error);
          // dispatch(setRemoveZenfitApiIntegration());
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [queryUserId]);

  return {};
}
