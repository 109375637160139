import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intl, RequestState } from '../../enum';
import type { RootState } from '../store';
import { Timestamp } from 'firebase/firestore';
import { QuestionType } from 'src/pages/simplyleads/leads/QuestionsSection';

// type QuestionType = "radio" | "number" | "long_text" | "contact";
export interface QuestionOption {
  id: string;
  content: string;
}

export interface Question {
  id: string;
  content: string;
  type: QuestionType;
  options?: QuestionOption[];
  placeholder?: string;
  required?: boolean;
}

export interface LeadForm {
  id: string;
  hasInitSetup?: boolean;
  createTime: Timestamp;
  language: Intl;
  form: Question[];
  color?: string;
  previousText: string;
  nextText: string;
  submitText: string;
}

export interface LeadFormSuggestion {
  id: string;
  suggestions: { id: string; suggestions: string[] }[];
}

export interface LeadFormSuggetionState {
  [id: string]: { suggestionsMap: { [id: string]: string[] } };
}
// Define a type for the slice state
interface LeadFormState {
  list: LeadForm[];
  suggestions: LeadFormSuggetionState;
  loading: RequestState;
  suggestionsLoading: RequestState;
  color?: string;
}

// Define the initial state using that type
const initialState: LeadFormState = {
  list: [],
  suggestions: {},
  loading: RequestState.IDLE,
  suggestionsLoading: RequestState.IDLE,
};

export const leadFormsSlice = createSlice({
  name: 'leadForms',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLeadFormsLoadingState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      loading: action.payload,
    }),
    setLeadForms: (state, action: PayloadAction<LeadForm[]>) => ({
      ...state,
      list: action.payload,
      loading: RequestState.RESOLVED,
    }),
    setLeadFormSuggestionsLoadingState: (state, action: PayloadAction<RequestState>) => ({
      ...state,
      suggestionsLoading: action.payload,
    }),
    setLeadFormSuggestions: (state, action: PayloadAction<LeadFormSuggestion>) => ({
      ...state,
      suggestionsLoading: RequestState.RESOLVED,
      suggestions: {
        ...state.suggestions,
        [action.payload.id]: {
          suggestionsMap: action.payload.suggestions.reduce(
            (acc, s) => ({
              ...acc,
              [s.id]: s.suggestions,
            }),
            {}
          ),
        },
      },
    }),
  },
});

export const {
  setLeadFormsLoadingState,
  setLeadFormSuggestionsLoadingState,
  setLeadForms,
  setLeadFormSuggestions,
} = leadFormsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLeadForm = (state: RootState) => state.leadForms.list[0];
export const loadingLeadFormState = (state: RootState) =>
  state.leadForms.loading === RequestState.IDLE || state.leadForms.loading === RequestState.PENDING;

export const selectLeadFormSuggestions = (state: RootState) => {
  const currentLeadForm = selectLeadForm(state);
  return state.leadForms.suggestions[currentLeadForm?.id]?.suggestionsMap;
};

export const selectLeadFormQuestions = (state: RootState) => {
  const currentLeadForm = selectLeadForm(state);
  return state.leadForms.list.find((q) => q.id === currentLeadForm?.id)?.form;
};

export const selectLeadFormColor = (state: RootState) =>
  state.leadForms.list[0]?.color || state.publicConfig.color || '#222';

export const selectLeadFormLanguage = (state: RootState) =>
  state.leadForms.list[0]?.language 

export default leadFormsSlice.reducer;
