import React, { Fragment } from 'react';
import { RHFTextField } from 'src/components/hook-form';

import { Grid, Button, IconButton, Stack, Tooltip, Box, InputAdornment } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useTranslation } from 'react-i18next';
import { countries, PhoneCountryField, phoneCustom, PhoneField } from 'src/components/PhoneField';

import { LoadingButton } from '@mui/lab';

// empty string here is manual type

interface Props {
  errors?: any;
  fields: any[];
  isSubmitting: boolean;
  includeSms: string | null;
  append: (props: any) => void;
  remove: (index: number) => void;
  submit: () => void;
}

export default function InviteForm({
  errors,
  fields,
  append,
  remove,
  submit,
  includeSms,
  isSubmitting,
}: Props) {
  const { t } = useTranslation();

  const nameGridSize = includeSms ? 4 : 6;
  const emailGridSize = includeSms ? 3 : 5;

  return (
    // empty string is manual type

    <Grid container spacing={2}>
      {fields.map((item, i) => (
        <Fragment key={item.id}>
          <Grid item xs={nameGridSize} md={nameGridSize}>
            <RHFTextField
              // {...register(`invites.${i}.name`)}
              name={`invites.${i}.name`}
              label={t('invites.form.fields.name')}
              autoFocus={i === 0}
            />
          </Grid>
          <Grid item xs={emailGridSize} md={emailGridSize}>
            <RHFTextField
              // {...register(`invites.${i}.email`)}
              name={`invites.${i}.email`}
              label={t('invites.form.fields.email')}
            />
          </Grid>
          {includeSms && (
            <Grid item xs={4} md={4}>
              <Stack flexDirection={'row'}>
                <Box flexGrow={1}>
                  <PhoneField
                    // size="small"
                    // {...register(`invites.${i}.phone`)}
                    name={`invites.${i}.phone`}
                    label={t('invites.form.fields.phone')}
                    InputProps={{
                      inputComponent: phoneCustom as any,
                      startAdornment: (
                        <InputAdornment sx={{ width: 200 }} position="start">
                          <PhoneCountryField
                            id="outlined-select-currency"
                            name={`invites.${i}.countryCode`}
                          >
                            {countries.map((option) => (
                              <option key={option.phone} value={option.phone}>
                                {option.flag} {option.phone}
                              </option>
                            ))}
                          </PhoneCountryField>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.invites?.[i]?.phone}
                    helperText={errors?.invites?.[i]?.phone?.message || ''}
                  />
                </Box>
              </Stack>
            </Grid>
          )}
          <Grid item xs={1} md={1} alignItems="center" justifyContent="center">
            <Tooltip title={t('invites.form.deleteRow') as string}>
              <Stack
                sx={{
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <IconButton aria-label="delete" onClick={() => remove(i)}>
                  <Iconify icon="ep:remove" />
                </IconButton>
              </Stack>
            </Tooltip>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12} md={12}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Button
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={() => append({ name: '', email: '', countryCode: '+45', phone: '' })}
          >
            {t('invites.form.addMore')}
          </Button>

          <LoadingButton
            variant="contained"
            size='large'
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={submit}
          >
            {t('invites.form.invite')}
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
}
