import { Platforms } from '../slices/platform';
import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

const platform = (state: RootState) => state.user.platform;
const teamInvites = (state: RootState) =>
  Object.keys(state.teamInvites.teamInviteMap).map((id) => ({
    ...state.teamInvites.teamInviteMap[id],
  }));

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentPlatformInvites = createSelector(
  teamInvites,
  (teamInvites) => teamInvites
);
