import { useEffect, useState } from 'react';
import { Typography, Stack, TextField, Button, DialogTitle, DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { QuestionType } from '../QuestionsSection';
import Iconify from '../../../../components/Iconify';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab';
import { Paper, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getTypeConfig } from '../types';
import { Functions } from 'src/contexts/FirebaseContext';
import { httpsCallable } from 'firebase/functions';
import { useSelector } from 'src/redux/store';
import { QuestionOption, selectLeadForm } from 'src/redux/slices/leadFormsSlice';
import { useSnackbar } from 'notistack';
import { Question } from '../../../../redux/slices/leadFormsSlice';
import uuidv4 from 'src/utils/uuidv4';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function EditForm({
  questionId,
  updateQuestion,
  updateQuestionOptions,
  deleteQuestion,
  handleClose,
}: {
  questionId: string | null;
  updateQuestion: (question: Question) => void;
  updateQuestionOptions: (questionId: string, options: QuestionOption[]) => void;
  deleteQuestion: VoidFunction;
  handleClose: () => void;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const leadForm = useSelector(selectLeadForm);
  const [question, setQuestion] = useState('');
  const [type, setType] = useState(QuestionType.RADIO);
  const [submitting, isSubmitting] = useState(false);
  useEffect(() => {
    if (questionId) {
      const question = leadForm?.form.find((q) => q.id === questionId);
      if (question) {
        setQuestion(question.content);
        setType(question.type);
      }
    }
  }, [questionId]);
  const onSubmit = async () => {
    try {
      if (!workspaceId || !leadForm || !questionId) {
        throw new Error('Missing workspaceId or leadForm or questionId');
      }
      isSubmitting(true);
      const typeOptions = type === QuestionType.RADIO ? { options: [] } : { placeholder: question };
      updateQuestion({
        id: questionId,
        content: question,
        type,
        ...typeOptions,
      });
      const functionRef = httpsCallable(Functions, 'getLeadFormQuestionSuggestions');
      functionRef({
        workspaceId,
        formId: leadForm.id,
        questionId,
        question,
        type,
      }).then((res) => {
        if (Array.isArray(res.data) && type === QuestionType.RADIO) {
          updateQuestionOptions(
            questionId,
            res.data.map((option) => ({ id: uuidv4(), content: option }))
          );
        }
      });

      enqueueSnackbar(t('leads.form.update.success'));
      // onClose();
      // setStep(Steps.INDUSTRY);
      // setOpen(false);
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      isSubmitting(false);
    }
  };

  return (
    <>
      <DialogTitle mb={2}>Add question</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Based on what your question is, we will add recommendations for the most optimal and
          converting flow
        </DialogContentText>
        <Box mt={2} />
        <TextField
          fullWidth
          label="Your question"
          placeholder="Ex. What is your motivation?"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <Box mt={2} />
        <Typography variant="subtitle1">Question type</Typography>
        <Box mt={1} />
        <Stack direction="row" spacing={2}>
          <InputCard
            type={QuestionType.RADIO}
            selected={type === QuestionType.RADIO}
            onSelect={() => setType(QuestionType.RADIO)}
          />
          <InputCard
            type={QuestionType.NUMBER}
            selected={type === QuestionType.NUMBER}
            onSelect={() => setType(QuestionType.NUMBER)}
          />
          <InputCard
            type={QuestionType.LONG_TEXT}
            selected={type === QuestionType.LONG_TEXT}
            onSelect={() => setType(QuestionType.LONG_TEXT)}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'inherit' }}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button color="error" onClick={deleteQuestion}>
            Delete question
          </Button>

          <Stack direction="row">
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!question?.trim()}
              onClick={onSubmit}
              loading={submitting}
            >
              Add question
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
}

function InputCard({
  type,
  selected,
  onSelect,
}: {
  selected?: boolean;
  type: QuestionType;
  onSelect: VoidFunction;
}) {
  const theme = useTheme();
  const { label, icon } = getTypeConfig(type);
  return (
    // @ts-ignore
    <CustomPaper selected={selected} onClick={onSelect}>
      <Stack alignItems="center">
        <Stack
          alignItems="center"
          justifyContent="center"
          mb={0.5}
          sx={{
            borderRadius: 1,
            height: 32,
            width: 32,
            backgroundColor: theme.palette.primary.lighter,
          }}
        >
          <Iconify icon={icon} fontSize={24} color={theme.palette.primary.light} />
        </Stack>
        <Typography variant="body2" fontSize={12}>
          {label}
        </Typography>
      </Stack>
      {selected && (
        <Box
          sx={{
            position: 'absolute',
            bottom: -14,
            left: 0,
            right: 0,
            textAlign: 'center',
          }}
        >
          <Iconify
            sx={{ backgroundColor: theme.palette.common.white, borderRadius: '50%' }}
            icon="carbon:checkmark-filled"
            color={theme.palette.success.main}
          />
        </Box>
      )}
    </CustomPaper>
  );
}

const CustomPaper = styled(Paper)(({ theme, selected }: any) => ({
  position: 'relative',
  padding: 16,
  cursor: 'pointer',
  border: `1px solid ${theme.palette.grey[300]}`,
  transition: 'all 0.2s',
  boxShadow: selected ? theme.customShadows.z4 : 'none',
  '&:hover': {
    boxShadow: theme.customShadows.z4,
  },
}));
