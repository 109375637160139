import { httpsCallable } from 'firebase/functions';
import { Functions } from '../../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { IconButton, CircularProgress, Tooltip } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

const root =
  process.env.NODE_ENV === 'production' ? 'https://app.simplyreview.com' : 'http://localhost:3000';

export default function LoginAsUser({
  userId,
  displayName,
}: {
  userId: string;
  displayName: string;
}) {
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (userId: string) => {
    try {
      if (!userId) return alert('Something is not working!');
      setloading(true);
      const functionRef = httpsCallable(Functions, 'supportUser');
      const response: any = await functionRef({
        userId,
      });

      // const configId = response?.data?.id;

      // @ts-ignore
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
        return;
      }
      const { token } = response.data;
      if (token) {
        // await customToken(token);
        window.open(`${root}/internal/auth?token=${token}`, '_blank');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <Tooltip title={`Login in as ${displayName}`}>
      <LoadingButton
        variant="outlined"
        loading={loading}
        startIcon={<Iconify icon="ic:round-login" />}
        onClick={() => handleSubmit(userId)}
      >
        Login as {displayName}
      </LoadingButton>
    </Tooltip>
  );
}
