import { createContext, useContext, useState, ReactNode } from 'react';

interface WorkspaceSettingsContextProps {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

const WorkspaceSettingsContext = createContext<WorkspaceSettingsContextProps | undefined>(
  undefined
);

interface WorkspaceSettingsProviderProps {
  children: ReactNode;
}

export function WorkspaceSettingsProvider({ children }: WorkspaceSettingsProviderProps) {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <WorkspaceSettingsContext.Provider
      value={{
        isOpen,
        openDialog,
        closeDialog,
      }}
    >
      {children}
    </WorkspaceSettingsContext.Provider>
  );
}

export function useWorkspaceSettings() {
  const context = useContext(WorkspaceSettingsContext);

  if (context === undefined) {
    throw new Error('useWorkspaceSettings must be used within a WorkspaceSettingsProvider');
  }

  return context;
}
