import { useEffect, useState } from 'react';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
// import useAuth from './useAuth';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import {
  WorkspaceLinks,
  setWorkspaceLinksState,
  setWorkspaceLinks,
} from '../redux/slices/workspaceLinksSlice';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function useWorkspaceLinks() {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) =>
      state.workspaceLinks.state !== RequestState.RESOLVED &&
      state.workspaceLinks.state !== RequestState.REJECTED
  );
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = query(
        collection(DB, getCollection[Collection.WORKSPACE_LINKS]()),
        where(`members.${userId}.uid`, '==', userId)
      );

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.group('useWorkspaceLinks');
          const useWorkspaceLinks = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as WorkspaceLinks[];

          async function setWorkspaceLinksData() {
            dispatch(setWorkspaceLinks({ workspaces: useWorkspaceLinks }));

            console.groupEnd();
          }

          setWorkspaceLinksData();
        },
        (error) => {
          dispatch(setWorkspaceLinksState(RequestState.REJECTED));
          console.error('useWorkspaceLinks', error);
          dispatch(
            setWorkspaceLinks({
              workspaces: [],
              current: null,
            })
          );
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingWorkspaceLinks: isLoading };
}
