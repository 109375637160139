import { useEffect } from 'react';
import { query, collection, where, limit, onSnapshot } from 'firebase/firestore';
import { Collection } from '../enum';
import { getCollection } from '../api';
import { DB } from '../contexts/FirebaseContext';
import { useDispatch, useSelector } from 'react-redux';
import { Automation, setAutomations } from '../redux/slices/automationsSlice';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function useAutomations() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  // const [isLoadingApis, setIsLoadingApis] = useState(true);

  useEffect(() => {
    let unsubscribe: () => void;
    if (workspaceId) {
      const q = query(
        collection(DB, getCollection[Collection.AUTOMATIONS]()),
        where('workspaceId', '==', workspaceId),
        limit(1)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const automations = querySnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as Automation)
          );
          console.log({ automations });

          dispatch(setAutomations(automations));
        },
        (error) => {
          console.error(error);
          dispatch(setAutomations([]));
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);

  return {};
}
