import React, { useState } from 'react';
import {
  Avatar,
  Box,
  useTheme,
  Typography,
  Stack,
  Grid,
  Button,
  TextField,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import Iconify from '../../../components/Iconify';
import { Intl as IntlI, ReviewType } from '../../../enum';
import { useTranslation } from 'react-i18next';
import BookingCalendar from './BookingCalendar';
import { LabelStyle } from 'src/pages/client/ReviewFormText';
import { convertMinutesToHHmm, convertMinutesToHoursAndMinutes } from 'src/utils/time';
import { format } from 'date-fns';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { LoadingButton } from '@mui/lab';
import BrandButton from 'src/components/buttons/BrandButton';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../../../components/hook-form/FormProvider';
import { Link } from '@mui/material';
import { Terms } from 'src/config';
import useResponsiveQuery from 'src/hooks/useResponsiveQuery';
import { getDateLocalFromIntl } from 'src/locales/helper';
import Lottie, { successAnimation } from 'src/components/Lottie';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import useBookings from 'src/hooks/useBookings';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

type FormValuesProps = {
  name: string;
  email: string;
  note: string;
  date: Date | null;
  time: number | null;
  consent: boolean;
};

const defaultValues: FormValuesProps = {
  name: '',
  email: '',
  note: '',
  date: null,
  time: null,
  consent: false,
};

export default function BookingInfo({
  configId,
  workspaceId,
  avatar = undefined,
  title,
  description,
  color,
  duration,
  rollingdays,
  weekAvailability,
  pageLanguage = IntlI.EN,
}: {
  configId?: string;
  workspaceId: string;
  avatar?: string;
  title: string;
  description: string;
  color?: string;
  duration: number;
  rollingdays: number;
  weekAvailability?: any;
  pageLanguage?: IntlI;
}) {
  const theme = useTheme();
  const { isDesktop } = useResponsiveQuery();
  const { t } = useTranslation();
  const blockedTimes = useBookings({ scheduleId: configId, rollingdays });
  const { enqueueSnackbar } = useSnackbar();
  // const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  // const [selectedTime, setSelectedTime] = React.useState<number | null>(null);
  const [step, setStep] = React.useState<'DATE' | 'DETAILS' | 'SUCCESS'>('DATE');
  const { hours, mins } = convertMinutesToHoursAndMinutes(duration);
  const timeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
    .formatToParts()
    .find((part) => part.type === 'timeZoneName')?.value;

  const UpdateUserSchema = z.object({
    name: z.string().min(1, { message: t('validation.name', { lng: pageLanguage }) as string }),
    email: z
      .string()
      .email({ message: t('validation.email.required', { lng: pageLanguage }) as string }),
    date: z.date({
      required_error: t('validation.date', { lng: pageLanguage }) as string,
    }),
    time: z.number({
      required_error: t('validation.time', { lng: pageLanguage }) as string,
    }),
    note: z.string().optional(),
    consent: z
      .boolean({
        required_error: t('validation.terms', { lng: pageLanguage }) as string,
      })
      .refine((val) => val === true, {
        message: t('validation.terms', { lng: pageLanguage }) as string,
      }),
  });

  const methods = useForm<FormValuesProps>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    // reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!configId || !workspaceId) {
        throw new Error(`Invalid configId=${configId} or workspaceId=${workspaceId}`);
      }
      const functionRef = httpsCallable(Functions, 'onSubmitBooking');
      const response: any = await functionRef({
        configId,
        workspaceId,
        name: data.name,
        email: data.email,
        note: data.note,
        date: data.date,
        time: data.time,
        consent: new Date(),
      });
      const error = response?.data?.httpErrorCode?.status || response?.data?.errorInfo?.code;

      if (error) {
        enqueueSnackbar(
          response?.data?.errorInfo?.message || response?.data?.httpErrorCode?.canonicalName,
          { variant: 'error' }
        );
        return;
      }
      setStep('SUCCESS');
    } catch (error) {
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
      console.error(error);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={4} md={4}>
            <Stack
              sx={{ height: '100%', /* alignItems: 'center', */ justifyContent: 'space-between' }}
            >
              <Stack>
                <Avatar
                  alt={'logo'}
                  src={avatar}
                  sx={{
                    // width: '100%',
                    // height: '100%',
                    width: 96,
                    height: 96,
                    borderRadius: 1,
                    // margin: '0 auto',
                  }}
                />
                <Stack mt={2} mb={1}>
                  <Typography variant="h6" align="left">
                    {title}
                  </Typography>
                </Stack>
                <Stack mb={1}>
                  <Typography variant="body2" align="left" sx={{ whiteSpace: 'pre-line' }}>
                    {description}
                  </Typography>
                </Stack>
                <Stack mb={1} direction="row" alignItems="center">
                  <Iconify color={theme.palette.grey[600]} icon="mdi:clock-time-four-outline" />
                  <Typography
                    color={theme.palette.grey[600]}
                    variant="body2"
                    align="left"
                    sx={{ ml: 1, whiteSpace: 'pre-line' }}
                  >
                    {hours
                      ? `${hours}${t('general.hours', {
                          lng: pageLanguage,
                        })} `
                      : ''}{' '}
                    {mins
                      ? `${mins}${t('general.minutes', {
                          lng: pageLanguage,
                        })}`
                      : ''}
                  </Typography>
                </Stack>
                <Stack mb={1} direction="row" alignItems="center">
                  <Iconify color={theme.palette.grey[600]} icon="mdi:calendar-month-outline" />
                  <Typography
                    color={theme.palette.grey[600]}
                    variant="body2"
                    align="left"
                    sx={{ ml: 1, whiteSpace: 'pre-line' }}
                  >
                    {values.date &&
                      format(values.date, 'EEEE, MMMM dd, yyyy', {
                        locale: getDateLocalFromIntl(pageLanguage),
                      })}

                    {values.time && (
                      <>
                        <br />
                        {convertMinutesToHHmm(values.time || 0)} -{' '}
                        {convertMinutesToHHmm(values.time + duration || 0)}
                      </>
                    )}
                    {!values.date && !values.time && t('booking.selectDate', { lng: pageLanguage })}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Iconify color={theme.palette.grey[600]} icon="mdi:globe" />
                <Typography
                  color={theme.palette.grey[600]}
                  variant="body2"
                  align="left"
                  fontSize={12}
                  sx={{ ml: 1, whiteSpace: 'pre-line' }}
                >
                  {timeZone}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8} md={8} sx={{}}>
            <Box sx={{ minHeight: 380 }}>
              {/* <Stack mb={2}>
          <Typography variant="overline" color={theme.palette.grey[500]} textAlign="center">
            {t('review.form.questionsLabel', { lng: pageLanguage })}
          </Typography>
        </Stack> */}
              {/* <Slide key="YOLO" direction="down" in={step === 'DATE'} mountOnEnter unmountOnExit> */}
              {step === 'DATE' && (
                <Box sx={{}}>
                  <BookingCalendar
                    blockedTimes={blockedTimes}
                    rollingdays={rollingdays}
                    weekAvailability={weekAvailability}
                    duration={duration}
                    customColor={color}
                    selectedDate={values.date}
                    setSelectedDate={(date: Date | null) => setValue('date', date)}
                    selectedTime={values.time}
                    setSelectedTime={(time: number | null) => setValue('time', time)}
                    setStep={() => setStep('DETAILS')}
                  />
                </Box>
              )}
              {/* </Slide> */}
              {/* <Slide key="YOLO" direction="up" in={step === 'DETAILS'} mountOnEnter unmountOnExit> */}
              {step === 'DETAILS' && (
                <Box sx={{}}>
                  <BrandButton
                    customColor={color}
                    startIcon={<Iconify icon="material-symbols:arrow-back-rounded" />}
                    variant="text"
                    onClick={() => setStep('DATE')}
                  >
                    {t('booking.event.details.back')}
                  </BrandButton>
                  <Stack mt={2}>
                    <LabelStyle>{t('general.name', { lng: pageLanguage })}*</LabelStyle>
                    <RHFTextField
                      fullWidth
                      size="small"
                      name="name"
                      // label={t('review.form.descriptionPlaceholder', { lng: pageLanguage })}
                      customColor={color}
                    />
                  </Stack>
                  <Stack mt={2}>
                    <LabelStyle>{t('general.email', { lng: pageLanguage })}*</LabelStyle>
                    <RHFTextField
                      size="small"
                      fullWidth
                      name="email"
                      // label={t('review.form.descriptionPlaceholder', { lng: pageLanguage })}
                      customColor={color}
                    />
                  </Stack>
                  <Stack mt={2}>
                    <LabelStyle>
                      {t('booking.event.note', { lng: pageLanguage })}
                      {/* {t('review.form.descriptionLabel', { lng: pageLanguage })} */}
                    </LabelStyle>
                    <RHFTextField
                      fullWidth
                      size="small"
                      name="note"
                      // label={t('review.form.descriptionPlaceholder', { lng: pageLanguage })}
                      customColor={color}
                      multiline
                      rows={3}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 3 }}
                    justifyContent="space-between"
                  >
                    <RHFCheckbox
                      name="consent"
                      customColor={color}
                      label={
                        <Typography fontSize={12} color={theme.palette.grey[600]}>
                          {t('booking.form.terms', { lng: pageLanguage }) as string}
                        </Typography>
                      }
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 3 }}
                    justifyContent="space-between"
                  >
                    <BrandButton
                      disabled={isSubmitting || !values.consent}
                      customColor={color}
                      variant="contained"
                      type="submit"
                    >
                      {isSubmitting && <CircularProgress size={24} />}
                      {t('booking.scheduleEvent')}
                    </BrandButton>
                    <Typography
                      variant="body2"
                      style={{ marginTop: 0 }}
                      sx={{
                        textAlign: isDesktop ? 'right' : 'right',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      <Link
                        target="_blank"
                        sx={{ mt: 0 }}
                        color="grey.600"
                        href={Terms.TERMS_AND_CONDITIONS}
                      >
                        {t('terms.read', { lng: pageLanguage }) as string}
                      </Link>
                    </Typography>
                  </Stack>
                </Box>
              )}
              {step === 'SUCCESS' && (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" gutterBottom>
                    {t('booking.completed.headline', { lng: pageLanguage })}
                  </Typography>

                  {isDesktop ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Lottie animation={successAnimation} />
                    </Box>
                  ) : (
                    <Lottie animation={successAnimation} />
                  )}
                  <Typography>
                    {t('booking.completed.thanks', { lng: pageLanguage })} &nbsp;
                  </Typography>
                  <Box sx={{ mt: 2 }} />
                  <Typography variant="subtitle1">
                    {t('booking.completed.getstarted', { lng: pageLanguage })}{' '}
                    {/* <Link to="/auth/register">{t('general.click.link', { lng: pageLanguage })}</Link> */}
                  </Typography>
                </Box>
              )}
              {/* </Slide> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
}
