import { useState } from 'react';
import zenfit from '../../../../../assets/integrations/zenfit.png';
import { Stack, DialogContent, TextField, Box, InputAdornment, Dialog, Link } from '@mui/material';
import Image from '../../../../../components/Image';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Functions } from '../../../../../contexts/FirebaseContext';
import { useSelector } from '../../../../../redux/store';
import { httpsCallable } from 'firebase/functions';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';
import { Card } from 'src/components/ui/card';
import { Button } from 'src/components/ui/button';

export default function Connect() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onConnect = async () => {
    try {
      setError('');
      if (!apiKey) {
        return setError('apiKey.required');
      }
      if (!workspaceId) {
        throw new Error('Workspace ID is missing');
      }
      setLoading(true);
      const functionRef = httpsCallable(Functions, 'connectZenfitClientApi');
      const response: any = await functionRef({ workspaceId, apiKey });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }
      enqueueSnackbar(t('apiKey.connect.success'), { variant: 'success' });
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      console.error({ error });
      setError('Could not connect to Zenfit with that API key');
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
      console.error(error);
    }
  };
  return (
    <>
      <Card className="p-6 shadow-none">
        <Stack spacing={3}>
          <Stack alignItems="center" py={3}>
            <Image src={zenfit} alt="zenfit" />
          </Stack>
          <Button type="submit" onClick={handleOpen}>
            Connect Zenfit
          </Button>
        </Stack>
      </Card>
      <Dialog keepMounted open={open} onClose={handleClose}>
        <DialogTitle mb={2}>Connect Zenfit</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('integrations.zenfit.description')}</DialogContentText>
          <TextField
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: loading ? (
                <InputAdornment position="start">
                  <CircularProgress size={16} />
                </InputAdornment>
              ) : null,
            }}
            disabled={loading}
            margin="dense"
            id="name"
            label={t('integrations.zenfit.apiKey.label')}
            type="text"
            fullWidth
            error={Boolean(error)}
            helperText={t(error)}
          />
          <Box mt={2} />
          <DialogContentText>
            {t('integrations.zenfit.helper.title')}
            {/* How do I find my Zenfit API key? */}
          </DialogContentText>

          <Typography variant="body2" color="">
            <ol
              style={{ marginLeft: 32, color: theme.palette.grey[500], listStyleType: 'deicmal' }}
            >
              <li>
                {t('integrations.zenfit.helper.step1.part1')} {/* Log in to your{' '} */}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      'https://app.zenfitapp.com/dashboard/settings/integrations',
                      '_blank'
                    )
                  }
                >
                  {t('integrations.zenfit.helper.step1.part2')}
                  {/* Zenfit account */}
                </Link>
              </li>
              <li>{t('integrations.zenfit.helper.step2')}</li>
              {/* Click on the Settings icon in the top right corner. */}
              <li>{t('integrations.zenfit.helper.step3')}</li>
              {/* Click on Settings */}
              <li>{t('integrations.zenfit.helper.step4')}</li>
              {/* Click on the Integrations tab */}
              <li>{t('integrations.zenfit.helper.step5')}</li>
              {/* Click connect with Simply */}
              <li>{t('integrations.zenfit.helper.step6')}</li>
              {/* Copy the API key */}
              <li>{t('integrations.zenfit.helper.step7')}</li>
              {/* Paste the API key in the field above */}
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outline" onClick={handleClose}>
            {t('general.cancel')}
          </Button>
          <Button loading={loading} onClick={onConnect}>
            Connect
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
