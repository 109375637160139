import React, { useEffect, useState } from 'react';
import { BlockPicker } from 'react-color';
import Box from '@mui/material/Box';
import { Fade, ClickAwayListener, Stack, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'src/redux/store';
import { selectPublicConfigHasLoaded } from 'src/redux/slices/publicConfig';
import { selectLeadFormColor } from 'src/redux/slices/leadFormsSlice';

export default function ColorBlockPicker({
  hex,
  setHex,
}: {
  hex: string;
  setHex: (hex: string) => void;
}) {
  const theme = useTheme();
  
  const hasLoadedConfig = useSelector(selectPublicConfigHasLoaded);
  const [open, setOpen] = useState(false);
  const handleColorChange = ({ hex }: any) => setHex(hex);

  // useEffect(() => {
  //   if (hasLoadedConfig) {
  //     setHex(brandColor);
  //   }
  // }, [hasLoadedConfig]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        sx={{
          position: 'relative',
          p: 0.5,
          border: '1px solid',
          borderRadius: 1,
          borderColor: 'grey.400',
          cursor: 'pointer',
          zIndex:1
        }}
        onClick={() => setOpen(true)}
      >
        {!hasLoadedConfig ? (
          <Stack direction="row" spacing={1}>
            <Box>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: 1,
                }}
              />
            </Box>
            <Skeleton
              variant="text"
              sx={{
                height: 40,
                width: '100%',
                borderRadius: 1,
              }}
            />
          </Stack>
        ) : (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box sx={{ height: 40, width: 40, borderRadius: 1, backgroundColor: hex }} />
              <Typography>{hex}</Typography>
            </Stack>
            <Fade in={open}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  // mt: 0.5,
                }}
              >
                <BlockPicker color={hex} onChangeComplete={handleColorChange} />
              </Box>
            </Fade>
          </>
        )}
      </Box>
    </ClickAwayListener>
  );
}
