import React, { useEffect, useState } from 'react';
import ReactJoyride, { CallBackProps, TooltipRenderProps } from 'react-joyride';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { Card } from '../ui/card';
import { X } from 'lucide-react';
import { Button } from '../ui/button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/redux/store';
import { selectUserId } from 'src/redux/slices/userSlice';
import { doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { Collection } from 'src/enum';
import { selectShouldDisplayNewSidebar } from 'src/redux/slices/onboarding';

const tour = {
  dashboard: [
    {
      target: '[data-joyride-id=new-sidebar-workspaces]',
      title: 'joyride.dashboard.workspaces.title',
      content: 'joyride.dashboard.workspaces.content',
      placement: 'right',
      scrollOffset: 120,
      disableBeacon: true,
      // disableScrollParentFix: true,
    },
    {
      target: '[data-joyride-id=new-sidebar-products]',
      title: 'joyride.dashboard.products.title',
      content: 'joyride.dashboard.products.content',
      placement: 'right',
      scrollOffset: 120,
      disableBeacon: true,
      // disableScrollParentFix: true,
    },
    {
      target: '[data-joyride-id=new-sidebar-other]',
      title: 'joyride.dashboard.other.title',
      content: 'joyride.dashboard.other.content',
      placement: 'right',
      scrollOffset: 120,
      disableBeacon: true,
      // disableScrollParentFix: true,
    },
    {
      target: '[data-joyride-id=new-sidebar-user]',
      title: 'joyride.dashboard.user.title',
      content: 'joyride.dashboard.user.content',
      placement: 'right-end',
      disableBeacon: true,
      // disableScrollParentFix: true,
    },
    // {
    //   target: '[data-joyride-id=dashboard-rating]',
    //   title: 'This is where your overall review rating will be shown!',
    //   content: 'This is where your overall review rating will be shown!',
    //   disableBeacon: true,
    // },
    // {
    //   target: '[data-joyride-id=dashboard-recent-reviews]',
    //   title: 'This is where your overall review rating will be shown!',
    //   content: 'This is where your overall review rating will be shown!',
    //   disableBeacon: true,
    // },
    // {
    //   target: '[data-joyride-id=dashboard-nav-reviews]',
    //   title: 'This is where your overall review rating will be shown!',
    //   content: 'This is where your overall review rating will be shown!',
    //   disableBeacon: true,
    // },
    // {
    //   target: '[data-joyride-id=dashboard-nav-integrations]',
    //   title: 'This is where your overall review rating will be shown!',
    //   content: 'This is where your overall review rating will be shown!',
    //   disableBeacon: true,
    // },
    // {
    //   target: '[data-joyride-id=dashboard-header-notification]',
    //   title: 'This is where your overall review rating will be shown!',
    //   content: 'This is where your overall review rating will be shown!',
    //   disableBeacon: true,
    // },
  ],
};

export default function Joyrider() {
  const [steps, setsteps] = useState<{ target: string; content: string }[]>([]);
  const userId = useSelector(selectUserId);
  const shouldDisplaySidebarTour = useSelector(selectShouldDisplayNewSidebar);
  const theme = useTheme();
  const l = useLocation();
  // console.log({ shouldDisplaySidebarTour });

  useEffect(() => {
    setTimeout(() => {
      switch (l.pathname) {
        case '/dashboard/app':
          if (shouldDisplaySidebarTour) {
            setsteps(tour.dashboard);
          }
          // TODO: setOnboarding to true
          break;

        default:
          break;
      }
    }, 2000);

    return () => {};
  }, [l.pathname, shouldDisplaySidebarTour]);
  const defaultOptions = {
    arrowColor: 'transparent', // theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: theme.palette.primary.main,
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: theme.palette.common.black,
    width: undefined,
    zIndex: 10000,
  };
  const setOnboardingNewSidebar = async () => {
    try {
      if (!userId) throw new Error(`userId invalid`);
      await setDoc(
        doc(DB, getCollection[Collection.ONBOARDING](userId), 'details'),
        { newSidebar: new Date() },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, step, index } = data;
    const finishedStatuses = ['ready', 'skipped'];
    if (finishedStatuses.includes(status) && action === 'reset') {
      // alert(JSON.stringify({ status, action }));
      // Do something when the tour ends or is closed
      setOnboardingNewSidebar();
      console.log('Tour ended or closed');
      // setRun(false); // Stop the tour
    }
  };
  return (
    <ReactJoyride
      tooltipComponent={CustomTooltip}
      steps={steps}
      continuous
      disableScrolling
      callback={handleJoyrideCallback}
      locale={{
        back: 'joyride.back',
        close: 'joyride.close',
        last: 'joyride.last',
        next: 'joyride.next',
        open: 'joyride.open',
        skip: 'joyride.skip',
      }}
      styles={{ options: defaultOptions }}
    />
  );
}

function CustomTooltip(props: TooltipRenderProps) {
  const { t } = useTranslation();
  const { backProps, closeProps, continuous, index, primaryProps, skipProps, step, tooltipProps } =
    props;

  return (
    <Card className="relative p-6 pb-4 pt-8 tooltip__body max-w-96" {...tooltipProps}>
      <Button variant="ghost" className="w-8 absolute top-0 right-0 tooltip__close" {...skipProps}>
        <X size={20} />
      </Button>
      {step.title && <h4 className="font-bold tooltip__title">{t(step.title as string)}</h4>}
      <div className="tooltip__content">{t(step.content as string)}</div>
      <div className="flex justify-between pt-4 tooltip__footer">
        <Button size="sm" variant="outline" className="text-red-600 tooltip__button" {...skipProps}>
          {t(skipProps.title)}
        </Button>
        <div className="flex gap-2 tooltip__spacer">
          {index > 0 && (
            <Button size="sm" variant="outline" className="tooltip__button" {...backProps}>
              {t(backProps.title)}
            </Button>
          )}
          {continuous && (
            <Button
              size="sm"
              className="tooltip__button tooltip__button--primary"
              {...primaryProps}
            >
              {t(primaryProps.title)}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
}
