import * as React from 'react';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import { useSelector } from 'src/redux/store';
import { Platforms } from 'src/redux/slices/platform';
// import Logo from 'src/components/SRLogo';
import { Stack, Typography } from '@mui/material';
import floatingVideoWidget from '../../assets/widget/floating_video.png';
import Image from 'src/components/Image';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isLoadingWorkspaceFlow } from 'src/redux/slices/workspaceFlowSlice';
import { isWorkspaceOwner, selectHasPersonalWorkspace } from 'src/redux/slices/userSlice';
import SetupFlow from './setupFlow';

export default function ModalFlowInitialContainer() {
  const { t } = useTranslation();
  const isFlowPlatform = useSelector((state) => state.user.platform === Platforms.SIMPLY_FLOW);
  const open = useSelector(
    (state) =>
      state.workspaceFlow.workspaceIds.length <= 0 &&
      !isLoadingWorkspaceFlow(state) &&
      state.user.id
  );
  const hasPersonalWorkspace = useSelector(selectHasPersonalWorkspace);
  const canSetupProduct = useSelector(isWorkspaceOwner);

  if (!isFlowPlatform) return null;

  if (!canSetupProduct) return null;

  if (!open && hasPersonalWorkspace) return null;

  return (
    <div className="fixed flex flex-col p-6 pt-12 justify-center items-center h-[calc(100%)] w-[calc(100%)] mt-0 pl-[--sidebar-width] z-10 backdrop-blur-lg gap-4">
      <div>
        {/* <Stack justifyContent="center" alignItems="center">
          <Logo />
        </Stack> */}

        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h2">{t('flow.modal.initial.title')}</Typography>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Paper elevation={10} sx={{ borderRadius: 2 }}>
            <Image sx={{ width: 250 }} src={floatingVideoWidget} />
          </Paper>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <SetupFlow />
          <Box mt={4} />
        </Stack>
        <Stack justifyContent="center" alignItems="center">
          <DialogContentText textAlign="center" width="60%">
            {t('flow.modal.initial.description')}
          </DialogContentText>
        </Stack>
      </div>
    </div>
  );
}
