import FullCalendar from '@fullcalendar/react';
import React, { useEffect, useState } from 'react';
import Calendar from 'src/pages/dashboard/Calendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  Draggable,
  EventDragStopArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
  Alert,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Fade,
  Select,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import daLocale from '@fullcalendar/core/locales/da';
import enLocale from '@fullcalendar/core/locales/da';
import noLocale from '@fullcalendar/core/locales/nn';
import { format } from 'date-fns';
import { useTheme } from '@mui/material';
import SwipeableTemporaryDrawer from './Drawer';
import { m, AnimatePresence, motion } from 'framer-motion';
import { Box } from '@mui/material';

import { useSelector } from 'src/redux/store';

import { lighten } from '@mui/system';
import { Intl } from 'src/enum';
import { getDateLocalFromIntl } from 'src/locales/helper';
import {
  EventFormat,
  convertAvailabilityToEvents,
  convertEventsToWeekAvailability,
} from 'src/utils/time';
import uuidv4 from 'src/utils/uuidv4';
import { getContrastColor } from 'src/utils/colors';
import Iconify from 'src/components/Iconify';
import { useTranslation } from 'react-i18next';

export default function AvailbilityCalendar({
  customColor,
  weekAvailability,
  setWeekAvailability,
}: {
  customColor?: string;
  weekAvailability: any;
  setWeekAvailability: any;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const intl = useSelector((state) => state.user.intl);

  const brandColor = customColor || theme.palette.primary.main;
  const events = convertAvailabilityToEvents(weekAvailability);

  // const { loading, message, handleSubmit } = useChat();
  const [drawer, setDrawer] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [locale, setLocale] = useState('da');
  const [state, setState] = useState<EventFormat[]>(events);

  // const handleUpdateWeekAvailability = () => {
  //   setWeekAvailability(weekAvailability);
  //   setState((prevState) => [...prevState, convertAvailabilityToEvents(weekAvailability)]);
  // };
  useEffect(() => {
    // handleSubmit(`Give me some 3 ideas for fitness posts, your reply must be a JSON string array`);
  }, []);
  // handle event receive
  // const handleEventReceive = (eventInfo: any) => {
  //   const newEvent = {
  //     id: eventInfo.draggedEl.getAttribute('data-id'),
  //     title: eventInfo.draggedEl.getAttribute('title'),
  //     color: eventInfo.draggedEl.getAttribute('data-color'),
  //     start: eventInfo.date,
  //     end: eventInfo.date,
  //     custom: eventInfo.draggedEl.getAttribute('data-custom'),
  //     image: '',
  //   };

  //   setState((state) => [...state, newEvent]);
  // };

  const renderEventContent = (eventInfo: any) => (
    <EventCard
      key={eventInfo.event.id}
      text={eventInfo.event.title}
      id={eventInfo.event.id}
      timeText={eventInfo.timeText}
      isEnd={eventInfo.isEnd}
    />
  );

  const EventCard = ({
    id,
    timeText,
    text,
    isEnd,
  }: {
    id: string;
    text: string;
    timeText: string;
    isEnd: boolean;
  }) => {
    // const content = useSelector((state) => state.content.map[id]);
    const color = theme.palette.primary.main;
    const isSelected = selectedEvent === id;
    const textColor = isSelected ? getContrastColor(color, true) : color;
    return (
      <Box
        sx={{
          p: 0,
          overflow: 'hidden',
          height: '100%',
          backgroundColor: isSelected ? alpha(color, 0.8) : 'inherit',
          borderRadius: 1,
        }}
      >
        {/* <Image
          sx={{ borderRadius: 1 }}
          alt="post cover"
          src={content?.unsplashUrl || content?.image}
          ratio="16/9"
        /> */}
        {isEnd && (
          <>
            <Typography
              variant="subtitle1"
              fontSize={10}
              color={textColor}
              lineHeight={1.2}
              mt={0.5}
            >
              {/* @ts-ignore */}
              {timeText}
            </Typography>
            <Typography
              variant="subtitle1"
              fontSize={10}
              color={textColor}
              lineHeight={1.2}
              mt={0.5}
            >
              {/* @ts-ignore */}
              {text}
            </Typography>
          </>
        )}
      </Box>
    );
  };
  const handleUpdateWeekAvailability = (events: EventFormat[]) => {
    setWeekAvailability(convertEventsToWeekAvailability(events));
  };

  const handleUpdateEvents = (info: EventDragStopArg | EventResizeDoneArg) => {
    // update state with new event position

    const calendarEvents = state.map((event) => {
      if (event.id === info.event.id) {
        return {
          ...event,
          start: info.event.start,
          end: info.event.end,
        };
      }
      return event;
    }) as EventFormat[];
    handleUpdateWeekAvailability(calendarEvents);
    setState(calendarEvents);
  };

  const handleEventDrop = (eventInfo: any) => {
    const newEvent = {
      id: eventInfo.draggedEl.getAttribute('data-id'),
      title: eventInfo.draggedEl.getAttribute('title'),
      color: 'black', // eventInfo.draggedEl.getAttribute('data-color'),
      start: eventInfo.date,
      end: eventInfo.date,
      date: eventInfo.date,
      custom: eventInfo.draggedEl.getAttribute('data-custom'),
      image: '',
    };
    const events = [...state, newEvent];
    handleUpdateWeekAvailability(events);
    setState(events);
    setSelectedEvent(newEvent.id);
  };
  // load external events
  // useEffect(() => {
  //   let draggableEl = document.getElementById('external-events') as HTMLElement;

  //   (() =>
  //     new Draggable(draggableEl, {
  //       itemSelector: '.fc-event',
  //       eventData: (eventEl) => {
  //         let id = eventEl.dataset.id;
  //         let title = eventEl.getAttribute('title');
  //         let color = eventEl.dataset.color;
  //         let custom = eventEl.dataset.custom;

  //         return {
  //           id,
  //           title,
  //           color,
  //           custom,
  //           create: true,
  //         };
  //       },
  //     }))();
  // }, []);
  //
  const handleClickAway = () => {
    setSelectedEvent(null);
  };
  return (
    <Stack sx={{ position: 'relative' }}>
      {/* <Stack direction="row" sx={{}}>
        {!loading &&
          message &&
          JSON.parse(message)?.map((m: string) => (
            <Card>
              <CardContent>{m}</CardContent>
            </Card>
          ))}
      </Stack> */}

      {selectedEvent && (
        <Button
          sx={{ position: 'absolute', top: -20, right: 0, zIndex: 9999999 }}
          variant="contained"
          startIcon={<Iconify icon="mdi:delete" />}
          onClick={() => {
            const events = state.filter((event) => event.id !== selectedEvent);
            handleUpdateWeekAvailability(events);
            setState(events);
            setSelectedEvent(null);
          }}
        >
          {t('general.delete')}
        </Button>
      )}
      <Stack direction="row">
        {/* <ContentList state={state} setState={setState} setDrawer={setDrawer} /> */}
        <ClickAwayListener onClickAway={handleClickAway}>
          <CalendarStack
            flexGrow={1}
            // @ts-ignore
            brandColor={theme.palette.primary.main}
          >
            <FullCalendar
              firstDay={1}
              eventOverlap={false}
              // height={400}
              // contentHeight={800}
              // expandRows
              locales={[daLocale, enLocale]} // Set the locale here
              locale={intl}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              // droppable
              selectable
              selectMirror
              eventDragStop={handleUpdateEvents}
              eventResize={handleUpdateEvents}
              select={(info) => {
                // Create a new event
                const newEvent = {
                  id: uuidv4(),
                  title: 'New Event',
                  start: new Date(info.startStr),
                  end: new Date(info.endStr),
                } as EventFormat;

                // Add the new event to the calendarEvents array
                const events = [...state, newEvent];
                handleUpdateWeekAvailability(events);
                setState(events);
                setSelectedEvent(newEvent.id);
              }}
              eventClick={(info) => {
                setSelectedEvent(info.event.id);
              }}
              editable
              allDaySlot={false}
              events={state}
              eventDrop={(info) => {
                const calendarEvents = state.map((event) => {
                  if (event.id === info.event.id) {
                    return {
                      ...event,
                      start: info.event.start,
                      end: info.event.end,
                    };
                  }
                  return event;
                }) as EventFormat[];
                handleUpdateWeekAvailability(calendarEvents);
                setState(calendarEvents);
              }}
              drop={handleEventDrop}
              dayHeaderContent={(info) => dayHeaderContent(info, intl)} // Set the custom day header rendering function
              eventContent={renderEventContent}
              // eventReceive={handleEventReceive}
              // dateClick={(e) => alert(`I'm clicked ${JSON.stringify(e)}`)}
              slotLabelInterval={{ minutes: 60 }}
              // slotDuration={'00:15:00'} // Set the slot duration to 1 hour
              // slotDuration={'00:60:00'}
              slotMinTime={'06:00:00'}
              scrollTime={0}
              headerToolbar={{
                //   left: 'prev,next today',
                //   center: 'title',
                right: '',
              }}
            />
          </CalendarStack>
        </ClickAwayListener>
        <SwipeableTemporaryDrawer drawer={drawer} setDrawer={setDrawer} />
        {/* <Box sx={{ position: 'fixed', bottom: 0, left: 0, width: 300, zIndex: 9999999 }}>
          <Card>
            <CardContent>
              <Stack sx={{ fontSize: 12 }}>
                <strong>User context</strong>{backgroundContext}
              </Stack>
              <Stack sx={{ fontSize: 12 }}>
                <b>Target audience</b>{targetAudience}
              </Stack>
            </CardContent>
          </Card>
        </Box> */}
      </Stack>
    </Stack>
  );
}

function dayHeaderContent(info: any, intl: Intl) {
  // Customize the day header content here
  const date = info.date;
  const formattedDate = format(date, 'EEE', { locale: getDateLocalFromIntl(intl) });
  // const formattedDate2 = format(date, 'd');

  // You can also add additional information if needed
  //   const additionalInfo = 'Extra Info';

  return (
    <div>
      <div style={{ color: 'grey', fontSize: 12 }}>{formattedDate}</div>
      {/* <div style={{ fontSize: 24 }}>{formattedDate2}</div> */}
    </div>
  );
}

const CalendarStack = styled(Stack)(({ theme, brandColor, selectedId }: any) => ({
  /* Add this CSS to your stylesheet */
  '.fc-col-header-cell-cushion': {
    width: '100%',
    textAlign: 'left',
  },

  '.fc-toolbar-title': {
    fontSize: 0,
    fontWeight: 600,
  },

  '.fc .fc-button': {
    fontSize: 12,
  },

  '.fc-event': {
    backgroundColor: `${alpha(brandColor, 0.4)} !important`,
    border: 'none',
    borderRadius: 8,
    padding: 0,
    color: brandColor,
    // borderLeft: `4px solid #ff0000`,
  },
  '.fc-event-title': {
    color: brandColor,
  },
  '.fc-event-time': {
    color: brandColor,
  },
  '.fc-timegrid-slot': {
    height: '0.5em', // 1.5em by default
    fontSize: '0.75em',
  },
  '.fc-button-group > .fc-button': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    boxShadow: 'none !important',
    '&:active': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.white,
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  '.fc-button-group > .fc-button-active': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.white,
    borderColor: `${theme.palette.primary.main} !important`,
  },
  // Hide dotted line
  'tr:nth-child(even) > td': {
    borderTop: '1px dotted transparent !important',
  },
}));
