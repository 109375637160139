import React from 'react';
import { Button, ButtonGroup, ButtonProps, styled, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import { Collection, RequestState } from 'src/enum';
import { collection, doc, writeBatch } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { getCollection } from 'src/api';
import { useSelector } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

function ActivityRadioButton(props: ButtonProps & { active?: boolean }) {
  return (
    <Button variant={props.active ? 'contained' : 'outlined'} {...props}>
      {props.children}
    </Button>
  );
}

export default function AddActivity({ leadId }: { leadId: string }) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(RequestState.IDLE);
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = useSelector(selectCurrentWorkspaceId) as string;
  const [activityType, setActivityType] = React.useState<1 | 2 | 3 | 4>(1);
  const [content, setContent] = React.useState('');
  const { placeholder, backgroundColor } = getActivityTypeInput(activityType);
  const user = useSelector((state) => ({
    id: state.user.id,
    email: state.user.email || '',
    name: state.user.displayName || '',
  }));

  const loading = state === RequestState.PENDING;
  const submitActivity = async () => {
    try {
      if (!workspaceId || !leadId || !content || !activityType) {
        throw new Error('No leadId found');
      }
      setState(RequestState.PENDING);
      const batch = writeBatch(DB);

      console.log('Activity submitted');

      const leadActivityRef = doc(
        collection(
          DB,
          getCollection[Collection.LEADS_FORMS_SUBMISSIONS_ACTIVITY](workspaceId, leadId)
        )
      );
      const leadSubRef = doc(
        DB,
        getCollection[Collection.LEADS_FORMS_SUBMISSIONS](workspaceId),
        leadId
      );
      const payload = {
        createTime: new Date(),
        updateTime: new Date(),
        type: activityType,
        content,
        author: user,
      };

      batch.set(leadActivityRef, payload);
      batch.set(leadSubRef, { lastestActivity: payload }, { merge: true });

      await batch.commit();

      enqueueSnackbar(t('general.success.default'));
      setState(RequestState.RESOLVED);
      setContent('');
    } catch (error) {
      enqueueSnackbar(t('general.error.default'));
      setState(RequestState.REJECTED);
      console.error('Error submitting activity', error);
    }
  };
  return (
    <>
      <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
        <ActivityRadioButton
          style={{ borderBottomLeftRadius: 0 }}
          active={activityType === 1}
          startIcon={<Iconify icon="mage:note" />}
          onClick={() => setActivityType(1)}
        >
          {t('leadSubmissions.activitylog.types.note')}
        </ActivityRadioButton>
        <ActivityRadioButton
          active={activityType === 2}
          startIcon={<Iconify icon="ic:baseline-phone" />}
          onClick={() => setActivityType(2)}
        >
          {t('leadSubmissions.activitylog.types.call')}
        </ActivityRadioButton>
        <ActivityRadioButton
          active={activityType === 3}
          startIcon={<Iconify icon="ic:baseline-email" />}
          onClick={() => setActivityType(3)}
        >
          {t('leadSubmissions.activitylog.types.email')}
        </ActivityRadioButton>
        <ActivityRadioButton
          style={{ borderBottomRightRadius: 0 }}
          active={activityType === 4}
          startIcon={<Iconify icon="mynaui:message" />}
          onClick={() => setActivityType(4)}
        >
          {t('leadSubmissions.activitylog.types.message')}
        </ActivityRadioButton>
      </ButtonGroup>

      <CssTextField
        placeholder={placeholder}
        fullWidth
        multiline
        value={content}
        onChange={(e) => setContent(e.target.value)}
        inputProps={{ style: { borderTopLeftRadius: 0 } }}
        style={{ borderTopLeftRadius: 0, marginTop: 0, backgroundColor }}
        rows={4}
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       <Iconify icon="ic:round-email" />
        //     </InputAdornment>
        //   ),
        // }}
      />
      <LoadingButton loading={loading} variant="contained" onClick={submitActivity}>
      {t('leadSubmissions.activitylog.actions.submit')}
      </LoadingButton>
    </>
  );
}

export const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});

export function getActivityTypeInput(activityType: 1 | 2 | 3 | 4 | null) {
  switch (activityType) {
    case 1:
      return {
        placeholder: 'Write a note',
        backgroundColor: 'lightyellow',
      };
    case 2:
      return {
        placeholder: 'Write about your call',
        backgroundColor: 'inherit',
      };
    case 3:
      return {
        placeholder: 'Write about your email',
        backgroundColor: 'inherit',
      };
    case 4:
      return {
        placeholder: 'Write what your message was about',
        backgroundColor: 'inherit',
      };

    default:
      return {
        placeholder: 'Write a note',
        backgroundColor: 'lightyellow',
      };
  }
}
