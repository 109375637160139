import * as React from 'react';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useSelector } from 'src/redux/store';
import { Platforms } from 'src/redux/slices/platform';
import { styled } from '@mui/material/styles';
import Logo from 'src/components/SRLogo';
import { Stack, Typography } from '@mui/material';
import reviewPlatform from '../../assets/widget/review_visual.png';
import Image from 'src/components/Image';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { isLoadingWorkspaceBookings } from 'src/redux/slices/workspaceBookingsSlice';
import { Button } from 'src/components/ui/button';
import { isWorkspaceOwner, selectHasPersonalWorkspace } from 'src/redux/slices/userSlice';

export default function ModalBookingsInitialContainer() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isBookingPlatform = useSelector(
    (state) => state.user.platform === Platforms.SIMPLY_BOOKING
  );
  const open = useSelector(
    (state) =>
      state.workspaceBookings.workspaceIds.length <= 0 &&
      !isLoadingWorkspaceBookings(state) &&
      state.user.id
  );

  const hasPersonalWorkspace = useSelector(selectHasPersonalWorkspace);
  const canSetupProduct = useSelector(isWorkspaceOwner);

  const onSetupBookingsWorkspace = async () => {
    try {
      setLoading(true);
      const functionRef = httpsCallable(Functions, 'setupBookingsWorkspace');
      const response: any = await functionRef();

      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }

      enqueueSnackbar(t('leads.setup.workspace.success'));
      // window.location.reload();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (!isBookingPlatform) return null;

  if (!canSetupProduct) return null;

  if (!open && hasPersonalWorkspace) return null;

  return (
    <div className="fixed flex flex-col p-6 pt-12 justify-center items-center h-[calc(100%)] w-[calc(100%)] mt-0 pl-[--sidebar-width] z-10 backdrop-blur-lg gap-4">
      <div>
        <Stack justifyContent="center" alignItems="center">
          <Logo />
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h2">{t('bookings.modal.initial.title')}</Typography>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Paper elevation={10} sx={{ borderRadius: 2 }}>
            <Image sx={{ width: 250 }} src={reviewPlatform} />
          </Paper>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <Button size="lg" onClick={onSetupBookingsWorkspace} loading={loading}>
            {t('flow.initial.startTrial')}
          </Button>
        </Stack>
        <Box mt={4} />
        <Stack justifyContent="center" alignItems="center">
          <DialogContentText textAlign="center" width="60%">
            {t('leads.modal.initial.description')}
          </DialogContentText>
        </Stack>
      </div>
    </div>
  );
}
