import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { Collection, RequestState } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { setInviteLoadingState } from '../redux/slices/invites';
import { convertFStoDate } from '../utils/formatTime';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export enum INVITE_ACTIVITY_TYPE {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  LINK = 'LINK',
  REVIEW_RECEIVED = 'REVIEW_RECEIVED',
  SMS_REMINDER = 'SMS_REMINDER',
  EMAIL_REMINDER = 'EMAIL_REMINDER',
}

export interface InviteActivity {
  id: string;
  type: INVITE_ACTIVITY_TYPE;
  createTime: Date;
}

// if passed here it will load X users reviews
export default function useInviteActivity(inviteId: string | null) {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentWorkspaceId);
  const [requestState, setRequestState] = useState(RequestState.IDLE);
  const [activities, setActivities] = useState<InviteActivity[]>([]);
  useEffect(() => {
    let unsubscribe: () => void;

    if (userId && inviteId) {
      setRequestState(RequestState.PENDING);
      // dispatch(setInviteLoadingState(RequestState.PENDING));
      const q = query(
        collection(DB, getCollection[Collection.USERS_INVITE_ACTIVITY](userId, inviteId)),
        orderBy('createTime', 'asc'),
        limit(100)
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const activity = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              createTime: convertFStoDate(data.createTime),
            } as InviteActivity;
          });

          setActivities(activity);
          setRequestState(RequestState.RESOLVED);
        },
        (error) => {
          setRequestState(RequestState.REJECTED);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId, inviteId]);

  return { requestState, activities };
}
