import { useEffect } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { Collection } from '../enum';
import { useDispatch, useSelector } from '../redux/store';
import { DB } from '../contexts/FirebaseContext';
import { getCollection } from '../api';
import { Stats, setReviewStats } from '../redux/slices/reviewSlice';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function useReviewStats() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectCurrentWorkspaceId);

  useEffect(() => {
    let unsubscribe: () => void;

    if (workspaceId) {
      const q = doc(DB, getCollection[Collection.USERS_REVIEW_STATS](workspaceId));
      unsubscribe = onSnapshot(q, (doc) => {
        const stats = {
          ...doc.data(),
        } as Stats;
        dispatch(setReviewStats(stats));
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [workspaceId]);
}
