'use client';

import { LifeBuoy, PartyPopper, Rss, type LucideIcon } from 'lucide-react';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from 'src/components/ui/sidebar';
import { useSupportChat } from '../chat/SupportChatContext';
import { Badge } from '../ui/badge';

// return (
//   <Stack>
//     <IconButtonAnimateCustom
//       onClick={onClick}
//       sx={{
//         width: 40,
//         height: 40,
//       }}
//       data-badge-changelog
//       data-sleek-changelog

export function NavInfo() {
  const { isMobile, state } = useSidebar();
  const { toggleOpen, countUnseenMessages } = useSupportChat();

  const onClick = () => {
    window.$sleek.toggle('tiny.changelog');
  };
  return (
    <SidebarGroup
      className="group-data-[collapsible=icon]:hidden"
      data-joyride-id="new-sidebar-other"
    >
      {/* <SidebarGroupLabel>Projects</SidebarGroupLabel> */}
      <SidebarMenu>
        <SidebarMenuItem key="support" onClick={toggleOpen}>
          <SidebarMenuButton asChild className="cursor-pointer">
            <div className="flex justify-between">
              {/* <a href={item.url}> */}
              <div className="flex w-full [&>svg]:size-4 items-center gap-2">
                <LifeBuoy />
                <span>{'Support'}</span>
              </div>
              {/* </a> */}
              {countUnseenMessages > 0 && (
                <Badge className="rounded-full px-2" variant="destructive">
                  {countUnseenMessages}
                </Badge>
              )}
            </div>
          </SidebarMenuButton>
        </SidebarMenuItem>
        <SidebarMenuItem key="feature-updates">
          <SidebarMenuButton
            asChild
            className="cursor-pointer"
            onClick={onClick}
            data-badge-changelog
            data-sleek-changelog
          >
            <div>
              <PartyPopper />
              <span>{'Feature updates'}</span>
            </div>
          </SidebarMenuButton>
        </SidebarMenuItem>
        {/* <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuAction showOnHover>
                  <MoreHorizontal />
                  <span className="sr-only">More</span>
                </SidebarMenuAction>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-48 rounded-lg"
                side={isMobile ? 'bottom' : 'right'}
                align={isMobile ? 'end' : 'start'}
              >
                <DropdownMenuItem>
                  <Folder className="text-muted-foreground" />
                  <span>View Project</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Forward className="text-muted-foreground" />
                  <span>Share Project</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Trash2 className="text-muted-foreground" />
                  <span>Delete Project</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> */}

        {/* <SidebarMenuItem>
          <SidebarMenuButton className="text-sidebar-foreground/70">
            <MoreHorizontal className="text-sidebar-foreground/70" />
            <span>More</span>
          </SidebarMenuButton>
        </SidebarMenuItem> */}
      </SidebarMenu>
    </SidebarGroup>
  );
}
