import { createSelector } from '@reduxjs/toolkit';
import { PlatformDisplayNames, Platforms } from '../slices/platform';
import { RootState } from '../store';
import { RequestState } from 'src/enum';

const platform = (state: RootState) => state.user.platform;

export const selectPlatformDisplayName = createSelector(platform, getPlatformDisplayName);

export function getPlatformDisplayName(platform: Platforms) {
  switch (platform) {
    case Platforms.SIMPLY_FLOW:
      return PlatformDisplayNames.SIMPLY_FLOW;

    case Platforms.SIMPLY_REVIEW:
      return PlatformDisplayNames.SIMPLY_REVIEW;

    case Platforms.SIMPLY_LEADS:
      return PlatformDisplayNames.SIMPLY_LEADS;

    case Platforms.SIMPLY_LINKS:
      return PlatformDisplayNames.SIMPLY_LINKS;

    case Platforms.SIMPLY_BOOKING:
      return PlatformDisplayNames.SIMPLY_BOOKING;

    case Platforms.SIMPLY_CHAT:
      return PlatformDisplayNames.SIMPLY_CHAT;

    default:
      return PlatformDisplayNames.SIMPLY_REVIEW;
  }
}

export const hasAllPlatformsLoaded = (state: RootState) => {
  // console.log({
  //   review: state.workspaceReview.state,
  //   flow: state.workspaceFlow.state,
  //   leads: state.workspaceLeads.state,
  //   links: state.workspaceLinks.state,
  // });

  const hasReviewWorkspacesLoaded =
    state.workspaceLinks.state === RequestState.RESOLVED ||
    state.workspaceLinks.state === RequestState.REJECTED;

  const hasFlowWorkspacesLoaded =
    state.workspaceFlow.state === RequestState.RESOLVED ||
    state.workspaceFlow.state === RequestState.REJECTED;

  const hasLeadWorkspacesLoaded =
    state.workspaceLeads.state === RequestState.RESOLVED ||
    state.workspaceLeads.state === RequestState.REJECTED;

  const hasLinksWorkspacesLoaded =
    state.workspaceLinks.state === RequestState.RESOLVED ||
    state.workspaceLinks.state === RequestState.REJECTED;

  // console.log({
  //   hasReviewWorkspacesLoaded,
  //   hasFlowWorkspacesLoaded,
  //   hasLeadWorkspacesLoaded,
  //   hasLinksWorkspacesLoaded,
  // });

  return (
    hasReviewWorkspacesLoaded &&
    hasFlowWorkspacesLoaded &&
    hasLeadWorkspacesLoaded &&
    hasLinksWorkspacesLoaded
  );
};
export const hasWorkspacesByNotPersonalWorkspace = (state: RootState) => {
  const reviewWorkspaceIds = state.workspaceReview.workspaceIds;
  const flowWorkspaceIds = state.workspaceFlow.workspaceIds;
  const leadWorkspaceIds = state.workspaceLeads.workspaceIds;
  const linksWorkspaceIds = state.workspaceLinks.workspaceIds;
  const allWorkspaceIds = [
    ...reviewWorkspaceIds,
    ...flowWorkspaceIds,
    ...leadWorkspaceIds,
    ...linksWorkspaceIds,
  ];
  const uniqueWorkspaceIds = allWorkspaceIds.filter(
    (id, index) => allWorkspaceIds.indexOf(id) === index
  );

  const userId = state.user.id as string;

  if (!uniqueWorkspaceIds.includes(userId) && uniqueWorkspaceIds.length > 0) {
    return true;
  }
  return false;
};
