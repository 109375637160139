import React from 'react';
import { Box, Tooltip, Avatar, useTheme } from '@mui/material';
import Iconify from './Iconify';
import { AvatarIcon } from '../sections/@dashboard/general/app/AppRatingTable';
import { useTranslation } from 'react-i18next';
import { getInitials } from '../utils/names';
import { getSourceIcon } from 'src/pages/simplyreview/reviews/ReviewImportDrawer';

type AvatarProps = { avatar?: string; avatarUrl?: string; name?: string };

function renderAvatar({ avatar, avatarUrl, name }: AvatarProps) {
  if (avatarUrl) {
    return <Avatar alt={name} src={avatarUrl} sx={{ width: 48, height: 48 }} />;
  }
  if (name) {
    return (
      <Avatar alt={name} sx={{ width: 48, height: 48 }}>
        {getInitials(name)}
      </Avatar>
    );
  }
  return avatar ? (
    <Avatar
      alt={'user'}
      src={avatar}
      sx={{ width: 48, height: 48, boxShadow: (theme) => theme.customShadows.z8 }}
    />
  ) : (
    <AvatarIcon icon={'eva:heart-fill'} />
  );
}

export default function SimplyVerifiedAvatar({
  avatar,
  avatarUrl,
  name,
  source,
}: {
  avatar?: string;
  avatarUrl?: string;
  name?: string;
  source?: 'trustpilot' | 'google';
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const icon = getSourceIcon(source);
  const tooltip = getTooltipText(source);
  return (
    <Box sx={{ position: 'relative' }}>
      {renderAvatar({ avatar, avatarUrl, name })}
      <Tooltip title={t(tooltip) as string}>
        <Box
          sx={{
            right: -2,
            bottom: -2,
            width: 22,
            height: 22,
            display: 'flex',
            borderRadius: '50%',
            position: 'absolute',
            alignItems: 'center',
            color: 'common.green',
            bgcolor: 'common.white',
            justifyContent: 'center',
          }}
        >
          {icon ? (
            <div style={{ transform: 'scale(0.7)' }}>{icon}</div>
          ) : (
            <Iconify
              icon={'ic:round-verified'}
              width={20}
              height={20}
              style={{
                color: theme.palette.primary.main,
                // backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
              }}
            />
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}

export function getTooltipText(source?: 'trustpilot' | 'google') {
  switch (source) {
    case 'trustpilot':
      return 'general.verifiedByTrustpilot';
    case 'google':
      return 'general.verifiedByGoogle';
    default:
      return 'general.verifiedBySimply';
  }
}
