import { SubTitle } from '../components/subtitles';

export function jsonToWebVTT(json: SubTitle[]) {
  let webvtt = 'WEBVTT\n\n';
  json.forEach((subtitle) => {
    const startTime = subtitle.startTime.replace('.', ',');
    const endTime = subtitle.endTime.replace('.', ',');
    webvtt += `${startTime} --> ${endTime}\n${subtitle.captions}\n\n`;
  });

  return webvtt;
}

export function jsonToSrt(json: SubTitle[]) {
  let srt = '';
  json.forEach((subtitle, i) => {
    const startTime = subtitle.startTime.replace('.', ',');
    const endTime = subtitle.endTime.replace('.', ',');
    const index = i + 1;
    srt += `${index}\n${startTime} --> ${endTime}\n${subtitle.captions}\n\n`;
  });
  return srt;
}
