import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Pulse = styled('div')<{ size?: number }>`
    transform: translateX(0%) translateY(0%);
    width: ${(props) => props.size || 30}px;
    height: ${(props) => props.size || 30}px;

    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color: ${(props) => props.color || props.theme.palette.primary.main};
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      display: block;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.color || 'white'};
      border-radius: 15px;
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  
`;

export default function PulseDot({ color, size }: { color?: string; size?: number }) {
  return (
    <Box>
      <Pulse color={color} size={size} />
    </Box>
  );
}
