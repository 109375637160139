import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { Functions } from '../../../contexts/FirebaseContext';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../../redux/store';
import { Box, Tooltip } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { selectCurrentWorkspaceId } from 'src/redux/slices/userSlice';

export default function ForwardZenfitLead({ leadId }: { leadId: string }) {
  const workspaceId = useSelector(selectCurrentWorkspaceId);
  const forwardedToZenfit = useSelector(
    (state) => state.leadFormsSubmissions.map[leadId]?.forwardedToZenfit
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const onForwardLeadToZenfit = async () => {
    try {
      // setError('');
      if (!leadId) {
        throw new Error('apiKey.required');
      }
      if (!workspaceId) {
        throw new Error('Workspace ID is missing');
      }
      setLoading(true);
      const functionRef = httpsCallable(Functions, 'forwardLeadToZenfit');
      const response: any = await functionRef({ workspaceId, leadId });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        enqueueSnackbar(t('general.error.default'), { variant: 'error' });
        return;
      }
      enqueueSnackbar(t('zenfit.lead.connect.success'), { variant: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.log({ error });
      // setError('Could not connect to Zenfit with that API key');
      enqueueSnackbar(t('general.error.default'), { variant: 'error' });
      console.error(error);
    }
  };
  return (
    <Tooltip title={forwardedToZenfit ? 'Lead already synced to Zenfit' : ''}>
      <Box>
        <LoadingButton
          variant="outlined"
          size="small"
          onClick={onForwardLeadToZenfit}
          loading={loading}
          disabled={forwardedToZenfit}
          endIcon={forwardedToZenfit && <Iconify icon="carbon:checkmark-outline" />}
        >
          Send to Zenfit
        </LoadingButton>
      </Box>
    </Tooltip>
  );
}
