import { collection, doc, setDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

export function updateParticipantLastSeenMessage({
  workspaceId,
  conversationId,
  userId,
  lastSeenMessageCreateTime,
}: {
  workspaceId: string;
  userId: string;
  conversationId: string | null;
  lastSeenMessageCreateTime: number | null;
}) {
  try {
    if (!workspaceId || !userId || !conversationId || !lastSeenMessageCreateTime) {
      throw new Error(
        `[updateParticipantLastSeenMessage]: Invalid parameters ${workspaceId} ${conversationId}`
      );
    }
    const conversationDocRef = doc(
      collection(DB, 'workspaceChats', workspaceId, 'conversations'),
      conversationId
    );
    setDoc(
      conversationDocRef,
      {
        participants: {
          [userId]: {
            lastSeenMessageTime: lastSeenMessageCreateTime,
          },
        },
      },
      { merge: true }
    );
  } catch (error) {
    console.error(error);
  }
}
