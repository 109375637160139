import { Alert, Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Platforms } from 'src/redux/slices/platform';
import {
  selectLatestFlowSubscription,
  selectLatestLeadsSubscription,
  selectLatestReviewSubscription,
} from 'src/redux/slices/stripeSlice';
import { selectPlatform } from 'src/redux/slices/userSlice';
import { useSelector } from 'src/redux/store';
import { getSubscriptionStatusMessage } from 'src/sections/pricing/helpers';

export default function SubscriptionModalTitle() {
  const { t } = useTranslation();
  const platform = useSelector(selectPlatform);
  const reviewSub = useSelector(selectLatestReviewSubscription);
  const flowSub = useSelector(selectLatestFlowSubscription);
  const leadSub = useSelector(selectLatestLeadsSubscription);
  if (Platforms.SIMPLY_REVIEW === platform && reviewSub?.status) {
    const info = getSubscriptionStatusMessage(reviewSub.status);
    return <Title title={info.title} alert={info.alert} />;
  }
  if (Platforms.SIMPLY_FLOW === platform && flowSub?.status) {
    const info = getSubscriptionStatusMessage(flowSub.status);
    return <Title title={info.title} alert={info.alert} />;
  }
  if (Platforms.SIMPLY_LEADS === platform && leadSub?.status) {
    const info = getSubscriptionStatusMessage(leadSub.status);
    return <Title title={info.title} alert={info.alert} />;
  }
  return <Title title={t('general.trial.expired')} />;
}

function Title({ title, alert }: { title: string; alert?: string }) {
  return (
    <Stack justifyContent="center" alignItems="center">
      <p className="text-2xl font-bold">{title}</p>
      {alert && <Alert severity="error">{alert}</Alert>}
    </Stack>
  );
}
